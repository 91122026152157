import * as React from 'react';
import { BrowserRouter, Route, Redirect, Switch } from 'react-router-dom';
import { SkeletonTheme } from 'react-loading-skeleton';
import { loginRoute } from './routes/loginRoute';
import { Dashboard } from './components/Dashboard';
import { NotificationProvider } from './components/NotificationProvider';
import { renderRoutes } from './components/RouteSwitch';
import {
  selfRegistrationConfirmRoute,
  selfRegistrationRoute,
} from './routes/selfRegistrationRoute';
import { storySubmissionRoute } from './routes/storySubmissionRoute';
import { attendanceRoute } from './routes/attendanceRoute';
import { surveyRoute } from './routes/surveyRoute';
import { joinRoute } from './routes/joinRoute';
import { educatorRegistrationRoute } from './routes/educatorRegistrationRoute';
import { ApiClientProvider } from './components/ApiClientProvider';
import { AuthTokensProvider } from './components/AuthTokensProvider';
import { useApiClientContext } from './hooks/useApiClientContext';

export const App: React.FunctionComponent = () => {
  return (
    <SkeletonTheme baseColor="#ddf2ff" highlightColor="#fff">
      <NotificationProvider>
        <AuthTokensProvider>
          <ApiClientProvider>
            <Routes />
          </ApiClientProvider>
        </AuthTokensProvider>
      </NotificationProvider>
    </SkeletonTheme>
  );
};

const Routes: React.FunctionComponent = () => {
  const { user } = useApiClientContext();

  return (
    <BrowserRouter>
      <Switch>
        <Route
          path="/login"
          render={(props) =>
            user ? (
              <Redirect
                to={
                  new URLSearchParams(props.location.search).get('url') || '/'
                }
              />
            ) : (
              <loginRoute.view {...props} />
            )
          }
        />

        {renderRoutes(publicRoutes)}

        <Route
          render={() =>
            user ? (
              <Dashboard />
            ) : (
              <Redirect
                to={`/login?url=${encodeURIComponent(
                  window.location.pathname,
                )}`}
              />
            )
          }
        />
      </Switch>
    </BrowserRouter>
  );
};

const publicRoutes = [
  selfRegistrationRoute,
  selfRegistrationConfirmRoute,
  storySubmissionRoute,
  attendanceRoute,
  surveyRoute,
  joinRoute,
  educatorRegistrationRoute,
];
