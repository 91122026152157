import { useState, useCallback } from 'react';

export function useStoryModalState<T>() {
  const [state, setState] = useState<{ visible: boolean; entity?: T }>({
    visible: false,
  });

  const show = useCallback((entity?: T) => {
    setState({ visible: true, entity });
  }, []);

  const hide = useCallback(() => {
    setState({ visible: false });
  }, []);

  return { ...state, show, hide };
}
