import { useContext } from 'react';
import { NotificationContext } from '../components/NotificationProvider';

export function useCreateNotification() {
  const context = useContext(NotificationContext);

  if (!context) {
    throw new Error('useContext used outside of NotificationProvider');
  }

  return context.createNotification;
}
