import * as React from 'react';
import { ModalProps } from './ModalProps';
import { Modal, Button } from 'react-bootstrap';
import gngLogo from '../images/gng-logo.png';

export interface AssignmentInstructionsModalProps extends ModalProps {
  title: string;
  description: string;
}

export const AssignmentInstructionsModal: React.FC<AssignmentInstructionsModalProps> = ({
  show,
  onHide,
  title,
  description,
}) => {
  return (
    <Modal
      show={show}
      onHide={onHide}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="assignmentInstructions"
    >
      <Modal.Header closeButton>
      <img
        alt="Global Nomads Group Logo showing a circle surrounded by a crescent shape on two sides with the text Global Nomads Group surrounding it."
        src={gngLogo}
        width={100}
        className="header-logo"
      />
        <Modal.Title id="contained-modal-title-vcenter">{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div dangerouslySetInnerHTML={{ __html: description }} />
      </Modal.Body>
      <Modal.Footer className="justify-content-center">
        <Button variant="success" block className="rounded-pill" onClick={onHide}>
          Let's do it!
        </Button>
      </Modal.Footer>
    </Modal>
  );
};