import * as React from 'react';
import { Button, Card, Col, Row } from 'react-bootstrap';
import { Player } from '@lottiefiles/react-lottie-player';
import cs from 'classnames';
import { NavLink } from 'react-router-dom';
import { PageContent } from '../components/PageContent';
import { Route } from '../Route';
import { classListRoute } from './classListRoute';
import { useApiQuery } from '../hooks/useApiQuery';
import { Class } from '../../model/entities/Class';
import { CenteredBusySpinner } from '../components/Busy';
import { useBoolean } from '../hooks/useBoolean';
import { useUser } from '../hooks/useUser';
import { UserRole } from '../../model/entities/User';
import { EditClassModal } from '../modals/EditClassModal';
import { classActivityTabRoute } from '../sections/ClassActivityTab';
import { classUsersTabRoute } from '../sections/ClassUsersTab';
// import { classCourseTabRoute } from '../sections/ClassCourseTab';
import { classProgressTabRoute } from '../sections/ClassProgressTab';
import { classSettingsTabRoute } from '../sections/ClassSettingsTab';
// import { courseDetailRoute } from '../sections/CourseDetailsSection';
import { remoteConfig } from '../..';
import { RouteSwitch } from '../components/RouteSwitch';
import { useContactSupportState } from '../hooks/useContactSupportState';
import { ContactSupport } from '../modals/ContactSupport';
import { useTrackingCode } from 'react-hubspot-tracking-code-hook';
const styles = require('../styles/ClassDetailsRoute.module.scss');

export const ClassContext = React.createContext<Class | null>(null);

export const classDetailsRoute = new Route<{ id: string }>(
  '/classes/:id',
  ({ match, history }) => {
    const modalVisible = useBoolean();
    const user = useUser();
    const currentClass = useApiQuery<Class>(`/classes/${match.params.id}`);
    const modalContactState = useContactSupportState<Class>();
    const educatorResources = JSON.parse(
      remoteConfig.getValue('educatorResources').asString(),
    );

    const handleOnHide = () => {
      modalVisible.setFalse();
      history.push(classUsersTabRoute.formatPath({ id: match.params.id! }));
    };

    const { setTrackEvent } = useTrackingCode();
    const openModalContactSupport = () => {
      modalContactState.show();
      setTrackEvent({ eventId: 'Facilitator Resources', value: user.email });
    };

    const openModalEditClass = () => {
      modalVisible.setTrue();
      setTrackEvent({ eventId: 'Edit class', value: user.email });
    };

    const renderHeader = () => {
      const resources = [
        {
          lottieFile:
            'https://assets9.lottiefiles.com/packages/lf20_xyadoh9h.json',
          title: 'Educators & Participants',
          route: classUsersTabRoute,
        },
        {
          lottieFile:
            'https://assets7.lottiefiles.com/packages/lf20_j0gxxxqj.json',
          title: 'Participant Activity',
          route: classActivityTabRoute,
        },
        {
          lottieFile:
            'https://assets5.lottiefiles.com/packages/lf20_aKRZfw.json',
          title: 'Class Progress',
          route: classProgressTabRoute,
        },
        {
          lottieFile:
            'https://assets6.lottiefiles.com/packages/lf20_whfzz2v2.json',
          title: 'Contact Support',
          onClick: openModalContactSupport,
          cursor: styles.cursorCard,
          // url: educatorResources.support,
          // target: '_blank',
        },
      ];

      const setStyle = (idx: number) => {
        if (idx === 0) {
          return 'pl-0';
        }
        if (idx === 3) {
          return 'pr-0';
        }
      };

      return (
        <div className={styles.wrapper}>
          <Row className={cs(styles.cardContainer)}>
            {resources.map((item, index) => (
              <Col sm={6} md={3} lg={3} key={index} className={setStyle(index)}>
                <Card
                  as={item.route ? NavLink : undefined}
                  to={
                    item.route
                      ? item.route.formatPath({ id: match.params.id })
                      : { pathname: undefined }
                  }
                  onClick={item?.onClick}
                  className=""
                  // as={item.url || item.route ? NavLink : undefined}
                  // to={item.route ? item.route.formatPath({ id: match.params.id }) : { pathname: item.url }}
                  // target={item.target}
                  // target="_blank"
                  // className={`border-radius ${
                  //   item?.cursor ? cs(item.cursor) : ''
                  // }`}
                >
                  <Card.Body>
                    <Player
                      autoplay
                      keepLastFrame={true}
                      src={item.lottieFile}
                      style={{ height: '100px' }}
                    />
                    <Card.Title className={cs(styles.title, 'text-center')}>
                      {item.title}
                    </Card.Title>
                  </Card.Body>
                  <div className="card-border-wrapper">
                    <div className="card-border"></div>
                  </div>
                </Card>
              </Col>
            ))}
          </Row>
        </div>
      );
    };

    return (
      <PageContent
        header={renderHeader()}
        title={'Class Management'}
        breadcrumbs={[{ title: 'Classes', url: classListRoute.path }]}
        typeA={false}
        callback={openModalEditClass}
        actions={
          <>
            <Button onClick={openModalEditClass} variant="light">
              Edit class
            </Button>
            {user.role === UserRole.Admin && (
              <>
                {/* <Button
                  as={NavLink}
                  to={classCourseTabRoute.formatPath({ id: match.params.id })}
                  variant="link"
                  className="mx-2"
                >
                  Edit topics
                </Button> */}
                <Button
                  as={NavLink}
                  to={classSettingsTabRoute.formatPath({ id: match.params.id })}
                  variant="link"
                >
                  Settings
                </Button>
              </>
            )}
          </>
        }
      >
        {currentClass.loading || !currentClass.data ? (
          <CenteredBusySpinner />
        ) : (
          <ClassContext.Provider value={currentClass.data}>
            <RouteSwitch
              routes={[
                classUsersTabRoute,
                classActivityTabRoute,
                // classCourseTabRoute,
                classProgressTabRoute,
                classSettingsTabRoute,
              ]}
              params={{ id: match.params.id }}
            />
            <EditClassModal
              show={modalVisible.state}
              editClass={currentClass.data}
              onHide={handleOnHide}
            />
          </ClassContext.Provider>
        )}

        {modalContactState.visible && (
          <ContactSupport
            show
            onHide={modalContactState.hide}
            mailToContact={educatorResources.support}
          />
        )}
      </PageContent>
    );
  },
  { exact: false },
);
