import * as React from 'react';
import { ModalProps } from './ModalProps';
import { useApiClient } from '../hooks/useApiClient';
import { useAsyncCallback } from '../hooks/useAsyncCallback';
import { Modal, Button } from 'react-bootstrap';
import { BusyButton } from '../components/Busy';

export interface DeleteModalProps extends ModalProps {
  title: React.ReactNode;
  resource: string;
  id: string;
}

export const DeleteModal: React.FunctionComponent<DeleteModalProps> = ({
  show,
  onHide,
  title,
  resource,
  id,
  children,
}) => {
  const client = useApiClient();

  const deleteEntity = useAsyncCallback(async () => {
    await client.destroy(resource, id);
    onHide();
  }, [resource, id]);

  return (
    <Modal backdrop show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title className="text-danger">{title}</Modal.Title>
      </Modal.Header>

      <Modal.Body>{children}</Modal.Body>

      <Modal.Footer>
        <Button
          variant="outline-secondary"
          onClick={onHide}
          disabled={deleteEntity.busy}
        >
          Cancel
        </Button>
        <BusyButton
          variant="danger"
          busy={deleteEntity.busy}
          onClick={deleteEntity.call}
        >
          Delete
        </BusyButton>
      </Modal.Footer>
    </Modal>
  );
};
