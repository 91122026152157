import * as React from 'react';
import cs from 'classnames';
import * as _ from 'lodash';
import { FormGroup, FormGroupProps } from './FormGroup';

export interface CheckboxGroupProps extends FormGroupProps {
  value: boolean;
  onChange: (value: boolean) => void;
}

export const CheckboxGroup: React.FunctionComponent<CheckboxGroupProps> = ({
  label,
  value,
  onChange,
  className,
  ...props
}) => {
  const id = _.uniqueId('checkbox');

  return (
    <FormGroup {...props} className={cs('form-check', className)}>
      <input
        type="checkbox"
        checked={value}
        onChange={e => onChange(e.target.checked)}
        id={id}
        className="form-check-input"
      />
      <label
        htmlFor={id}
        className="form-check-label"
        onClick={e => e.stopPropagation()}
      >
        {label}
      </label>
    </FormGroup>
  );
};
