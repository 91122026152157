import {
  useMemo,
  useState,
  Dispatch,
  SetStateAction,
  useCallback,
} from 'react';

/**
 * Sets or removes an item from the LocalStorage
 * If value is null it removes the item
 */
function setOrRemoveItem(name: string, value: any) {
  if (value != null) {
    localStorage.setItem(name, JSON.stringify(value));
  } else {
    localStorage.removeItem(name);
  }
}

/**
 * Hook that persist the state in the LocalStorage
 */
export function useLocalState<S>(
  name: string,
  initialState: S,
): [S, Dispatch<SetStateAction<S>>];
export function useLocalState<S = undefined>(
  name: string,
): [S | undefined, Dispatch<SetStateAction<S | undefined>>];
export function useLocalState<S>(
  name: string,
  initialState?: S,
): [S, Dispatch<SetStateAction<S>>] {
  const storedState = useMemo(() => {
    const value = localStorage.getItem(name);

    return value == null
      ? initialState
      : value === 'undefined'
      ? undefined
      : JSON.parse(value);
  }, [initialState, name]);

  const [state, setState] = useState<S>(storedState);

  const storeState = useCallback(
    state => {
      if (typeof state === 'function') {
        setState(currentState => {
          const result = (state as Function)(currentState);
          setOrRemoveItem(name, result);
          return result;
        });
      } else {
        setState(state);
        setOrRemoveItem(name, state);
      }
    },
    [name],
  ) as Dispatch<SetStateAction<S>>;

  return [state, storeState];
}

export function createLocalState(name: string) {
  return <S>(initialState?: S): [S, Dispatch<SetStateAction<S>>] => {
    return useLocalState<S>(name, initialState as any);
  };
}
