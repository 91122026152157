import { useApiClientContext } from './useApiClientContext';

export function useUser() {
  const { user } = useApiClientContext();

  if (!user) {
    throw new Error('expected user');
  }

  return user;
}
