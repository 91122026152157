import * as React from 'react';
import { Column } from 'react-table';
import { Route } from '../Route';
// import { useApiQuery } from '../hooks/useApiQuery';
// import * as _ from 'lodash';
// import { CenteredBusySpinner } from '../components/Busy';
// import { GetClassGradesResponse } from '../../model/api/GetClassGradesResponse';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faCircle, faBan } from '@fortawesome/free-solid-svg-icons';
// import { faCircle as faOpenCircle } from '@fortawesome/free-regular-svg-icons';
// import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Class } from '../../model/entities/Class';
import { featureToggles } from '../featureToggles';
import { useTrackingCode } from 'react-hubspot-tracking-code-hook';
import { useUser } from '../hooks/useUser';
import CardImgIcon from '../images/class-card.svg';
import RightArrowIcon from '../images/r-arrow.svg';
// import CheckBoxIcon from '../images/check.svg';
import { RedstoneTable, Data } from '../components/Table';
import { useApiQuery } from '../hooks/useApiQuery';
const styles = require('../styles/ClassProgressTab.module.scss');

export const classProgressTabRoute = new Route<{ id: string }>(
  '/classes/:id/progress',
  ({ match, history }) => {
    const currentClass = useApiQuery<Class>(`/classes/${match.params.id}`);
    // const courses = useApiQuery<any>(`/courses`);
    const user = useUser();
    const { setPathPageView, setIdentity } = useTrackingCode();

    React.useEffect(() => {
      if (match?.params && user?.email) {
        setPathPageView(`/classes/${match.params.id}/progress`);
        setIdentity(user.email);
      }
    }, [user, match, setPathPageView, setIdentity]);

    // TOdo: Make a call to course metrics passing current class student ids
    // return object {k:v} where v is object with all dets

    const data = currentClass.data?.courses?.map((course: any) => ({
      id: course.id,
      courseName: course.name,
      // courseStatus: (
      //   <div className={`${styles.storyDefault}`}>Not started yet</div>
      // ),
      startedBy: 0,
      numberOfParticipants: currentClass.data?.students?.length,
      actions: (
        <a href="/">
          <img
            className={styles.rowImg}
            src={RightArrowIcon}
            height={14}
            alt="r-arr-1"
          />
        </a>
      ),
    }));

    const columns = React.useMemo<Column<Partial<Data>>[]>(
      () => [
        { Header: 'Course name', accessor: 'courseName' },
        // { Header: 'Status', accessor: 'courseStatus' },
        { Header: 'Nbr of students', accessor: 'numberOfParticipants' },
        { Header: 'Started by', accessor: 'startedBy' },
        { Header: '', accessor: 'actions' },
      ],
      [],
    );

    return (
      <>
        <div>
          <p>Quick overview</p>
        </div>
        <div className="row">
          <div className="col-lg-4 col-sm-6">
            <div className="card-body p-24 bg-white">
              <div className="class-card">
                <img src={CardImgIcon} height={36} alt="card-img-1" />
              </div>
              <div>
                <p className="m-0">Number of students</p>
              </div>
              <div>
                <h1 className="f-bold">
                  {currentClass.data?.students?.length || 0}
                </h1>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-sm-6">
            <div className="card-body p-24 bg-white">
              <div className="class-card">
                <img src={CardImgIcon} height={36} alt="card-img-2" />
              </div>
              <div>
                <p className="m-0">Number of classes</p>
              </div>
              <div>
                <h1 className="f-bold">1</h1>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-sm-6">
            <div className="card-body p-24 bg-white">
              <div className="class-card">
                <img src={CardImgIcon} height={36} alt="card-img-3" />
              </div>
              <div>
                <p className="m-0">Number of courses started</p>
              </div>
              <div>
                <h1 className="f-bold">
                  {currentClass.data?.courses?.length || 0}
                </h1>
              </div>
            </div>
          </div>
        </div>
        <section>
          <div>
            <p>Course statistics</p>
          </div>
          <div className={`${styles.tblWrapper} row`}>
            <RedstoneTable
              columns={columns}
              data={data || []}
              onClick={(id: any) =>
                history.push(`/courses/${id}:${match.params.id}`)
              }
            />
          </div>
        </section>
        {/* <section className={styles.wrapper}>
          <div>
            <p>My Classes</p>
          </div>
          <div className={`${styles.tblWrapper} row`}>
            <RedstoneTable
              columns={classesColumns}
              data={classesData}
              onClick={(id: any) => history.push(`/classes/${id}`)}
            />
          </div>
        </section> */}
      </>
    );
  },
  { title: 'Progress', enabled: featureToggles.reviewQueue },
);
