import * as React from 'react';
import cs from 'classnames';
import { ListGroup } from 'react-bootstrap';
import { BusySpinner } from './Busy';
import { OverflowMenu } from './OverflowMenu';

const styles = require('../styles/ListItem.module.scss');

export interface ListItemProps {
  className?: string;
  onClick?: React.MouseEventHandler<any>;
  title: React.ReactNode;
  meta?: React.ReactNode;
  actions?: React.ReactNode;
  right?: React.ReactNode;
  left?: React.ReactNode;
  busy?: boolean;
}

export const ListItem: React.StatelessComponent<ListItemProps> = ({
  onClick,
  className,
  children,
  ...props
}) => (
  <ListGroup.Item
    onClick={onClick}
    className={cs(styles.listItem, className, {
      clickable: onClick != null,
    })}
  >
    <ListItemRow {...props} />
    {children}
  </ListGroup.Item>
);

export const ListItemRow: React.StatelessComponent<ListItemProps> = ({
  title,
  meta,
  actions,
  right,
  left,
  busy,
}) => {
  return (
    <div className={styles.row}>
      {left && <div className={styles.left}>{left}</div>}
      {busy && (
        <div className={styles.left}>
          <BusySpinner />
        </div>
      )}
      <div className={styles.details}>
        <div className={styles.title}> {title}</div>
        {meta && <div className={styles.meta}>{meta}</div>}
      </div>
      {right && <div className={styles.right}>{right}</div>}
      {actions && (
        <div className={styles.actions}>
          <OverflowMenu>{actions}</OverflowMenu>
        </div>
      )}
    </div>
  );
};
