import * as React from 'react';
import { ModalProps } from './ModalProps';
import { useApiClient } from '../hooks/useApiClient';
import { useAsyncCallback } from '../hooks/useAsyncCallback';
import { Modal, Button } from 'react-bootstrap';
import { BusyButton } from '../components/Busy';
import { User } from '../../model/entities/User';

export interface ResetStudentPasswordModalProps extends ModalProps {
  student: User;
  classId: string;
}

export const ResetStudentPasswordModal: React.FunctionComponent<
  ResetStudentPasswordModalProps
> = ({ show, onHide, student, classId }) => {
  const client = useApiClient();

  const resetPassword = useAsyncCallback(async () => {
    await client.post(
      `/classes/${classId}/students/${student.id}/resetpassword`,
      {},
    );
    onHide();
  }, [student.id]);

  return (
    <Modal backdrop show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Reset password</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <p>
          This will reset any login associated with{' '}
          <strong>{student.name}</strong> to the default password listed for
          them.
        </p>
      </Modal.Body>

      <Modal.Footer>
        <Button
          variant="outline-secondary"
          onClick={onHide}
          disabled={resetPassword.busy}
        >
          Cancel
        </Button>
        <BusyButton
          variant="primary"
          busy={resetPassword.busy}
          onClick={resetPassword.call}
        >
          Reset password
        </BusyButton>
      </Modal.Footer>
    </Modal>
  );
};
