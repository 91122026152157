import * as React from 'react';
import { LanguageProficiency } from '../../model/entities/User';
import { Select } from './Select';

export interface LanguageProficiencyFieldProps {
  value?: LanguageProficiency;
  onChange: (value: LanguageProficiency) => void;
}

export const LanguageProficiencyField: React.FunctionComponent<
  LanguageProficiencyFieldProps
> = ({ value, onChange }: LanguageProficiencyFieldProps) => {
  const options = [
    { value: LanguageProficiency.Native, label: 'Native' },
    { value: LanguageProficiency.Fluent, label: 'Fluent' },
    { value: LanguageProficiency.Intermediate, label: 'Intermediate' },
    { value: LanguageProficiency.Basic, label: 'Basic' },
  ];
  
  return (
    <Select
      value={options.find(x => x.value === value)}
      onChange={(value: any) => onChange(value ? value.value : null)}
      options={options}
    />
  );
};
