import * as React from 'react';
import {Select} from './Select';
import {City} from 'country-state-city';
import {palestineCities} from '../country/palestineCities';

interface OptionType {
    value: string;
    label: object;
}

export interface NewCountryFieldProps {
    options: OptionType[];
    value: any;
    placeholder?: string;
    isInvalid?: boolean;
    className?: string;
    name?: string;
    setFieldValue?: any;
    setInputCityValue?: any;
}

export const CountryFieldSelect: React.FunctionComponent<NewCountryFieldProps> = ({
                                                                                      options,
                                                                                      value,
                                                                                      // onChange,
                                                                                      placeholder,
                                                                                      isInvalid,
                                                                                      className,
                                                                                      name,
                                                                                      setFieldValue,
                                                                                      setInputCityValue,
                                                                                  }) => {
    const onChangeSelect = (optionValue: any, setFieldValue: Function) => {
        setFieldValue(name, optionValue);

        setFieldValue('address', '');
        setFieldValue('country', optionValue);
        const getCitByCountry = City.getCitiesOfCountry(optionValue.value.iso_code);
        let getCitiesBySelectedCountry: any = optionValue.value.iso_code === 'PS' ? palestineCities : getCitByCountry;
        const getNewCitiesBySelectedCountry = getCitiesBySelectedCountry.map((city: { name: string, countryCode: string, latitude: string, longitude: string, }) => {
            return {
                label: city.name,
                value: {
                    city_name: city.name,
                    country_code: city.countryCode,
                    latitude: city.latitude,
                    longitude: city.longitude,
                },
            };
        });
        setInputCityValue(getNewCitiesBySelectedCountry);
    };
    return (
        <Select
            name={name}
            options={options}
            value={value}
            onChange={(optionValue) => onChangeSelect(optionValue, setFieldValue)}
            placeholder={placeholder}
            isInvalid={isInvalid}
            className={className}
        />
    );
};
