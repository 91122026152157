import * as React from 'react';
import { Route, RouteParams } from '../Route';
import { Route as RouterRoute, Switch, Redirect } from 'react-router-dom';

export interface RouteSwitchProps<T extends RouteParams> {
  routes: Route<T>[];
  params?: T;
}

export function RouteSwitch<T extends RouteParams>({
  routes,
  params = {} as any,
}: RouteSwitchProps<T>) {
  return (
    <Switch>
      {renderRoutes(routes)}

      <RouterRoute
        path="*"
        render={() => <Redirect to={routes[0].formatPath(params)} />}
      />
    </Switch>
  );
}

export function renderRoutes<T extends RouteParams>(routes: Route<T>[]) {
  return routes.map(route => (
    <RouterRoute
      key={route.path}
      path={route.path}
      component={route.view}
      exact={route.options.exact}
    />
  ));
}
