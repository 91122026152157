import * as React from 'react';
import Avatar from '../images/Avatar.png';
const styles = require('../styles/UserCard.module.scss');

interface UserCardProps {
  fullName: string;
  email: string;
  role?: string;
}

export const UserCard: React.FunctionComponent<UserCardProps> = ({
  fullName,
  email,
  role,
}) => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.profile}>
        <img src={Avatar} alt="avatar" />
        <small>{fullName}</small>
      </div>
      <div className={styles.email}>
        <small>{email}</small>
      </div>
    </div>
  );
};
