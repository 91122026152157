import { useApiClient } from './useApiClient';
import { useAsyncEffect } from './useAsyncEffect';
import { ObserveResultsOptions } from '../util/AuthenticatedApiClient';

export function useObserveResults<T>(
  url: string,
  callback: (result: T) => void,
  options: ObserveResultsOptions = {},
) {
  const client = useApiClient();

  const loading = useAsyncEffect(async () => {
    return await client.observeResults(url, callback, options);
  }, [url, client]);

  const reload = () => {
    client.reload(options.observeUrl || url);
  };

  return { loading, reload };
}
