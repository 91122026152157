import * as _ from 'lodash';
import { useCallback } from 'react';

export function useArray<T>(
  items: T[],
  callback: (value: T[]) => void,
  customizer?: _.IsEqualCustomizer,
) {
  const update = useCallback(
    (changed: T) => {
      callback(
        items.map(item =>
          _.isEqualWith(item, changed, customizer) ? changed : item,
        ),
      );
    },
    [items, callback, customizer],
  );

  const remove = useCallback(
    (removed: T) => {
      callback(items.filter(item => !_.isEqualWith(item, removed, customizer)));
    },
    [items, callback, customizer],
  );

  const add = useCallback(
    (item: T) => {
      callback([...items, item]);
    },
    [items, callback],
  );

  return { update, remove, add, items };
}

export const idEqual: _.IsEqualCustomizer = (a, b) => a.id === b.id;
