import * as React from 'react';
import { Route } from '../Route';
import { ClassContext } from '../routes/classDetailsRoute';
import { useAsyncCallback } from '../hooks/useAsyncCallback';
import { PageSection } from '../components/PageSection';
import { useApiClient } from '../hooks/useApiClient';
import copy from 'copy-text-to-clipboard';
import { Toggle } from '../components/Toggle';
import { BusySpinner } from '../components/Busy';
import { createHref } from '../util/createHref';
import { Row, Col, Form } from 'react-bootstrap';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faCopy } from '@fortawesome/free-solid-svg-icons';
import { selfRegistrationRoute } from '../routes/selfRegistrationRoute';
// import { QuickEditField } from '../components/QuickEditField';
// import { filterUndefined } from '../../util/filterUndefined';
// import { FormGroup } from '../components/FormGroup';
import { useCreateNotification } from '../hooks/useCreateNotification';

export const classSettingsTabRoute = new Route<{ id: string }>(
  '/classes/:id/settings',
  ({ history, location }) => {
    const currentClass = React.useContext(ClassContext);
    if (!currentClass) return null;

    const client = useApiClient();
    const createNotification = useCreateNotification();

    const updateSelfRegistration = useAsyncCallback(
      async (value: boolean) => {
        const response = await client.update('/classes', currentClass.id!, {
          selfRegistration: {
            ...currentClass.selfRegistration,
            enabled: value,
          },
        });

        if (value) {
          const link = createHref(history, location, selfRegistrationRoute, {
            token: response.data.selfRegistration.token,
          });

          copy(link);

          createNotification(
            'Enabled',
            'Self-registration is enabled and the link has been copied to your clipboard.',
            'success',
            { hideAfter: 3000 },
          );
        } else {
          createNotification(
            'Disabled',
            'Self-registration has been disabled.',
            'success',
            { hideAfter: 3000 },
          );
        }
      },
      [currentClass],
    );

    // const update = useAsyncCallback(
    //   async (value: any) => {
    //     await client.update('/classes', currentClass.id!, {
    //       selfRegistration: filterUndefined(
    //         {
    //           ...currentClass.selfRegistration,
    //           ...value,
    //         },
    //         false,
    //       ),
    //     });

    //     createNotification('Updated', 'Settings updated', 'success', {
    //       hideAfter: 3000,
    //     });
    //   },
    //   [currentClass.id, currentClass.selfRegistration],
    // );

    const link =
      currentClass.selfRegistration &&
      currentClass.selfRegistration.enabled &&
      createHref(history, location, selfRegistrationRoute, {
        token: currentClass.selfRegistration.token!,
      });

    return (
      <div>
        <PageSection title="Self-registration">
          <p>
            You can allow students/parents to sign up for this class on their
            own.
          </p>

          <Form>
            <Form.Group as={Row} className="align-items-center">
              <Form.Label column lg={3}>
                Enabled
              </Form.Label>
              <Col lg={9}>
                <div className="d-flex flex-row align-items-center">
                  <Toggle
                    value={
                      currentClass.selfRegistration &&
                      currentClass.selfRegistration.enabled
                    }
                    onChange={updateSelfRegistration.call}
                    className="mr-2"
                    disabled={updateSelfRegistration.busy}
                  >
                    {currentClass.selfRegistration &&
                    currentClass.selfRegistration.enabled
                      ? 'Enabled'
                      : 'Disabled'}
                  </Toggle>

                  {updateSelfRegistration.busy && <BusySpinner size="sm" />}
                </div>
              </Col>
            </Form.Group>

            {link && (
              <>
                {/* <Form.Group as={Row}>
                  <Form.Label column lg={3}>
                    Registration page
                  </Form.Label>
                  <Col lg={9}>
                    <button
                      className="btn btn-link"
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        copy(link);
                        createNotification(
                          'Copied',
                          'Link copied to clipboard',
                          'success',
                          {
                            hideAfter: 3000,
                          },
                        );
                      }}
                    >
                      <span className="font-size-sm">
                        <FontAwesomeIcon icon={faCopy} /> copy
                      </span>
                    </button>

                    <div className="mt-2 font-size-sm text-muted">
                      <a href={link} target="_blank" rel="noopener noreferrer">
                        {link}
                      </a>
                    </div>
                  </Col>
                </Form.Group> */}

                {/* <Form.Group as={Row}>
                  <Form.Label column lg={3}>
                    Limit
                  </Form.Label>
                  <Col lg={9}>
                    <QuickEditField
                      value={currentClass.selfRegistration!.limit || ''}
                      renderView={() => {
                        return currentClass.selfRegistration!.limit || 'None';
                      }}
                      validate={(value: any) => {
                        return !/^[0-9]+$/.test(value) && value !== ''
                          ? 'Must be a whole number or blank'
                          : '';
                      }}
                      renderEdit={({
                        currentValue,
                        setCurrentValue,
                        error,
                        save,
                      }) => {
                        return (
                          <FormGroup
                            error={error}
                            help="Set a maximum number of students for the class, leave blank for no limit"
                          >
                            <FormControl
                              value={currentValue.toString()}
                              onChange={(e: any) => {
                                setCurrentValue(e.target.value);
                              }}
                              onKeyPress={(
                                e: React.KeyboardEvent<HTMLInputElement>,
                              ) => {
                                if (e.key === 'Enter') {
                                  e.preventDefault();
                                  save();
                                }
                              }}
                              autoFocus
                            />
                          </FormGroup>
                        );
                      }}
                      onChange={(limit) => {
                        return update.call({ limit });
                      }}
                    />
                  </Col>
                </Form.Group> */}

                {/* <Form.Group as={Row}>
                  <Form.Label column lg={3}>
                    Waiting list link
                  </Form.Label>
                  <Col lg={9}>
                    <QuickEditField
                      value={currentClass.selfRegistration!.waitlistUrl}
                      renderView={() => {
                        return (
                          currentClass.selfRegistration!.waitlistUrl || 'None'
                        );
                      }}
                      renderEdit={({ currentValue, setCurrentValue, save }) => {
                        return (
                          <FormGroup help="Set a link to direct users to when the class is full">
                            <FormControl
                              value={currentValue}
                              onChange={(e: any) => {
                                setCurrentValue(e.target.value);
                              }}
                              onKeyPress={(
                                e: React.KeyboardEvent<HTMLInputElement>,
                              ) => {
                                if (e.key === 'Enter') {
                                  e.preventDefault();
                                  save();
                                }
                              }}
                              autoFocus
                            />
                          </FormGroup>
                        );
                      }}
                      onChange={(waitlistUrl) => {
                        return update.call({ waitlistUrl });
                      }}
                    />
                  </Col>
                </Form.Group> */}
              </>
            )}
          </Form>
        </PageSection>

        {/* <PageSection title="Individual course enrol">
          <Form>
            <Form.Group as={Row} className="align-items-center">
              <Form.Label column lg={3}>
                Enabled
              </Form.Label>
              <Col lg={9}>
                <div className="d-flex flex-row align-items-center">
                  <Toggle
                    value={
                      currentClass.selfRegistration &&
                      currentClass.selfRegistration.individualCourseEnrol
                    }
                    onChange={(value) =>
                      update.call({ individualCourseEnrol: value })
                    }
                    className="mr-2"
                    disabled={updateSelfRegistration.busy}
                  >
                    {currentClass.selfRegistration &&
                    currentClass.selfRegistration.individualCourseEnrol
                      ? 'Enabled'
                      : 'Disabled'}
                  </Toggle>

                  {updateSelfRegistration.busy && <BusySpinner size="sm" />}
                </div>
              </Col>
            </Form.Group>
          </Form>
        </PageSection> */}

        {/* <PageSection title="Optional fields">
          <Form>
            <Form.Group as={Row} className="align-items-center">
              <Form.Label column lg={3}>
                Ask for school
              </Form.Label>
              <Col lg={9}>
                <div className="d-flex flex-row align-items-center">
                  <Toggle
                    value={
                      currentClass.selfRegistration &&
                      currentClass.selfRegistration.school
                    }
                    onChange={(value) => update.call({ school: value })}
                    className="mr-2"
                    disabled={update.busy}
                  >
                    {currentClass.selfRegistration &&
                    currentClass.selfRegistration.school
                      ? 'Enabled'
                      : 'Disabled'}
                  </Toggle>

                  {update.busy && <BusySpinner size="sm" />}
                </div>
              </Col>
            </Form.Group>
            <Form.Group as={Row} className="align-items-center">
              <Form.Label column lg={3}>
                Ask for other languages
              </Form.Label>
              <Col lg={9}>
                <div className="d-flex flex-row align-items-center">
                  <Toggle
                    value={
                      currentClass.selfRegistration &&
                      currentClass.selfRegistration.languages
                    }
                    onChange={(value) => update.call({ languages: value })}
                    className="mr-2"
                    disabled={update.busy}
                  >
                    {currentClass.selfRegistration &&
                    currentClass.selfRegistration.languages
                      ? 'Enabled'
                      : 'Disabled'}
                  </Toggle>

                  {update.busy && <BusySpinner size="sm" />}
                </div>
              </Col>
            </Form.Group>
            <Form.Group as={Row} className="align-items-center">
              <Form.Label column lg={3}>
                Marginalized identity
              </Form.Label>
              <Col lg={9}>
                <div className="d-flex flex-row align-items-center">
                  <Toggle
                    value={
                      currentClass.selfRegistration &&
                      currentClass.selfRegistration.marginalizedIdentity
                    }
                    onChange={(value) =>
                      update.call({ marginalizedIdentity: value })
                    }
                    className="mr-2"
                    disabled={update.busy}
                  >
                    {currentClass.selfRegistration &&
                    currentClass.selfRegistration.marginalizedIdentity
                      ? 'Enabled'
                      : 'Disabled'}
                  </Toggle>

                  {update.busy && <BusySpinner size="sm" />}
                </div>
              </Col>
            </Form.Group>
          </Form>
        </PageSection> */}

        {/* <PageSection title="Required fields">
          <Form>
            <Form.Group as={Row} className="align-items-center">
              <Form.Label column lg={3}>
                Ask for parent details
              </Form.Label>
              <Col lg={9}>
                <div className="d-flex flex-row align-items-center">
                  <Toggle
                    value={
                      currentClass.selfRegistration &&
                      currentClass.selfRegistration.parentDetails
                    }
                    onChange={(value) => update.call({ parentDetails: value })}
                    className="mr-2"
                    disabled={update.busy}
                  >
                    {currentClass.selfRegistration &&
                    currentClass.selfRegistration.parentDetails
                      ? 'Enabled'
                      : 'Disabled'}
                  </Toggle>

                  {update.busy && <BusySpinner size="sm" />}
                </div>
              </Col>
            </Form.Group>
            <Form.Group as={Row} className="align-items-center">
              <Form.Label column lg={3}>
                Ask for grade level
              </Form.Label>
              <Col lg={9}>
                <div className="d-flex flex-row align-items-center">
                  <Toggle
                    value={
                      currentClass.selfRegistration &&
                      currentClass.selfRegistration.gradeLevel
                    }
                    onChange={(value) => update.call({ gradeLevel: value })}
                    className="mr-2"
                    disabled={update.busy}
                  >
                    {currentClass.selfRegistration &&
                    currentClass.selfRegistration.gradeLevel
                      ? 'Enabled'
                      : 'Disabled'}
                  </Toggle>

                  {update.busy && <BusySpinner size="sm" />}
                </div>
              </Col>
            </Form.Group>
            <Form.Group as={Row} className="align-items-center">
              <Form.Label column lg={3}>
                Ask for gender
              </Form.Label>
              <Col lg={9}>
                <div className="d-flex flex-row align-items-center">
                  <Toggle
                    value={
                      currentClass.selfRegistration &&
                      currentClass.selfRegistration.gender
                    }
                    onChange={(value) => update.call({ gender: value })}
                    className="mr-2"
                    disabled={update.busy}
                  >
                    {currentClass.selfRegistration &&
                    currentClass.selfRegistration.gender
                      ? 'Enabled'
                      : 'Disabled'}
                  </Toggle>

                  {update.busy && <BusySpinner size="sm" />}
                </div>
              </Col>
            </Form.Group>
            <Form.Group as={Row} className="align-items-center">
              <Form.Label column lg={3}>
                Ask for age
              </Form.Label>
              <Col lg={9}>
                <div className="d-flex flex-row align-items-center">
                  <Toggle
                    value={
                      currentClass.selfRegistration &&
                      currentClass.selfRegistration.age
                    }
                    onChange={(value) => update.call({ age: value })}
                    className="mr-2"
                    disabled={update.busy}
                  >
                    {currentClass.selfRegistration &&
                    currentClass.selfRegistration.age
                      ? 'Enabled'
                      : 'Disabled'}
                  </Toggle>

                  {update.busy && <BusySpinner size="sm" />}
                </div>
              </Col>
            </Form.Group>
            <Form.Group as={Row} className="align-items-center">
              <Form.Label column lg={3}>
                Ask for address
              </Form.Label>
              <Col lg={9}>
                <div className="d-flex flex-row align-items-center">
                  <Toggle
                    value={
                      currentClass.selfRegistration &&
                      currentClass.selfRegistration.address
                    }
                    onChange={(value) => update.call({ address: value })}
                    className="mr-2"
                    disabled={update.busy}
                  >
                    {currentClass.selfRegistration &&
                    currentClass.selfRegistration.address
                      ? 'Enabled'
                      : 'Disabled'}
                  </Toggle>

                  {update.busy && <BusySpinner size="sm" />}
                </div>
              </Col>
            </Form.Group>
            <Form.Group as={Row} className="align-items-center">
              <Form.Label column lg={3}>
                Race
              </Form.Label>
              <Col lg={9}>
                <div className="d-flex flex-row align-items-center">
                  <Toggle
                    value={
                      currentClass.selfRegistration &&
                      currentClass.selfRegistration.race
                    }
                    onChange={(value) => update.call({ race: value })}
                    className="mr-2"
                    disabled={update.busy}
                  >
                    {currentClass.selfRegistration &&
                    currentClass.selfRegistration.race
                      ? 'Enabled'
                      : 'Disabled'}
                  </Toggle>

                  {update.busy && <BusySpinner size="sm" />}
                </div>
              </Col>
            </Form.Group>
            <Form.Group as={Row} className="align-items-center">
              <Form.Label column lg={3}>
                Religion
              </Form.Label>
              <Col lg={9}>
                <div className="d-flex flex-row align-items-center">
                  <Toggle
                    value={
                      currentClass.selfRegistration &&
                      currentClass.selfRegistration.religion
                    }
                    onChange={(value) => update.call({ religion: value })}
                    className="mr-2"
                    disabled={update.busy}
                  >
                    {currentClass.selfRegistration &&
                    currentClass.selfRegistration.religion
                      ? 'Enabled'
                      : 'Disabled'}
                  </Toggle>

                  {update.busy && <BusySpinner size="sm" />}
                </div>
              </Col>
            </Form.Group>
            <Form.Group as={Row} className="align-items-center">
              <Form.Label column lg={3}>
                Disability
              </Form.Label>
              <Col lg={9}>
                <div className="d-flex flex-row align-items-center">
                  <Toggle
                    value={
                      currentClass.selfRegistration &&
                      currentClass.selfRegistration.disability
                    }
                    onChange={(value) => update.call({ disability: value })}
                    className="mr-2"
                    disabled={update.busy}
                  >
                    {currentClass.selfRegistration &&
                    currentClass.selfRegistration.disability
                      ? 'Enabled'
                      : 'Disabled'}
                  </Toggle>

                  {update.busy && <BusySpinner size="sm" />}
                </div>
              </Col>
            </Form.Group>
            <Form.Group as={Row} className="align-items-center">
              <Form.Label column lg={3}>
                Ask to accept the terms
              </Form.Label>
              <Col lg={9}>
                <div className="d-flex flex-row align-items-center">
                  <Toggle
                    value={
                      currentClass.selfRegistration &&
                      currentClass.selfRegistration.terms
                    }
                    onChange={(value) => update.call({ terms: value })}
                    className="mr-2"
                    disabled={update.busy}
                  >
                    {currentClass.selfRegistration &&
                    currentClass.selfRegistration.terms
                      ? 'Enabled'
                      : 'Disabled'}
                  </Toggle>

                  {update.busy && <BusySpinner size="sm" />}
                </div>
              </Col>
            </Form.Group>
            <Form.Group as={Row} className="align-items-center">
              <Form.Label column lg={3}>
                Ask to accept partner terms
              </Form.Label>
              <Col lg={9}>
                <QuickEditField
                  value={
                    (currentClass.selfRegistration &&
                      currentClass.selfRegistration.termsPartner) ||
                    ''
                  }
                  renderView={() => {
                    return (
                      (currentClass.selfRegistration &&
                        currentClass.selfRegistration.termsPartner) ||
                      'None'
                    );
                  }}
                  renderEdit={({
                    currentValue,
                    setCurrentValue,
                    error,
                    save,
                  }) => {
                    return (
                      <FormGroup
                        error={error}
                        help="Set the HTML label/text to be shown in the form"
                      >
                        <FormControl
                          value={currentValue.toString()}
                          onChange={(e: any) => {
                            setCurrentValue(e.target.value);
                          }}
                          onKeyPress={(
                            e: React.KeyboardEvent<HTMLInputElement>,
                          ) => {
                            if (e.key === 'Enter') {
                              e.preventDefault();
                              save();
                            }
                          }}
                          autoFocus
                        />
                      </FormGroup>
                    );
                  }}
                  onChange={(termsPartner) => {
                    return update.call({ termsPartner });
                  }}
                />
              </Col>
            </Form.Group>
          </Form>
        </PageSection> */}
      </div>
    );
  },
  { title: 'Settings', adminOnly: true },
);
