import * as React from 'react';
import { Button } from 'react-bootstrap';

interface ButtonLinkProps {
  onClick: () => void;
  text: string;
  className: string;
}

export const ButtonLink: React.FunctionComponent<ButtonLinkProps> = ({
  onClick,
  text,
  className,
}) => {
  return (
    <div>
      <Button variant="link" onClick={onClick} className={className}>
        {text}
      </Button>
    </div>
  );
};
