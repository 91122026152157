import * as React from 'react';
import { Modal, Form, Col } from 'react-bootstrap';
import { useApiQuery } from '../hooks/useApiQuery';
import { ModalProps } from './ModalProps';
import { ButtonLink } from '../components/ButtonLink';
import { FaqsModal } from '../modals/FaqsModal';
import { Player } from '@lottiefiles/react-lottie-player';
import { useFaqsState } from '../hooks/useFaqsState';

export interface EditClassModalProps extends ModalProps {
  mailToContact?: string;
}

export const ContactSupport: React.FunctionComponent<EditClassModalProps> = ({
  show,
  onHide,
  mailToContact,
}) => {
  const faqData = useApiQuery('/faqs', {});
  const modalFaqsState = useFaqsState();
  return (
    <Modal
      backdrop
      show={show}
      onHide={onHide}
      size="lg"
      contentClassName="p-4 border-radius"
    >
      <Modal.Body>
        <Form.Row>
          <Form.Row>
            <Col xs={7}>
              <h5>We’re here to help!</h5>
              <p>
                We understand new platforms and programs can be challenging. The
                Global Nomads Group team is here to support you.
              </p>
              <p>
                Available to you is a <b>Global Facilitator Community</b> with
                educators and facilitators, like you, who are implementing our
                program. Reach out to them for advice!
              </p>
              <p>
                We also have a set of Frequently Asked Questions and Classroom
                Resources that include Facilitator Guides & Implementation Tips.
              </p>
              <p>
                You can always reach out to a team member. Check your email
                (Subject Line: Welcome to Global Nomads Group!) to find out who
                your dedicated GNG contact is.
              </p>
            </Col>
            <Col xs={5}>
              <Player
                autoplay
                keepLastFrame={true}
                src={
                  'https://assets6.lottiefiles.com/packages/lf20_whfzz2v2.json'
                }
              />
            </Col>
          </Form.Row>
          <Col xs={12}>
            <div className="item-link">
              <a
                target={'_blank'}
                rel="noreferrer noopener"
                className={'text-center'}
                href={'https://www.linkedin.com/groups/12893011/'}
              >
                Ask the community
              </a>
            </div>
            <div className="item-link">
              <ButtonLink
                className="p-0"
                text={'View FAQs'}
                onClick={() => modalFaqsState.show()}
              />
            </div>
            <div className="item-link">
              <a
                className={'text-center'}
                href={
                  'https://drive.google.com/drive/folders/1HrajJ7vQ2mdfjmr2QRxHQrkb4pbh7a2u'
                }
              >
                Check out our Classroom Resources
              </a>
            </div>
          </Col>
        </Form.Row>
      </Modal.Body>
      {modalFaqsState.visible && (
        <FaqsModal show onHide={modalFaqsState.hide} content={faqData} />
      )}
    </Modal>
  );
};
