import * as React from 'react';
import { DatePicker } from '../components/DatePicker';
import { Form, Col } from 'react-bootstrap';
import * as moment from 'moment';
import { useLocalState } from '../hooks/useLocalState';

export interface Props {
  onUpdateDates: (
    startDate: Date | undefined,
    endDate?: Date | undefined,
  ) => void;
}

export const DateFilter: React.FunctionComponent<Props> = ({
  onUpdateDates,
}) => {
  const [startDate, setStartDate] = useLocalState<Date | undefined>(
    'DateFilter:startDate',
  );

  const [endDate, setEndDate] = useLocalState<Date | undefined>(
    'DateFilter:endDate',
  );

  const onChangeDate = (sDate: Date | undefined, eDate?: Date | undefined) => {
    if (sDate !== startDate) {
      setStartDate(sDate);
    } else if (eDate !== endDate) {
      setEndDate(eDate);
    }
    onUpdateDates(sDate, eDate);
  };

  return (
    <>
      <Form.Row>
        <Col>
          <DatePicker
            selected={startDate ? moment.utc(startDate).toDate() : undefined}
            placeholder="Filter by start date"
            value={startDate ? moment.utc(startDate).toDate() : undefined}
            onChange={(value) => {
              onChangeDate(value, endDate);
            }}
            selectsStart
            startDate={startDate}
            endDate={endDate}
            dateFormat="MMM d yyyy"
          />
        </Col>
        <Col>
          <DatePicker
            selected={endDate ? moment.utc(startDate).toDate() : undefined}
            placeholder="Filter by end date"
            value={endDate ? moment.utc(endDate).toDate() : undefined}
            onChange={(value) => {
              onChangeDate(startDate, value);
            }}
            selectsEnd
            startDate={startDate}
            endDate={endDate}
            minDate={startDate}
            dateFormat="MMM d yyyy"
          />
        </Col>
      </Form.Row>
    </>
  );
};
