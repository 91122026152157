import * as React from 'react';
import cs from 'classnames';

const styles = require('../styles/PageSection.module.scss');

export interface PageSectionProps {
  title: React.ReactNode;
  className?: string;
}

export const PageSection: React.FunctionComponent<PageSectionProps> = ({
  title,
  children,
  className,
}) => {
  return (
    <div className={cs(styles.container, className)}>
      <div className={styles.title}>{title}</div>

      {children}
    </div>
  );
};
