import * as React from 'react';
import * as copy from 'copy-text-to-clipboard';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencilAlt } from '@fortawesome/free-solid-svg-icons';
import BackCaret from '../../frontend/images/Caret.svg';
import Line from '../../frontend/images/Line.svg';
import Tooltip from '../components/Tooltip';
import { QButton } from './QButton';

const styles = require('../styles/PageContent.module.scss');

export interface PageContentProps {
  title?: string | React.ReactNode;
  header?: React.ReactNode;
  headerFirst?: boolean;
  breadcrumbs?: Breadcrumb[];
  actions?: React.ReactNode;
  typeA?: boolean;
  callback: () => void;
}

export interface Breadcrumb {
  title: React.ReactNode;
  url: string;
}

export const PageContent: React.FunctionComponent<PageContentProps> = ({
  title,
  header,
  headerFirst,
  breadcrumbs,
  children,
  actions,
  callback,
  typeA = true,
}) => {
  const checkHomePage = () => {
    const path = window.location.pathname.split('/').pop();
    return path?.includes('classes');
  };
  const getClassId = () => {
    const path = window.location.pathname.split('/');
    if (path.includes('classes') && path.length >= 3) {
      return path[2];
    }
    return '';
  };

  const classId = getClassId();

  const [isHomePage] = React.useState(checkHomePage());
  React.useEffect(() => {
    document.title = title
      ? `${title} | Class Management Hub - Global Nomads Group`
      : 'Class Management Hub - Global Nomads Group';
  }, [title]);

  const goBack = () => {
    if (!isHomePage) {
      window.history.back();
    }
  };

  return (
    <div className={styles.container}>
      {headerFirst && <div className={styles.header}>{header}</div>}
      {typeA && (
        <div>
          <div className={styles.title}>
            {/* {breadcrumbs && (
          <div className={styles.breadcrumbs}>
            {breadcrumbs.map((item) => (
              <React.Fragment key={item.url}>
                <Link className={styles.crumb} to={item.url}>
                  {item.title}
                </Link>{' '}
                /
              </React.Fragment>
            ))}
          </div>
        )} */}
            {!isHomePage && (
              <div>
                <button className="back-btn-btn" onClick={() => goBack()}>
                  <svg
                    width="12"
                    height="10"
                    viewBox="0 0 12 10"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className="back-btn"
                  >
                    <path
                      d="M11.5 5.5C11.7761 5.5 12 5.27614 12 5C12 4.72386 11.7761 4.5 11.5 4.5L1.80298 4.5L5.83448 0.871651C6.03974 0.686921 6.05638 0.370777 5.87165 0.165522C5.68692 -0.039733 5.37077 -0.0563723 5.16552 0.128357L0.165518 4.62835C0.0601606 4.72317 6.19579e-09 4.85826 0 5C-6.19579e-09 5.14174 0.0601606 5.27683 0.165518 5.37165L5.16552 9.87164C5.37077 10.0564 5.68692 10.0397 5.87165 9.83448C6.05638 9.62922 6.03974 9.31308 5.83448 9.12835L1.80298 5.5L11.5 5.5Z"
                      fill="#4F88B3"
                    ></path>
                  </svg>
                </button>
              </div>
            )}
            <div className={styles.titleContent}>
              {title || '\u00A0'}
              <span>
                {classId && (
                  <Tooltip text="Copy">
                    <small
                      className={styles.smallCopy}
                      onClick={() => copy(classId)}
                    >
                      ID: {classId}
                    </small>
                  </Tooltip>
                )}
              </span>
            </div>

            {actions && <div className={styles.actions}>{actions}</div>}
          </div>
        </div>
      )}

      {!typeA && (
        <div className={styles.headerB}>
          <div className={styles.wrap}>
            <img src={BackCaret} alt={'BackCaret'} />
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <span className={styles.backBtn} onClick={() => goBack()}>
                Back
              </span>
              <img src={Line} alt="Line" />
            </div>
            <div className={styles.headerWrapper}>
              <div>
                <span className={styles.head}>{title}</span>
              </div>
              <div>
                <QButton
                  variant="outline-danger"
                  className={`${styles.btnStyle} mr-3`}
                  onClick={() => callback()}
                >
                  <FontAwesomeIcon icon={faPencilAlt} className="mr-2" />
                  Edit
                </QButton>
              </div>
            </div>
          </div>
        </div>
      )}

      {!headerFirst && <div className={styles.header}>{header}</div>}
      {/* {isUserRoute && <div className={styles.header}>{header}</div>} */}
      <div className={!typeA ? styles.contentB : styles.content}>
        {children}
      </div>
    </div>
  );
};
