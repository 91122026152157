import * as React from 'react';
import * as moment from 'moment';
import { List } from '../components/List';
import { Class } from '../../model/entities/Class';
import { ListItem } from '../components/ListItem';
import { Button, Badge, Dropdown, Card, Row, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faPlusCircle,
  faUser,
  faUserSlash,
} from '@fortawesome/free-solid-svg-icons';
import cs from 'classnames';
import { PageContent } from '../components/PageContent';
import { Route } from '../Route';
import { EditClassModal } from '../modals/EditClassModal';
import { ContactSupport } from '../modals/ContactSupport';
import { FaqsModal } from '../modals/FaqsModal';
import { GlobalFacilitatorCommunityModal } from '../modals/GlobalFacilitatorCommunity';
import { classDetailsRoute } from './classDetailsRoute';
import { UserRole } from '../../model/entities/User';
import { useUser } from '../hooks/useUser';
import { useApiQuery } from '../hooks/useApiQuery';
import * as _ from 'lodash';
import { useEditModalState } from '../hooks/useEditModalState';
import { useContactSupportState } from '../hooks/useContactSupportState';
import { useFaqsState } from '../hooks/useFaqsState';
import { useGfcState } from '../hooks/useGfcState';
import { DateRangeBadge } from '../components/DateRangeBadge';
import { DateFilter } from '../components/DateFilter';
import { useLocalState } from '../hooks/useLocalState';
import { useApiClient } from '../hooks/useApiClient';
import { useLocalBoolean } from '../hooks/useLocalBoolean';
// import { Toggle } from '../components/Toggle';
import { Player } from '@lottiefiles/react-lottie-player';
import { remoteConfig } from '../..';
import { useTrackingCode } from 'react-hubspot-tracking-code-hook';
const styles = require('../styles/ClassListRoute.module.scss');

export const classListRoute = new Route(
  '/classes',
  ({ history }) => {
    const modalState = useEditModalState<Class>();
    const modalContactState = useContactSupportState<Class>();
    const modalFaqsState = useFaqsState<Class>();
    const modalGfcState = useGfcState<Class>();
    const user = useUser();
    const client = useApiClient();
    const educatorResources = JSON.parse(
      remoteConfig.getValue('educatorResources').asString(),
    );

    const includeArchivedClasses = useLocalBoolean(
      'Classes:includeArchivedClasses',
      false,
    );

    const [startDate, setStartDate] = useLocalState<Date>(
      'DateFilter:startDate',
    );

    const [endDate, setEndDate] = useLocalState<Date>('DateFilter:endDate');

    let dateParams = startDate ? `&startDate=${startDate}` : '';
    dateParams += endDate ? `&endDate=${endDate}` : '';
    const [url, setUrl] = React.useState(
      `/classes?includeInactive=${includeArchivedClasses.state}${dateParams}`,
    );

    // const updateIncludeInactive = (value: boolean) => {
    //   updateUrl(startDate, endDate, value);
    // };

    const updateDates = (startDate?: Date, endDate?: Date) => {
      setStartDate(startDate);
      setEndDate(endDate);
      updateUrl(startDate, endDate, includeArchivedClasses.state);
    };

    const updateUrl = (
      startDate?: Date,
      endDate?: Date,
      includeInactive?: boolean,
    ) => {
      let dateParams = startDate
        ? `&startDate=${moment.utc(startDate).toISOString()}`
        : '';
      dateParams += endDate
        ? `&endDate=${moment.utc(endDate).toISOString()}`
        : '';
      setUrl(`/classes?includeInactive=${includeInactive}${dateParams}`);
    };

    const classes = useApiQuery<Class[]>(url, []);
    const faqData = useApiQuery('/faqs', {});

    const sortedClasses = _.orderBy(
      classes.data.map((x) => ({
        ...x,
        member:
          user.role === UserRole.Admin &&
          user.classes &&
          user.classes.includes(x.id!),
      })),
      [(x) => x.member, (x) => x.name],
      ['desc', 'asc'],
    );

    const handleArchiveUnarchive = async (item: Class) => {
      var confirmed = window.confirm(
        `Are you sure you want to ${
          item.active ? 'archive' : 'unarchive'
        } this class?`,
      );
      if (!confirmed) return;
      if (item.active) {
        await client.post(`/classes/${item.id}/deactivate`, {});

        let next = '';
        do {
          const result = await client.post(
            `/classes/${item.id}/unenroll?page=${next}`,
            {},
          );
          next = result.next;
        } while (next);
      } else {
        await client.post(`/classes/${item.id}/activate`, {});

        let next = '';
        do {
          const result = await client.post(
            `/classes/${item.id}/enroll?page=${next}`,
            {},
          );
          next = result.next;
        } while (next);
      }
      await client.reload(url);
    };

    const renderHeader = () => {
      const resources = [
        {
          lottieFile:
            'https://assets9.lottiefiles.com/packages/lf20_e8zkfmos.json',
          title: 'Global Facilitator Community',
          onClick: openGlobalFacilitatorModal,
        },
        {
          lottieFile:
            'https://assets1.lottiefiles.com/packages/lf20_ssIwdK.json',
          title: 'FAQs',
          onClick: openFaqsModal,
        },
      ];

      return (
        <div className="mb-4">
          <div className={styles.titleWrapper}>
            <div className={styles.header}>
              <h2 className={styles.title}>
                Facilitator Resources & Community
              </h2>
            </div>
            <div>
              <button
                type="button"
                className="btn btn-light"
                onClick={() =>
                  window.open(
                    `${process.env.REACT_APP_QUETZAL_ENDPOINT}/courses`,
                    '_target',
                  )
                }
              >
                Back to courses
              </button>
            </div>
          </div>

          <Row>
            {resources.map((item, index) => (
              <Col sm={6} lg={4} key={index}>
                <Card
                  as="a"
                  onClick={item.onClick}
                  target="_blank"
                  className="h-100 border-radius"
                >
                  <Card.Body>
                    <Player
                      autoplay
                      keepLastFrame={true}
                      src={item.lottieFile}
                      style={{ height: '100px' }}
                    />
                    <Card.Title className={cs(styles.cardTitle, 'text-center')}>
                      {item.title}
                    </Card.Title>
                  </Card.Body>
                </Card>
              </Col>
            ))}

            <Col sm={6} lg={4}>
              <Card
                as="a"
                onClick={openModalContactSupport}
                className={cs(styles.cursorCard, 'h-100 border-radius')}
              >
                <Card.Body>
                  <Player
                    autoplay
                    keepLastFrame={true}
                    src={
                      'https://assets6.lottiefiles.com/packages/lf20_whfzz2v2.json'
                    }
                    style={{ height: '100px' }}
                  />
                  <Card.Title className={cs(styles.cardTitle, 'text-center')}>
                    Facilitator Resources
                  </Card.Title>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </div>
      );
    };

    const openModalAddClass = () => {
      modalState.show();
    };

    const { setTrackEvent } = useTrackingCode();
    const openModalContactSupport = () => {
      // modalContactState.show();
      window.open(
        'https://drive.google.com/drive/folders/1HrajJ7vQ2mdfjmr2QRxHQrkb4pbh7a2u',
        '_blank',
      );
      setTrackEvent({ eventId: 'Facilitator Resources', value: user.email });
    };
    const openFaqsModal = () => {
      modalFaqsState.show();
      setTrackEvent({ eventId: 'FAQs', value: user.email });
    };
    const openGlobalFacilitatorModal = () => {
      modalGfcState.show();
      setTrackEvent({ eventId: 'GlobalFacilitator', value: user.email });
    };
    const { setPathPageView, setIdentity } = useTrackingCode();
    React.useEffect(() => {
      if (user?.email) {
        setPathPageView('/classes');
        setIdentity(user.email);
      }
    }, [user, setPathPageView, setIdentity]);

    return (
      <PageContent
        headerFirst={true}
        header={renderHeader()}
        title="Classes"
        actions={<DateFilter onUpdateDates={updateDates} />}
        callback={() => {}}
      >
        <Button variant="primary" onClick={openModalAddClass}>
          <FontAwesomeIcon icon={faPlusCircle} className="mr-2" />
          Add class
        </Button>

        {modalContactState.visible && (
          <ContactSupport
            show
            onHide={modalContactState.hide}
            mailToContact={educatorResources.support}
          />
        )}

        {modalFaqsState.visible && (
          <FaqsModal show onHide={modalFaqsState.hide} content={faqData} />
        )}

        {modalState.visible && (
          <EditClassModal
            show
            onHide={modalState.hide}
            editClass={modalState.entity}
          />
        )}

        {modalGfcState.visible && (
          <GlobalFacilitatorCommunityModal show onHide={modalGfcState.hide} />
        )}

        <List
          className="mt-3"
          items={sortedClasses}
          loading={classes.loading}
          placeholder="No classes added yet"
          renderItem={(item) => (
            <ListItem
              key={item.id}
              title={item.name}
              left={
                <Badge
                  style={{ width: 50 }}
                  variant={
                    item.students && item.students.length ? 'primary' : 'danger'
                  }
                  className="text-white"
                >
                  {(item.students && item.students.length) || 0}
                  <FontAwesomeIcon
                    icon={
                      item.students && item.students.length
                        ? faUser
                        : faUserSlash
                    }
                    className="ml-1"
                  />
                </Badge>
              }
              right={
                <>
                  {/* since delivery was added later on we need to check that it exists */}
                  {item.delivery && (
                    <Badge variant="primary" className="ml-2 text-capitalize">
                      {item.delivery}
                    </Badge>
                  )}
                  {user.classes.includes(item.id!) && (
                    <Badge variant="primary" className="ml-2">
                      Member
                    </Badge>
                  )}
                  {item.startDate && item.endDate && (
                    <DateRangeBadge
                      startDate={item.startDate}
                      endDate={item.endDate}
                      className="ml-2"
                    />
                  )}
                </>
              }
              actions={
                user.role === UserRole.Admin ? (
                  <>
                    <Dropdown.Item onClick={() => modalState.show(item)}>
                      Edit
                    </Dropdown.Item>
                    <Dropdown.Item onClick={() => handleArchiveUnarchive(item)}>
                      {item.active ? 'Archive' : 'Unarchive'}
                    </Dropdown.Item>
                  </>
                ) : undefined
              }
              onClick={() => {
                history.push(classDetailsRoute.formatPath({ id: item.id! }));
              }}
            />
          )}
        />

        {/* <Toggle
          value={includeArchivedClasses.state}
          onChange={(value) => {
            includeArchivedClasses.setState(value);
            updateIncludeInactive(value);
          }}
          className="mt-2 mb-2"
        >
          Include archived classes
        </Toggle> */}
      </PageContent>
    );
  },
  { title: 'Classes' },
);
