import * as React from 'react';
import {Select} from '../Select';

interface OptionType {
    value: string;
    label: object;
}

export interface CityFieldProps {
    options?: OptionType[];
    value: any;
    onChange: (value: string) => void;
    placeholder?: string;
    isInvalid?: boolean;
    className?: string;
    name?: string;
}

export const ModalCityFieldSelect: React.FunctionComponent<CityFieldProps> = ({
                                                                                  options,
                                                                                  value,
                                                                                  onChange,
                                                                                  placeholder,
                                                                                  isInvalid,
                                                                                  className,
                                                                                  name,
                                                                              }) => {
    return (
        <Select
            name={name}
            options={options}
            value={value}
            onChange={(value: any) => onChange(value)}
            // onChange={(optionValue) => onChangeSelect(optionValue)}
            placeholder={placeholder}
            isInvalid={isInvalid}
            className={className}
        />
    );
};
