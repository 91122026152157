import * as React from 'react';
import { Formik, FormikConfig } from 'formik';
import * as yup from 'yup';
import { Col, Form } from 'react-bootstrap';
import Autocomplete from 'react-google-autocomplete';
import { Class } from '../../model/entities/Class';
import { GenderField } from './GenderField';
import { BusyButton } from './Busy';
import { Address, User } from '../../model/entities/User';
import { CountryField } from './CountryField';
import { ApiClient } from '../../util/ApiClient';
import { useCreateNotification } from '../hooks/useCreateNotification';
import _ from 'lodash';
import { KnownLanguagesField } from './KnownLanguagesField';
import {
  GOOGLE_MAPS_API_KEY,
  popupCenter,
} from '../routes/selfRegistrationRoute';
import { DisabilityField } from './Disability';
import { ReligionField } from './ReligionField';
import { RaceField } from './RaceField';

const schemaGenerator = (
  selfRegistration: NonNullable<Class['selfRegistration']>,
  email: boolean,
) =>
  yup.object().shape({
    name: yup.string().required(),
    ...(email ? { email: yup.string().email().required() } : {}),
    ...(selfRegistration.gender ? { gender: yup.string().required() } : {}),
    ...(selfRegistration.age ? { age: yup.number().required() } : {}),
    ...(selfRegistration.race ? { race: yup.string().required() } : {}),
    ...(selfRegistration.religion ? { religion: yup.string().required() } : {}),
    ...(selfRegistration.disability
      ? { disability: yup.string().required() }
      : {}),
    ...(selfRegistration.marginalizedIdentity
      ? { marginalizedIdentity: yup.string() }
      : {}),
    ...(selfRegistration.address ? { address: yup.object().required() } : {}),
    ...(selfRegistration.address ? { country: yup.string().required() } : {}),
    ...(selfRegistration.school ? { school: yup.string() } : {}),
    ...(selfRegistration.gradeLevel
      ? { gradeLevel: yup.string().required() }
      : {}),
    ...(selfRegistration.languages ? { languages: yup.array() } : {}),
    ...(selfRegistration.parentDetails
      ? { additionalEmail: yup.string().required() }
      : {}),
    ...(selfRegistration.terms
      ? { terms: yup.bool().oneOf([true], 'terms must be accepted') }
      : {}),
    ...(selfRegistration.termsPartner
      ? { termsPartner: yup.bool().oneOf([true], 'terms must be accepted') }
      : {}),
  });

interface Props {
  currentClass: Class;
  onRegistration: (user: User) => Promise<any>;
  setIsSubmitting?: (submitting: boolean) => void;
  processing?: boolean;
  email?: boolean;
}

export const RegistrationForm: React.FC<Props> = ({
  currentClass,
  onRegistration,
  setIsSubmitting,
  processing,
  email = true,
}) => {
  const client = new ApiClient('/api');
  const createNotification = useCreateNotification();

  const onSubmitHandler: FormikConfig<any>['onSubmit'] = async (
    values,
    { setSubmitting },
  ) => {
    try {
      setIsSubmitting && setIsSubmitting(true);
      const { country, ...formValues } = values;
      const data = {
        ...formValues,
        address: formValues.address
          ? {
              ...formValues.address,
              country: formValues.address.country
                ? formValues.address.country
                : country,
            }
          : formValues.address,
      };

      let response;
      if (email) {
        response = await client.post(
          `/classes/${currentClass.id}/students-registration`,
          data,
        );
      } else {
        response = await client.post(
          `/classes/${currentClass.id}/students`,
          data,
        );
      }

      console.log(data, currentClass);

      setSubmitting(false);
      setIsSubmitting && setIsSubmitting(false);
      onRegistration(response.data);
    } catch (error: any) {
      setSubmitting(false);
      setIsSubmitting && setIsSubmitting(false);
      console.error('error', error.message);
      createNotification('Server error', error.message, 'danger', {
        cause: _.get(error, 'meta.details.message'),
      });
    }
  };

  const selfRegistration = currentClass.selfRegistration;

  if (!selfRegistration || !selfRegistration.enabled) {
    return <p>The self-registration is not active.</p>;
  }

  const schema = schemaGenerator(selfRegistration, email);

  return (
    <Formik
      validationSchema={schema}
      onSubmit={onSubmitHandler}
      initialValues={{
        name: '',
        email: '',
        gender: undefined,
        age: undefined,
        race: undefined,
        religion: undefined,
        disability: undefined,
        marginalizedIdentity: undefined,
        address: undefined,
        country: undefined,
        school: undefined,
        languages: undefined,
        gradeLevel: undefined,
        additionalEmail: undefined,
        terms: false,
        termsPartner: false,
      }}
    >
      {({
        handleSubmit,
        handleChange,
        values,
        touched,
        errors,
        isSubmitting,
      }) => (
        <Form noValidate onSubmit={handleSubmit}>
          <Form.Row>
            <Form.Group as={Col} controlId="validationFormikName">
              <Form.Label>Name</Form.Label>
              <Form.Control
                type="text"
                name="name"
                onChange={handleChange}
                isInvalid={touched.name && !!errors.name}
              />
              <Form.Control.Feedback type="invalid">
                {errors.name}
              </Form.Control.Feedback>
            </Form.Group>
          </Form.Row>
          {email && (
            <Form.Row>
              <Form.Group as={Col} controlId="validationFormikEmail">
                <Form.Label>Email</Form.Label>
                <Form.Control
                  type="email"
                  aria-describedby="inputGroupPrepend"
                  name="email"
                  onChange={handleChange}
                  isInvalid={touched.email && !!errors.email}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.email}
                </Form.Control.Feedback>
              </Form.Group>
            </Form.Row>
          )}
          {selfRegistration.gender && (
            <Form.Row>
              <Form.Group as={Col} controlId="validationFormikGender">
                <Form.Label>Gender</Form.Label>
                <div
                  className={
                    touched.gender && !!errors.gender
                      ? 'border border-danger is-invalid'
                      : ''
                  }
                >
                  <GenderField
                    name="gender"
                    value={values.gender}
                    onChange={handleChange}
                  />
                </div>
                <Form.Control.Feedback type="invalid">
                  {errors.gender}
                </Form.Control.Feedback>
              </Form.Group>
            </Form.Row>
          )}
          {selfRegistration.age && (
            <Form.Row>
              <Form.Group as={Col} controlId="validationFormikAge">
                <Form.Label>Age</Form.Label>
                <Form.Control
                  type="number"
                  name="age"
                  onChange={handleChange}
                  isInvalid={touched.age && !!errors.age}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.age}
                </Form.Control.Feedback>
              </Form.Group>
            </Form.Row>
          )}
          {selfRegistration.race && (
            <Form.Row>
              <Form.Group as={Col} controlId="validationFormikRace">
                <Form.Label>Race</Form.Label>
                <RaceField
                  name="race"
                  onChange={(value) =>
                    handleChange({
                      target: { name: 'race', value },
                    })
                  }
                  isInvalid={touched.race && !!errors.race}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.race}
                </Form.Control.Feedback>
              </Form.Group>
            </Form.Row>
          )}
          {selfRegistration.religion && (
            <Form.Row>
              <Form.Group as={Col} controlId="validationFormikReligion">
                <Form.Label>Religion</Form.Label>
                <ReligionField
                  name="religion"
                  onChange={(value) =>
                    handleChange({
                      target: { name: 'religion', value },
                    })
                  }
                  isInvalid={touched.religion && !!errors.religion}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.religion}
                </Form.Control.Feedback>
              </Form.Group>
            </Form.Row>
          )}
          {selfRegistration.disability && (
            <Form.Row>
              <Form.Group as={Col} controlId="validationFormikDisability">
                <Form.Label>Disability</Form.Label>
                <DisabilityField
                  name="disability"
                  onChange={(value) =>
                    handleChange({
                      target: { name: 'disability', value },
                    })
                  }
                  isInvalid={touched.disability && !!errors.disability}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.disability}
                </Form.Control.Feedback>
              </Form.Group>
            </Form.Row>
          )}
          {selfRegistration.marginalizedIdentity && (
            <Form.Row>
              <Form.Group
                as={Col}
                controlId="validationFormikMarginalizedIdentity"
              >
                <Form.Label>Marginalized identity (optional)</Form.Label>
                <Form.Control
                  type="text"
                  name="marginalizedIdentity"
                  onChange={handleChange}
                />
              </Form.Group>
            </Form.Row>
          )}
          {selfRegistration.school && (
            <Form.Row>
              <Form.Group as={Col} controlId="validationFormikSchool">
                <Form.Label>School (optional)</Form.Label>
                <Form.Control name="school" onChange={handleChange} />
              </Form.Group>
            </Form.Row>
          )}
          {selfRegistration.gradeLevel && (
            <Form.Row>
              <Form.Group as={Col} controlId="validationFormikGender">
                <Form.Label>Grade level</Form.Label>
                <Form.Control
                  name="gradeLevel"
                  onChange={handleChange}
                  isInvalid={touched.gradeLevel && !!errors.gradeLevel}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.gradeLevel}
                </Form.Control.Feedback>
              </Form.Group>
            </Form.Row>
          )}
          {selfRegistration.address && (
            <Form.Row>
              <Form.Group as={Col} controlId="validationFormikAddress">
                <Form.Label>City</Form.Label>
                <Autocomplete
                  className={
                    'form-control' +
                    (touched.address && !!errors.address ? ' is-invalid' : '')
                  }
                  apiKey={GOOGLE_MAPS_API_KEY}
                  onPlaceSelected={(place: any) => {
                    console.log(place);
                    if (!place.address_components) return;
                    const cityItem = place.address_components.find(
                      (item: any) =>
                        item.types.find((type: string) => type === 'locality'),
                    );
                    const stateItem = place.address_components.find(
                      (item: any) =>
                        item.types.find(
                          (type: string) =>
                            type === 'administrative_area_level_1',
                        ),
                    );
                    const countryItem = place.address_components.find(
                      (item: any) =>
                        item.types.find((type: string) => type === 'country'),
                    );
                    const address: Address = {
                      city: (cityItem && cityItem.short_name) || '',
                      state: (stateItem && stateItem.short_name) || '',
                      country: (countryItem && countryItem.short_name) || '',
                    };
                    // update country
                    const eventCountry = {
                      target: { name: 'country', value: address.country },
                    };
                    handleChange(eventCountry);
                    // update address
                    const eventAddress = {
                      target: { name: 'address', value: address },
                    };
                    handleChange(eventAddress);
                  }}
                  name="address"
                  initialvalue={values.address}
                  onChange={(event: any) => {
                    if (event.target.value === '') {
                      handleChange(event);
                    }
                  }}
                  placeholder=""
                />
                <Form.Control.Feedback type="invalid">
                  {errors.address}
                </Form.Control.Feedback>
              </Form.Group>
            </Form.Row>
          )}
          {selfRegistration.address && (
            <Form.Row>
              <Form.Group as={Col} controlId="validationFormikCountry">
                <Form.Label>Country</Form.Label>
                <CountryField
                  value={values.country || ''}
                  onChange={(value) => {
                    const event = { target: { name: 'country', value } };
                    handleChange(event);
                  }}
                  isInvalid={touched.country && !!errors.country}
                  placeholder=""
                  className="text-dark"
                />
                <Form.Control.Feedback
                  type="invalid"
                  className={
                    touched.country && !!errors.country ? ' d-block' : ''
                  }
                >
                  {errors.country}
                </Form.Control.Feedback>
              </Form.Group>
            </Form.Row>
          )}
          {selfRegistration.languages && (
            <Form.Row>
              <Form.Group as={Col} controlId="validationFormiklanguages">
                <Form.Label>Languages (optional)</Form.Label>
                <KnownLanguagesField
                  value={values.languages || []}
                  onChange={(value) => {
                    const event = { target: { name: 'languages', value } };
                    handleChange(event);
                  }}
                />
              </Form.Group>
            </Form.Row>
          )}
          {selfRegistration.parentDetails && (
            <Form.Row>
              <Form.Group as={Col} controlId="validationFormikAdditionalEmail">
                <Form.Label>Additional email</Form.Label>
                <Form.Control
                  name="additionalEmail"
                  onChange={handleChange}
                  isInvalid={
                    touched.additionalEmail && !!errors.additionalEmail
                  }
                />
                <Form.Control.Feedback type="invalid">
                  {errors.additionalEmail}
                </Form.Control.Feedback>
              </Form.Group>
            </Form.Row>
          )}
          {selfRegistration.terms && (
            <Form.Row>
              <Form.Group>
                <Form.Check id="validationFormikTerms">
                  <Form.Check.Input
                    required
                    name="terms"
                    type="checkbox"
                    isInvalid={touched.terms && !!errors.terms}
                    onChange={handleChange}
                  />
                  <Form.Check.Label>
                    I certify that I am at least 13 years of age and have read
                    and agree to{' '}
                    <a
                      href={currentClass.selfRegistration!.privacyUrl}
                      onClick={(e) => {
                        e.preventDefault();
                        popupCenter({
                          url: currentClass.selfRegistration!.privacyUrl!,
                          title: "GNG's Privacy Policy",
                          w: 900,
                          h: 500,
                        });
                        return false;
                      }}
                    >
                      GNG's Privacy Policy
                    </a>{' '}
                    and the{' '}
                    <a
                      href={currentClass.selfRegistration!.termsUrl}
                      onClick={(e) => {
                        e.preventDefault();
                        popupCenter({
                          url: currentClass.selfRegistration!.termsUrl!,
                          title: 'Student to World Terms and Conditions',
                          w: 900,
                          h: 500,
                        });
                        return false;
                      }}
                    >
                      Student to World Terms and Conditions
                    </a>
                    .
                  </Form.Check.Label>
                  <Form.Control.Feedback type="invalid">
                    {errors.terms}
                  </Form.Control.Feedback>
                </Form.Check>
              </Form.Group>
            </Form.Row>
          )}
          {selfRegistration.termsPartner && (
            <Form.Row>
              <Form.Group>
                <Form.Check id="validationFormikTermsPartner">
                  <Form.Check.Input
                    required
                    name="termsPartner"
                    type="checkbox"
                    isInvalid={touched.termsPartner && !!errors.termsPartner}
                    onChange={handleChange}
                  />
                  <Form.Check.Label>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: selfRegistration!.termsPartner,
                      }}
                    />
                  </Form.Check.Label>
                  <Form.Control.Feedback type="invalid">
                    {errors.termsPartner}
                  </Form.Control.Feedback>
                </Form.Check>
              </Form.Group>
            </Form.Row>
          )}
          <BusyButton type="submit" busy={isSubmitting || processing}>
            Submit
          </BusyButton>
        </Form>
      )}
    </Formik>
  );
};
