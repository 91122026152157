import * as React from 'react';
import { Column } from 'react-table';
import { Route } from '../Route';
import { PageContent } from '../components/PageContent';
import { useApiQuery } from '../hooks/useApiQuery';
// import { Class, Course, ModuleT } from '../../model/entities/Class';
import { CenteredBusySpinner } from '../components/Busy';
import { RedstoneTable, Data } from '../components/Table';
import { StoryModal } from '../modals/StoryModal';
import { useStoryModalState } from '../hooks/useStoryModalState';
import { ModalState } from '../../model/entities/Class';
import SkeletonItem from '../components/SkeletonItem';
import Approved from '../../frontend/images/check.png';
import InWriting from '../../frontend/images/in-writing.png';
import InReview from '../../frontend/images/in-progress.png';
import Rejected from '../../frontend/images/rejected.png';
import { StatusIcon } from '../components/StatusIcon';

const styles = require('../styles/UserStoriesRoute.module.scss');

export const userStoriesRoute = new Route<{ id: string }>(
  '/userStories/:id',
  ({ match, history }) => {
    const userId = match.params.id.split(':')[0];
    const courseId = match.params.id.split(':')[1];
    const [selectedStory, setSelectedStory] = React.useState<string>('');

    const storyModalState = useStoryModalState<ModalState>();

    const userStories = useApiQuery<any>(
      `/users/${userId}/courses/${courseId}/stories`,
    );

    const statusMapper = (): any => {
      return {
        approved: <StatusIcon img={Approved} label="Complete" />,
        in_writing: <StatusIcon img={InWriting} label="In progress" />,
        in_review: <StatusIcon img={InReview} label="In review" />,
        rejected: <StatusIcon img={Rejected} label="Rejected" />,
      };
    };

    const data = userStories.data?.map((story: any) => ({
      id: story.id,
      studentName: story.fullName,
      story: <span>{story.title}</span>,
      storyStatus: statusMapper()[story.storyStatus],
    }));

    const columns = React.useMemo<Column<Partial<Data>>[]>(
      () => [
        { Header: 'Student name', accessor: 'studentName' },
        { Header: 'Story', accessor: 'story' },
        { Header: 'Status', accessor: 'storyStatus' },
        { Header: '', accessor: 'actions' },
      ],
      [],
    );

    const renderModal = (id: string) => {
      setSelectedStory(id);
      storyModalState.show();
    };

    return (
      <PageContent
        title={
          userStories.data ? (
            `${
              userStories.data.length > 0 && userStories.data[0].fullName
            } stories `
          ) : (
            <SkeletonItem count={1} width={100} />
          )
        }
        breadcrumbs={[{ title: 'User Stories', url: '/' }]}
        actions={<></>}
        typeA={false}
        callback={() => {}}
      >
        {userStories.loading || !userStories.data ? (
          <CenteredBusySpinner />
        ) : (
          <section>
            <div>
              <p>Student statistics</p>
            </div>
            <div className={`${styles.tblWrapper} row`}>
              <RedstoneTable
                columns={columns}
                data={data || []}
                onClick={(id: string) => renderModal(id)}
              />
            </div>
          </section>
        )}

        {storyModalState.visible && (
          <StoryModal
            statusFromUserStories={
              userStories.data?.find((story: any) => story.id === selectedStory)
                ?.storyStatus
            }
            show
            onHide={storyModalState.hide}
            content={data?.find((story: any) => story.id === selectedStory)}
          />
        )}
      </PageContent>
    );
  },
);
