import * as React from 'react';
import { Modal, Button, FormControl } from 'react-bootstrap';
import { BusyButton, BusySpinner } from '../components/Busy';
import { useAsyncCallback } from '../hooks/useAsyncCallback';
import { ModalProps } from './ModalProps';
import { User, UserRole } from '../../model/entities/User';
import { RadioGroup } from '../components/RadioGroupField';
import { ClassesField } from '../components/ClassesField';
import { useApiClient } from '../hooks/useApiClient';
import { useUser } from '../hooks/useUser';
import { useInputField } from '../hooks/useInputField';
import { required } from '../validators/required';
import { emailFormat } from '../validators/emailFormat';
import { requiredIf } from '../validators/requiredIf';
import { useField } from '../hooks/useField';
import { FormGroup } from '../components/FormGroup';
import { validateAll } from '../util/validateAll';
import { useApiClientContext } from '../hooks/useApiClientContext';

export interface EditUserModalProps extends ModalProps {
  user?: User;
}

export const EditUserModal: React.FunctionComponent<EditUserModalProps> = ({
  show,
  onHide,
  user,
}) => {
  const client = useApiClient();
  const currentUser = useUser();
  const { setAuth } = useApiClientContext();

  const [matchedUser, setMatchedUser] = React.useState<any>();

  const email = useInputField(user ? user.email : '', [required, emailFormat]);

  const name = useInputField(user ? user.name : '', [
    requiredIf(() => !matchedUser),
  ]);

  const role = useField(user ? user.role : UserRole.Educator, [required]);
  const trainingCourses = useField(
    user ? user.courses.filter((course) => !course.sectionId) : [],
    [],
  );
  const classes = useField(user ? user.classes : [], []);

  const matchUser = useAsyncCallback(
    async (email: string) => {
      const { data } = await client.get(
        `/users/match/${encodeURIComponent(email)}`,
      );

      setMatchedUser(data);
    },
    [client],
  );

  const save = useAsyncCallback(
    async (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();

      if (!validateAll([email, name, role, trainingCourses, classes])) return;

      const values = {
        email: email.value,
        username: email.value,
        name: name.value,
        role: role.value,
        trainingCourses: trainingCourses.value,
        classes: classes.value,
      };

      if (user) {
        await client.update('/users', user.id!, values);

        if (user.id === currentUser.id) {
          const updated = { ...currentUser, ...values };
          setAuth({ user: updated });
        }
      } else if (matchedUser && !matchedUser.inRedstone) {
        await client.post(`/users/importfromcanvas/${matchedUser.id}`, {
          role: role.value,
          classes: classes.value,
          trainingCourses: trainingCourses.value,
        });
        await client.reload('/users');
      } else {
        await client.create('/users', values);
      }

      onHide();
    },
    [user, matchedUser, email, name, role, trainingCourses, classes],
  );

  return (
    <Modal backdrop show={show} onHide={onHide}>
      <form onSubmit={save.call}>
        <Modal.Header closeButton>
          <Modal.Title>{user ? 'Edit' : 'Create'} user</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <FormGroup label="Email" error={email.error}>
            <FormControl
              placeholder="User's email address"
              value={email.value}
              onChange={email.onChange}
              onBlur={(e: any) => {
                if (email.validate()) {
                  matchUser.call(e.target.value);
                }
              }}
              isInvalid={!!name.error}
              autoFocus
            />
          </FormGroup>

          {matchUser.busy && <BusySpinner />}

          {matchedUser ? (
            <FormGroup label="Name">
              <FormControl value={matchedUser.name} disabled />
            </FormGroup>
          ) : (
            <FormGroup label="Name">
              <FormControl
                placeholder="User's name"
                value={name.value}
                onChange={name.onChange}
                onBlur={name.validate}
                isInvalid={!!name.error}
              />
            </FormGroup>
          )}

          {matchedUser && matchedUser.inRedstone ? (
            <p>This user already exists.</p>
          ) : (
            <>
              <FormGroup label="Classes" error={classes.error} optional>
                <ClassesField
                  value={classes.value}
                  onChange={classes.setValue}
                />
              </FormGroup>

              <FormGroup label="Role" error={role.error}>
                <RadioGroup
                  options={[
                    { value: UserRole.Admin, label: 'Admin' },
                    { value: UserRole.Educator, label: 'Educator' },
                    { value: '', label: 'Not set' },
                  ]}
                  value={role.value}
                  onChange={(event) => role.setValue(event.target.value)}
                />
              </FormGroup>

              {/* {role.value === UserRole.Educator && (
                <FormGroup
                  label="Training courses"
                  error={trainingCourses.error}
                  help="Optionally enroll the educator in a training course"
                  optional
                >
                  <CoursesField
                    value={trainingCourses.value}
                    onChange={trainingCourses.setValue}
                  />
                </FormGroup>
              )} */}
            </>
          )}
        </Modal.Body>

        <Modal.Footer>
          <Button
            variant="outline-secondary"
            onClick={onHide}
            disabled={save.busy}
          >
            Cancel
          </Button>
          <BusyButton
            variant="primary"
            type="submit"
            busy={save.busy}
            disabled={matchUser.busy || (matchedUser && matchedUser.inRedstone)}
          >
            Save
          </BusyButton>
        </Modal.Footer>
      </form>
    </Modal>
  );
};
