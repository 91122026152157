import { Route, RouteParams } from '../Route';
import * as history from 'history';

export function createHref(
  _history: history.History,
  location: history.Location,
  to: string,
): string;
export function createHref<T extends RouteParams>(
  history: history.History,
  location: history.Location,
  route: Route<T>,
  params: T,
): string;
export function createHref(
  _history: history.History,
  location: history.Location,
  to: any,
  params?: any,
): string {
  if (to instanceof Route) {
    return createHref(_history, location, to.formatPath(params));
  }

  const linkLocation = history.createLocation(
    to,
    undefined,
    undefined,
    location,
  );

  const link = _history.createHref(linkLocation);
  return makeAbsoluteUrl(link);
}

export function makeAbsoluteUrl(url: string) {
  const a = document.createElement('a');
  a.href = url;
  return a.href;
}
