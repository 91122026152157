import * as React from 'react';
import { AuthTokens } from '../../model/api/AuthResponse';
import { useLocalState } from '../hooks/useLocalState';

export interface AuthTokensProviderProps {
  name?: string;
}

export interface AuthTokensContextInterface {
  tokens: AuthTokens | null;
  setTokens(tokens: AuthTokens | null): void;
}

export const AuthTokensContext = React.createContext<AuthTokensContextInterface | null>(
  null,
);

export const AuthTokensProvider: React.FunctionComponent<
  AuthTokensProviderProps
> = ({ children, name = 'tokens' }) => {
  const [tokens, setTokens] = useLocalState<AuthTokens | null>(name, null);

  return (
    <AuthTokensContext.Provider value={{ tokens, setTokens }}>
      {children}
    </AuthTokensContext.Provider>
  );
};
