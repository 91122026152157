import * as React from 'react';
import { useState, useEffect } from 'react';
import { Modal, Button, FormControl, Form, Col } from 'react-bootstrap';
import { merge } from 'lodash';
import * as moment from 'moment';
import { Player } from '@lottiefiles/react-lottie-player';
import { BusyButton } from '../components/Busy';
import { useAsyncCallback } from '../hooks/useAsyncCallback';
import { ModalProps } from './ModalProps';
import { useApiClient } from '../hooks/useApiClient';
import {
  Class,
  ClassCourse,
  ClassDelivery,
  ClassEnvironment,
} from '../../model/entities/Class';
import { DatePicker } from '../components/DatePicker';
import { useInputField, useInputNumberField } from '../hooks/useInputField';
import { useField } from '../hooks/useField';
import { required } from '../validators/required';
import { FormGroup } from '../components/FormGroup';
import { validateAll } from '../util/validateAll';
import { Select } from '../components/Select';
import { classUsersTabRoute } from '../sections/ClassUsersTab';
import { useApiQuery } from '../hooks/useApiQuery';
import { useUser } from '../hooks/useUser';
import { UserRole } from '../../model/entities/User';
import { useTrackingCode } from 'react-hubspot-tracking-code-hook';
export interface EditClassModalProps extends ModalProps {
  editClass?: Class;
}

interface DeliveryOption {
  value: ClassDelivery;
  label: string;
}

interface EnvironmentOption {
  value: ClassEnvironment;
  label: string;
}

export const EditClassModal: React.FunctionComponent<EditClassModalProps> = ({
  show,
  onHide,
  editClass,
}) => {
  const client = useApiClient();
  const user = useUser();

  const [coursesQ, setCoursesQ] = useState([
    { id: 1, uuid: 'some-uuid', name: 'null' },
  ]);
  const matchUser = useAsyncCallback(async () => {
    const { data } = await client.get('/courses');
    const formattedData = data.courses.map((course: ClassCourse) => {
      return {
        id: course.id,
        uuid: course.id,
        name: course.title,
      };
    });

    setCoursesQ(formattedData);
  }, [client]);
  useEffect(() => {
    matchUser.call();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const name = useInputField(editClass ? editClass.name : '', [required]);
  const classDeliveryOptions: DeliveryOption[] = [
    { value: ClassDelivery.OnlineCourse, label: 'Online course' },
    { value: ClassDelivery.Video, label: 'Video' },
    { value: ClassDelivery.LiveEvent, label: 'Live event' },
  ];

  const classEnvironmentOptions: EnvironmentOption[] = [
    { value: ClassEnvironment.CoreSubject, label: 'Core Subject' },
    { value: ClassEnvironment.ElectiveClass, label: 'Elective Class' },
    { value: ClassEnvironment.AdvisoryPeriod, label: 'Advisory Period' },
    { value: ClassEnvironment.AfterSchoolClub, label: 'After School Club' },
    {
      value: ClassEnvironment.NonSchoolRelatedClub,
      label: 'Non-School Related Club',
    },
  ];

  const deliveryOptions =
    editClass &&
    editClass.delivery &&
    classDeliveryOptions.filter((deliveryOption) =>
      editClass.delivery.find(
        (classDeliveryValue) => classDeliveryValue === deliveryOption.value,
      ),
    );

  const delivery = useField<DeliveryOption[] | undefined>(deliveryOptions, []);

  const environmentOption = classEnvironmentOptions.find(
    (environmentOption) => editClass?.environment === environmentOption.value,
  );

  const environment = useField(environmentOption, []);

  const startDate = useField<Date | undefined>(
    editClass && editClass.startDate
      ? moment.utc(editClass.startDate).toDate()
      : undefined,
    [required],
  );

  const endDate = useField<Date | undefined>(
    editClass && editClass.endDate
      ? moment.utc(editClass.endDate).toDate()
      : undefined,
    [required],
  );

  const sessionsPerWeek = useInputNumberField(
    editClass && editClass.sessionsPerWeek ? editClass.sessionsPerWeek : '',
    [],
  );
  const coursesList = useApiQuery<ClassCourse[]>(`/courses`, []);
  const courses = useField<ClassCourse[]>(
    editClass && editClass.courses ? editClass.courses : [],
    [],
  );
  const filteredCourses = coursesQ;

  const save = useAsyncCallback(
    async (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();

      if (
        !validateAll([
          name,
          startDate,
          endDate,
          delivery,
          environment,
          sessionsPerWeek,
          // courses,
        ])
      )
        return;

      const values = {
        name: name.value,
        startDate: startDate.value,
        endDate: endDate.value,
        delivery: delivery.value?.map((item) => item.value),
        environment: environment.value && environment.value.value,
        sessionsPerWeek: sessionsPerWeek.value || undefined,
        courses: courses.value || undefined,
      };

      const coursesTransformed = courses.value.map((course) => ({
        uuid: course.id,
        name: course.name,
      }));

      const payload = merge(values, { courses: coursesTransformed });

      if (editClass) {
        await client.update('/classes', editClass.id!, payload);
      } else {
        const createdClass = await client.create('/classes', payload);
        await client.update('/classes', createdClass.data.id, payload);
      }

      onHide();
      if (editClass) {
        classUsersTabRoute.formatPath({ id: editClass.id! });
      }
    },
    [
      editClass,
      name,
      startDate,
      endDate,
      delivery,
      environment,
      sessionsPerWeek,
      courses,
    ],
  );
  const { setTrackEvent } = useTrackingCode();
  const addNewClass = () => {
    setTrackEvent({ eventId: 'Created new class', value: user.email });
  };
  return (
    <Modal
      backdrop
      show={show}
      onHide={onHide}
      size="lg"
      contentClassName="p-4 border-radius"
    >
      <form onSubmit={save.call}>
        <Modal.Body>
          <Form.Row>
            <Col xs={7}>
              <h5>How can we help you?</h5>
              <p>
                Our team is here to support as you implement these courses. By
                sharing your class information below, we will be able to provide
                support at the appropriate times. You can always update
                information later on should plans change.
              </p>
              <p>
                Please share the information below based on your current
                implementation plans.
              </p>
            </Col>
            <Col xs={5}>
              <Player
                autoplay
                keepLastFrame={true}
                src="https://assets5.lottiefiles.com/private_files/lf30_pejowq8e.json"
                className="border-radius"
              />
            </Col>
          </Form.Row>
          <h6 className="my-4 text-center">
            How would you like to engage with the program?
          </h6>
          <Form.Row>
            <FormGroup as={Col} label="Class Name" error={name.error}>
              <FormControl
                autoFocus
                value={name.value}
                onChange={name.onChange}
                onBlur={name.validate}
              />
            </FormGroup>
            <FormGroup as={Col} label="Type of Class" error={environment.error}>
              <Select
                options={classEnvironmentOptions}
                getOptionValue={(x) => x.value}
                getOptionLabel={(x) => x.label}
                onChange={(value) => {
                  environment.setValue(value as EnvironmentOption);
                }}
                value={environment.value}
                onBlur={environment.validate}
              />
            </FormGroup>
          </Form.Row>
          <Form.Row>
            <FormGroup
              as={Col}
              label="Estimated Start Date"
              error={startDate.error}
            >
              <DatePicker
                value={startDate.value}
                onChange={startDate.setValue}
                onBlur={startDate.validate}
                selectsStart
                startDate={startDate.value}
                endDate={endDate.value}
              />
            </FormGroup>
            <FormGroup
              as={Col}
              label="Estimated End Date"
              error={endDate.error}
            >
              <DatePicker
                value={endDate.value}
                onChange={endDate.setValue}
                onBlur={endDate.validate}
                selectsEnd
                startDate={startDate.value}
                endDate={endDate.value}
                minDate={startDate.value}
              />
            </FormGroup>
          </Form.Row>
          <Form.Row>
            <FormGroup
              as={Col}
              label="Estimated number of Participants?"
              error={sessionsPerWeek.error}
            >
              <FormControl
                type="number"
                autoFocus
                value={sessionsPerWeek.value}
                onChange={sessionsPerWeek.onChange}
                onBlur={sessionsPerWeek.validate}
              />
            </FormGroup>
          </Form.Row>
          {user.role === UserRole.Admin && (
            <FormGroup label="Delivery Tag (for " error={delivery.error}>
              <Select
                isMulti
                options={classDeliveryOptions}
                getOptionValue={(x) => x.value}
                getOptionLabel={(x) => x.label}
                onChange={(value) => {
                  delivery.setValue(value as DeliveryOption[]);
                }}
                value={delivery.value}
                onBlur={delivery.validate}
              />
            </FormGroup>
          )}
          <FormGroup label="Courses">
            <Select
              isMulti
              options={filteredCourses}
              getOptionValue={(x) => x.uuid}
              getOptionLabel={(x) => x.name}
              onChange={(value) => {
                courses.setValue(value as ClassCourse[]);
              }}
              placeholder="Select Online Courses"
              value={courses.value}
              isInvalid={!!courses.error}
              isLoading={coursesList.loading}
            />
          </FormGroup>
          <div className="mt-4 text-center">
            <BusyButton
              onClick={addNewClass}
              variant="primary"
              type="submit"
              busy={save.busy}
            >
              Save {editClass && ' and add students'}
            </BusyButton>
          </div>
          <div className="mt-4 text-center">
            <Button variant="link" onClick={onHide} disabled={save.busy}>
              I'm not sure, yet
            </Button>
          </div>
        </Modal.Body>
      </form>
    </Modal>
  );
};
