import { RouteComponentProps } from 'react-router';
import * as _ from 'lodash';

export type RouteParams = Record<string, string | undefined>;

export interface RouteOptions {
  adminOnly?: boolean;
  enabled?: boolean;
  exact?: boolean;
  title?: string;
}

export class Route<TParams extends RouteParams = {}> {
  constructor(
    public path: string,
    public view: React.ComponentType<RouteComponentProps<TParams>>,
    public options: RouteOptions = {},
  ) {
    this.options = _.defaults(options, {
      adminOnly: false,
      enabled: true,
      exact: true,
    });
  }

  formatPath(params: TParams) {
    return this.path.replace(
      /:([^/]+)($|\/)/g,
      (str, key, end) => params[key] + end,
    );
  }
}
