import * as React from 'react';
import { Button, Modal, InputGroup, FormControl } from 'react-bootstrap';
// import OtpInput from 'react-otp-input';
import { Player } from '@lottiefiles/react-lottie-player';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy } from '@fortawesome/free-regular-svg-icons';
import copy from 'copy-text-to-clipboard';
import { auth } from 'firebase';
import { ModalProps } from './ModalProps';
import { Class } from '../../model/entities/Class';
import { useCreateNotification } from '../hooks/useCreateNotification';
import { CenteredBusySpinner } from '../components/Busy';
const styles = require('../styles/InviteParticipantsModal.module.scss');

export interface InviteParticipantssModalProps extends ModalProps {
  currentClass: Class;
}

export const InviteParticipantssModal: React.FunctionComponent<
  InviteParticipantssModalProps
> = ({ show, onHide, currentClass }) => {
  const createNotification = useCreateNotification();
  const [loading, setLoading] = React.useState(true);
  const [emailVerified, setEmailVerified] = React.useState(false);
  const [token, setToken] = React.useState<string>();

  React.useEffect(() => {
    return auth().onAuthStateChanged((user) => {
      if (
        !currentClass.selfRegistration ||
        !currentClass.selfRegistration.token
      ) {
        setLoading(false);
        return;
      }
      setToken(currentClass.selfRegistration.token);
      setEmailVerified((user && user.emailVerified) || false);
      setLoading(false);
    });
  }, [currentClass.selfRegistration]);

  const sendConfirmationEmail = async () => {
    auth().currentUser && (await auth().currentUser!.sendEmailVerification());
    createNotification(
      'Email verification',
      `An email has been sent to ${
        auth().currentUser!.email
      }, please click the link in the email to verify it, and then come back to continue.`,
      'info',
      { hideAfter: 8000 },
    );
  };

  const userReload = async () => {
    if (!auth().currentUser) {
      return;
    }
    await auth().currentUser!.reload();
    setEmailVerified(auth().currentUser!.emailVerified);
  };

  const copyCode = () => {
    copy(token!.substring(0, 6));
    createNotification(
      'Class code',
      'The class code has been copied to the clipboard.',
      'info',
      { hideAfter: 3000 },
    );
  };

  return (
    <Modal
      backdrop
      show={show}
      onHide={onHide}
      size="lg"
      contentClassName="p-4 border-radius"
    >
      <Modal.Body>
        <h2 className="my-4 text-center">Invite Participants</h2>
        {loading ? (
          <CenteredBusySpinner />
        ) : !token ? (
          <div className="text-center">
            <p>There is not a token setup.</p>
          </div>
        ) : (
          <>
            <div className="text-center">
              {!auth().currentUser || emailVerified ? (
                <>
                  <p>
                    <a
                      href={`${process.env.REACT_APP_QUETZAL_ENDPOINT}/join-the-class?token=${token}`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      Share
                    </a>{' '}
                    the code below with the program participants.
                  </p>
                  <p>
                    They can enter after they click <i>Join the class</i> button
                    on{' '}
                    <a
                      href={process.env.REACT_APP_QUETZAL_ENDPOINT}
                      target="_blank"
                      rel="noreferrer"
                    >
                      courses.gng.org
                    </a>{' '}
                    and will be directly added to your class.
                  </p>
                  <p>Make sure the 0’s are zero’s and the o’s are o’s 😃</p>
                </>
              ) : (
                <>
                  <p>
                    To add Students to your Class, we need to verify your email.
                  </p>
                  <p>
                    <Button
                      variant="link p-0 align-baseline"
                      onClick={sendConfirmationEmail}
                    >
                      Click here
                    </Button>{' '}
                    to send the confirmation email.
                  </p>
                  <p>
                    After you have succesfully verified your email,{' '}
                    <Button
                      variant="link p-0 align-baseline"
                      onClick={userReload}
                    >
                      click here
                    </Button>{' '}
                    to check.
                  </p>
                </>
              )}
            </div>
            <div className={styles.inputContainer}>
              {/* <OtpInput
                value={
                  !auth().currentUser || auth().currentUser!.emailVerified
                    ? token
                    : '------'
                }
                numInputs={6}
                inputStyle={{
                  width: '52px',
                  height: '56px',
                  margin: '0 0.3rem',
                  borderRadius: '17px',
                  border: '2px solid #E0E0E0',
                  fontSize: '30px',
                  fontFamily: "'Fira Mono', monospace",
                }}
                containerStyle={{
                  justifyContent: 'center',
                  margin: '40px auto',
                }}
              /> */}
              <InputGroup className="mb-3">
                <FormControl
                  value={
                    !auth().currentUser || auth().currentUser!.emailVerified
                      ? token
                      : '------'
                  }
                  aria-label="Token"
                  aria-describedby="basic-addon2"
                  className={styles.customInputGroup}
                  disabled
                />
                <InputGroup.Append>
                  <InputGroup.Text
                    id="basic-addon2"
                    className={styles.inputGroupAppend}
                    onClick={copyCode}
                  >
                    <FontAwesomeIcon icon={faCopy} />{' '}
                    {/* <span className={styles.marginLeft}></span> */}
                  </InputGroup.Text>
                </InputGroup.Append>
              </InputGroup>
            </div>
            <div className={styles.playerContainer}>
              <Player
                autoplay
                keepLastFrame={true}
                src="https://assets1.lottiefiles.com/packages/lf20_Sog6Ae.json"
                className="border-radius"
                style={{
                  width: '300px',
                }}
              />
            </div>
          </>
        )}
      </Modal.Body>
    </Modal>
  );
};
