import * as React from 'react';
import cs from 'classnames';
import * as _ from 'lodash';

export const errorContext = React.createContext<any>(null);

export const ErrorMessage: React.FunctionComponent<{
  path: string;
  className: string;
}> = ({ path, className }) => {
  const error = React.useContext(errorContext);
  const message = _.get(error, path);

  return typeof message === 'string' ? (
    <div className={cs('field-error', className)}>{message}</div>
  ) : null;
};

export const ErrorContext: React.FunctionComponent<{ path: string }> = ({
  path,
  children,
}) => {
  const error = React.useContext(errorContext);

  return (
    <errorContext.Provider value={_.get(error, path)}>
      {children}
    </errorContext.Provider>
  );
};
