import { useState } from 'react';

export interface ValidatorFn<T> {
  (value: T): string | false | undefined | null;
}

export function useValidation<T = string>(
  validators: (ValidatorFn<T>)[],
): [string, (value: string) => void, (value: T) => boolean] {
  const [error, setError] = useState('');

  const validate = (value: T) => {
    for (const validator of validators) {
      const error = validator(value);

      if (error) {
        setError(error);
        return false;
      }
    }

    setError('');
    return true;
  };

  return [error, setError, validate];
}
