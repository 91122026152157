import { useState, useCallback } from 'react';
import { ValidatorFn, useValidation } from './useValidation';

export interface Field<T> {
  value: T;
  setValue: (value: T, doValidate?: boolean) => void;
  error: string;
  setError: (error: string) => void;
  validate: () => boolean;
}

export function useField<T = string>(
  initialState: T,
  validators: ValidatorFn<T>[],
): Field<T> {
  const [value, _setValue] = useState(initialState);
  const [shouldValidate, setShouldValidate] = useState(false);
  const [error, setError, _validate] = useValidation<T>(validators);

  const validate = useCallback(() => {
    setShouldValidate(true);
    return _validate(value);
  }, [value, _validate]);

  const setValue = useCallback(
    (value: T, _shouldValidate?: boolean) => {
      _setValue(value);

      if (_shouldValidate != null) {
        setShouldValidate(_shouldValidate);
      }

      if (shouldValidate || _shouldValidate || error) {
        _validate(value);
      }
    },
    [shouldValidate, error, _validate],
  );

  return {
    value,
    setValue,
    error: shouldValidate ? error : '',
    setError,
    validate,
  };
}
