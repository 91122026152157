import * as React from 'react';
import { Religion } from '../../model/entities/User';
import { Select } from './Select';

const options = [
  { value: Religion.Buddhist, label: 'Buddhist' },
  { value: Religion.ChristianCatholic, label: 'Christian - Catholic' },
  {
    value: Religion.ChristianProtestant,
    label: 'Christian - Protestant',
  },
  { value: Religion.Hindu, label: 'Hindu' },
  { value: Religion.Muslim, label: 'Muslim' },
  { value: Religion.Other, label: 'Other' },
  { value: Religion.PreferNotToSay, label: 'Prefer not to say' },
];

export interface ReligionFieldProps {
  name?: string;
  value?: Religion | string;
  onChange: (value: Religion) => void;
  isInvalid?: boolean;
}

export const ReligionField: React.FunctionComponent<ReligionFieldProps> = ({
  name,
  value,
  onChange,
  isInvalid,
}) => {
  return (
    <Select
      name={name}
      value={options.find((x) => x.value === value)}
      onChange={(value: any) => {
        onChange(value.value);
      }}
      options={options}
      isInvalid={isInvalid}
    />
  );
};
