import * as React from 'react';
import { AuthenticatedApiClient } from '../util/AuthenticatedApiClient';
import { User } from '../../model/entities/User';
import { useCreateNotification } from '../hooks/useCreateNotification';
import { useAsyncEffect } from '../hooks/useAsyncEffect';
import { useAuthTokensContext } from '../hooks/useAuthTokensContext';
import { AuthResponse } from '../../model/api/AuthResponse';

export interface ApiClientContextInterface {
  client: AuthenticatedApiClient;
  user: User | null;
  setAuth(auth: Partial<AuthResponse> | null): void;
  canvasInstallUrl: string;
  loading: boolean;
}

export const ApiClientContext =
  React.createContext<ApiClientContextInterface | null>(null);

export const ApiClientProvider: React.FunctionComponent = ({ children }) => {
  const { tokens, setTokens } = useAuthTokensContext();
  const createNotification = useCreateNotification();
  const [user, setUser] = React.useState<User | null>(null);
  const [canvasInstallUrl, setCanvasInstallUrl] = React.useState('');

  const client = React.useMemo(
    () => new AuthenticatedApiClient(tokens, createNotification),
    [tokens, createNotification],
  );

  const setAuth = React.useCallback(
    (result: AuthResponse | null) => {
      if (!result) {
        setUser(null);
        setTokens(null);
      } else {
        setUser(result.user || user);
        setTokens(result.tokens || tokens);
        setCanvasInstallUrl(result.canvasInstallUrl || canvasInstallUrl);
      }
    },
    [setUser, setTokens, setCanvasInstallUrl, user, tokens, canvasInstallUrl],
  );

  const loading = useAsyncEffect(async () => {
    if (tokens && tokens.refreshToken && !user) {
      const result = await client.refreshAuth(tokens.refreshToken);
      setAuth(result);
    }
  }, [user, tokens, client]);

  return (
    <ApiClientContext.Provider
      value={{ client, user, setAuth, canvasInstallUrl, loading }}
    >
      {children}
    </ApiClientContext.Provider>
  );
};
