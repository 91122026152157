import * as React from 'react';
import { Class } from '../../model/entities/Class';
import { UsernameVerification } from './UsernameVerification';
import { RegistrationForm } from './RegistrationForm';
import canvasBg from '../images/canvas-bg.png';
import logoWhite from '../images/gng-logo-white.png';
import { Card } from 'react-bootstrap';
import { User } from '../../model/entities/User';

const styles = require('../styles/UsernameVerificationRegistration.module.scss');

interface Props {
  onVerification: (usersIds: number[]) => Promise<any>;
  setIsSubmitting?: (submitting: boolean) => void;
  currentClass?: Class;
  onRegistration?: (userId: number) => Promise<any>;
  setIsRegistration?: (registering: boolean) => void;
  isRegistration?: boolean;
  addAnother?: boolean;
  backButton?: React.ReactElement;
  processing?: boolean;
}

export const UsernameVerificationRegistration: React.FC<Props> = ({
  currentClass,
  onVerification,
  onRegistration,
  setIsSubmitting,
  setIsRegistration,
  isRegistration,
  addAnother,
  backButton,
  processing,
}) => {
  const handleOnRegistration = async (user: User) => {
    onRegistration && onRegistration(parseInt(user.id!));
  };

  const handleSetIsSubmitting = (submitting: boolean) => {
    setIsSubmitting && setIsSubmitting(submitting);
  };

  const handleOnShowRegistration = () => {
    setIsRegistration && setIsRegistration(true);
  };

  return (
    <div
      className={styles.container}
      style={{
        backgroundImage: `linear-gradient(transparent, transparent 80%, #002f3d), url(${canvasBg})`,
      }}
    >
      <div className="justify-content-center row">
        <Card className="col-sm-8 col-md-6 col-lg-5 col-xl-4 bg-transparent text-white border-0">
          <Card.Body>
            <img
              alt="Logo"
              src={logoWhite}
              width={160}
              className="my-5 d-block"
            />
            {backButton}
            {isRegistration && currentClass ? (
              <RegistrationForm
                currentClass={currentClass}
                onRegistration={handleOnRegistration}
                setIsSubmitting={handleSetIsSubmitting}
                processing={processing}
              />
            ) : (
              <UsernameVerification
                onVerification={onVerification}
                onShowRegistration={handleOnShowRegistration}
                addAnother={addAnother}
                allowRegistration={!!currentClass}
                processing={processing}
              />
            )}
          </Card.Body>
        </Card>
      </div>
    </div>
  );
};
