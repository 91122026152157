import * as React from 'react';
import * as _ from 'lodash';
import { Player } from '@lottiefiles/react-lottie-player';
import { Button, Card } from 'react-bootstrap';
import { Route } from '../Route';
import { ApiClient } from '../../util/ApiClient';
import { Class } from '../../model/entities/Class';
import { useAsyncEffect } from '../hooks/useAsyncEffect';
import { CenteredBusySpinner } from '../components/Busy';
import { useCreateNotification } from '../hooks/useCreateNotification';
import { UsernameVerificationRegistration } from '../components/UsernameVerificationRegistration';

export const attendanceRoute = new Route<{ id: string }>(
  '/attendance/:id',
  ({ match }) => {
    const client = new ApiClient('/api');
    const createNotification = useCreateNotification();

    const [currentClass, setCurrentClass] = React.useState<Class>();
    const [showThankYou, setShowThankYou] = React.useState<boolean>();
    const [isRegistration, setIsRegistration] = React.useState(false);
    const [submitting, setSubmitting] = React.useState(false);
    const [processing, setProcessing] = React.useState(false);

    React.useEffect(() => {
      document.title = 'Global Nomads Group - attendance';
    }, []);

    const loading = useAsyncEffect(async () => {
      try {
        const response = await client.get(
          `/classes/${match.params.id}/attendance`,
        );
        console.log('response', response);
        setCurrentClass(response.data);
      } catch (error: any) {
        console.error('error', error.message);
        createNotification('Server error', error.message, 'danger', {
          cause: _.get(error, 'meta.details.message'),
        });
      }
    }, [match.params.id]);

    const handleOnVerification = async (usersIds: number[]) => {
      try {
        console.log('usersIds', usersIds);
        setProcessing(true);
        const response = await client.post(
          `/classes/${currentClass!.id}/students-enrollment`,
          {
            usersIds,
          },
        );
        console.log('response', response);
        setShowThankYou(true);
        setProcessing(false);
      } catch (error: any) {
        setProcessing(false);
        console.error('error', error.message);
        createNotification('Server error', error.message, 'danger', {
          cause: _.get(error, 'meta.details.message'),
        });
      }
    };

    const handleOnRegistration = async () => {
      setShowThankYou(true);
    };

    const handleBack = () => {
      setIsRegistration(false);
    };

    if (loading) {
      return <CenteredBusySpinner />;
    }

    if (!currentClass) {
      return <p>There was a problem loading the class.</p>;
    }

    if (showThankYou) {
      return (
        <div className="vh-100 d-flex flex-row">
          <div className="container">
            <div className="justify-content-center row">
              <div className="col-lg-8">
                <Card className="border-0">
                  <Card.Body>
                    <Player
                      autoplay
                      keepLastFrame={true}
                      src="https://assets7.lottiefiles.com/packages/lf20_ktwnwv5m.json"
                      style={{ height: '200px' }}
                    />
                    <h4 className="text-center">Thanks for attending!</h4>
                    <p className="text-center mt-5">
                      I’m sharing a device with others <br />
                      <Button
                        variant="link"
                        className="p-0"
                        onClick={() => window.location.reload()}
                      >
                        register another
                      </Button>
                    </p>
                  </Card.Body>
                </Card>
              </div>
            </div>
          </div>
        </div>
      );
    }

    return (
      <UsernameVerificationRegistration
        currentClass={currentClass}
        onVerification={handleOnVerification}
        onRegistration={handleOnRegistration}
        setIsRegistration={setIsRegistration}
        setIsSubmitting={setSubmitting}
        isRegistration={isRegistration}
        addAnother={true}
        backButton={
          isRegistration ? (
            <Button
              className="text-white p-0 mb-3"
              variant="link"
              onClick={handleBack}
              disabled={submitting}
            >
              Go back
            </Button>
          ) : undefined
        }
        processing={processing}
      />
    );
  },
);
