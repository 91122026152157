import * as React from 'react';
import * as _ from 'lodash';
import { Button, Card, Carousel } from 'react-bootstrap';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import 'survey-core/defaultV2.min.css';
import { StylesManager, Model } from 'survey-core';
import { Survey } from 'survey-react-ui';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Player } from '@lottiefiles/react-lottie-player';
import { Route } from '../Route';
import { ApiClient } from '../../util/ApiClient';
import { Class } from '../../model/entities/Class';
import { useAsyncEffect } from '../hooks/useAsyncEffect';
import { Assignment } from '../../model/entities/Assignment';
import { CenteredBusySpinner } from '../components/Busy';
import { AssignmentInstructionsModal } from '../modals/AssignmentInstructionsModal';
import { useLocalState } from '../hooks/useLocalState';
import { TemporaryStory } from '../../model/entities/TemporaryStory';
import '../styles/StorySubmission.scss';
import { useCreateNotification } from '../hooks/useCreateNotification';
import { UsernameVerificationRegistration } from '../components/UsernameVerificationRegistration';

export const storySubmissionRoute = new Route<{ id: string }>(
  '/stories-submissions/:id',
  ({ match }) => {
    const client = new ApiClient('/api');
    const createNotification = useCreateNotification();

    StylesManager.applyTheme('defaultV2');
    const [assignment, setAssignment] = React.useState<Assignment>();
    const [currentClass, setCurrentClass] = React.useState<Class>();
    const [story, setStory] = useLocalState<TemporaryStory>('story');
    const [survey, setSurvey] = React.useState<Model>();
    const [answerId, setAnswerId] = React.useState<string>();

    const [activeIndex, setActiveIndex] = React.useState(0);
    const [editorValue, setEditorValue] = React.useState('');
    let quillRef: ReactQuill;
    const [show, setShow] = React.useState(true);
    const [showStoryRequiredError, setShowStoryRequiredError] =
      React.useState(false);
    const [submitting, setSubmitting] = React.useState(false);
    const [processing, setProcessing] = React.useState(false);
    const [isRegistration, setIsRegistration] = React.useState(false);
    const [showThankYou, setShowThankYou] = React.useState(false);
    const [surveyCompleted, setSurveyCompleted] = React.useState(false);
    const [
      showUsernameVerificationRegistration,
      setShowUsernameVerificationRegistration,
    ] = React.useState(false);

    const handleOpen = () => setShow(true);
    const handleClose = () => setShow(false);
    const handleNext = () => setActiveIndex(activeIndex + 1);
    const handleBack = () => {
      if (!isRegistration) {
        setShowUsernameVerificationRegistration(false);
      } else {
        setIsRegistration(false);
      }
    };

    React.useEffect(() => {
      document.title = 'Global Nomads Group - stories submissions';
    }, []);

    const loading = useAsyncEffect(async () => {
      try {
        const response = await client.get(
          `/assignments-stories-submissions/${match.params.id}`,
        );
        console.log('response', response);
        setAssignment(response.data.assignment);
        setCurrentClass(response.data.class);
      } catch (error: any) {
        console.error('error', error.message);
        createNotification('Server error', error.message, 'danger', {
          cause: _.get(error, 'meta.details.message'),
        });
      }
    }, [match.params.id]);

    const handleSubmitStory = async () => {
      if (quillRef.getEditor().getLength() <= 1) {
        setShowStoryRequiredError(true);
        setTimeout(() => {
          setShowStoryRequiredError(false);
        }, 4000);
        return;
      }
      setShowUsernameVerificationRegistration(true);
      let response;
      if (!story) {
        response = await client.post('/temporary-stories', {
          content: editorValue,
        });
      } else {
        response = await client.patch(`/temporary-stories/${story.id}`, {
          content: editorValue,
        });
      }
      console.log('response', response);
      setStory(response.data);
    };

    const handleOnRegistration = async (userId: number) => {
      console.log(userId);
      await handleStorySubmission(userId);
    };

    const handleOnVerification = async (usersIds: number[]) => {
      try {
        console.log(usersIds);
        if (!usersIds.length) {
          throw new Error('No user id.');
        }

        setProcessing(true);
        await client.post(`/classes/${currentClass!.id}/students-enrollment`, {
          usersIds,
        });
      } catch (error: any) {
        setProcessing(false);
        console.error('error', error.message);
        createNotification('Server error', error.message, 'danger', {
          cause: _.get(error, 'meta.details.message'),
        });
      }

      await handleStorySubmission(usersIds[0]);
    };

    const handleStorySubmission = async (userId: number) => {
      try {
        console.log(userId);
        setSubmitting(true);
        setProcessing(true);
        const storySubmissionResponse = await client.post(
          `/users/${userId}/assignments/${match.params.id}/stories-submissions`,
          {
            story: {
              id: story && story.id,
              content: editorValue,
            },
          },
        );

        console.log('storySubmissionResponse', storySubmissionResponse.data);

        setStory(undefined);

        try {
          const response = await client.get(
            `/courses/${assignment!.course.id}/students/${userId}/assignments/${
              assignment!.id
            }/survey`,
          );
          setShowUsernameVerificationRegistration(false);
          setShowThankYou(true);

          setSurvey(new Model(JSON.parse(response.data.survey.json)));
          setAnswerId(response.data.answerId);
        } catch (error: any) {
          setShowUsernameVerificationRegistration(false);
          setShowThankYou(true);
        }
        setSubmitting(false);
        setProcessing(false);
        handleNext();
      } catch (error: any) {
        setSubmitting(false);
        setProcessing(false);
        console.error('error', error.message);
        createNotification('Server error', error.message, 'danger', {
          cause: _.get(error, 'meta.details.message'),
        });
      }
    };

    const setSurveyLocale = (event: any) => {
      console.log(event);
      if (survey) {
        survey.locale = event.target.value;
      }
    };

    const handleCompleteSurvey = async (result: any) => {
      await client.post(
        `/answers/${answerId}/sections/${assignment!.sectionId}`,
        {
          value: JSON.stringify(result.data),
        },
      );
      setSurveyCompleted(true);
    };

    const handlePlayerEvent = (event: string) => {
      if (event === 'complete' && survey) {
        handleNext();
      }
    };

    if (loading) {
      return <CenteredBusySpinner />;
    }

    if (!assignment || !currentClass) {
      return <p>There was a problem loading the assignment or class.</p>;
    }

    return (
      <>
        <div className="vh-100 d-flex flex-row">
          <div className="container">
            <div className="justify-content-center row">
              <div className="col-lg-8">
                <Carousel
                  indicators={false}
                  controls={false}
                  activeIndex={activeIndex}
                  onSelect={() => {}}
                >
                  <Carousel.Item className="carouselInner">
                    <Card className="border-0">
                      <Card.Header
                        className="text-center border-bottom-0 bg-white"
                        as="h2"
                      >
                        Story
                        <FontAwesomeIcon
                          icon={faInfoCircle}
                          className="mr-2 position-absolute right-0"
                          onClick={handleOpen}
                        />
                      </Card.Header>
                      <Card.Body className="p-0">
                        <ReactQuill
                          ref={(el: any) => (quillRef = el)}
                          modules={{
                            toolbar: [
                              [{ header: [1, 2, false] }],
                              ['bold', 'italic', 'underline'],
                              ['image'],
                            ],
                          }}
                          onChange={setEditorValue}
                          className="editorContainer"
                          placeholder="Compose your story here ..."
                        />
                        {showStoryRequiredError && (
                          <div className="invalid-feedback d-block text-center">
                            The story is required
                          </div>
                        )}
                      </Card.Body>
                      <Card.Footer className="text-center border-top-0 bg-white">
                        <Button
                          variant="success"
                          block
                          className="rounded-pill"
                          onClick={handleSubmitStory}
                        >
                          Next
                        </Button>
                      </Card.Footer>
                    </Card>
                  </Carousel.Item>
                  {showThankYou && (
                    <Carousel.Item>
                      <Card className="border-0">
                        <Card.Header
                          className="text-center border-bottom-0 bg-white"
                          as="h2"
                        >
                          Thanks!
                        </Card.Header>
                        <Card.Body>
                          <p className="text-center">
                            Your story is submitted, thank you!
                          </p>
                          <Player
                            autoplay
                            speed={0.5}
                            onEvent={handlePlayerEvent}
                            src="https://assets1.lottiefiles.com/temporary_files/i5pVEt.json"
                            style={{ height: '100%' }}
                          />
                          {!survey && (
                            <p className="text-center">
                              I’m sharing a device with others <br />
                              <Button
                                variant="link"
                                className="p-0"
                                onClick={() => window.location.reload()}
                              >
                                submit another
                              </Button>
                            </p>
                          )}
                        </Card.Body>
                      </Card>
                    </Carousel.Item>
                  )}
                  {survey && (
                    <Carousel.Item>
                      <Card className="border-0">
                        <Card.Body>
                          {!surveyCompleted ? (
                            <>
                              <div className="text-right">
                                <select onChange={setSurveyLocale}>
                                  <option value="">English</option>
                                  <option value="ar">العربية</option>
                                  <option value="es">Español</option>
                                </select>
                              </div>
                              <Survey
                                model={survey}
                                onComplete={handleCompleteSurvey}
                              />
                            </>
                          ) : (
                            <>
                              <h4 className="text-center">Thanks!</h4>
                              <p className="text-center mt-5">
                                I’m sharing a device with others <br />
                                <Button
                                  variant="link"
                                  className="p-0"
                                  onClick={() => window.location.reload()}
                                >
                                  submit another
                                </Button>
                              </p>
                            </>
                          )}
                        </Card.Body>
                      </Card>
                    </Carousel.Item>
                  )}
                </Carousel>
                <AssignmentInstructionsModal
                  show={show}
                  onHide={handleClose}
                  title={assignment.name}
                  description={assignment.description}
                />
              </div>
            </div>
          </div>
        </div>
        {showUsernameVerificationRegistration && (
          <div className="zindex-modal fixed-top">
            <UsernameVerificationRegistration
              currentClass={currentClass}
              onRegistration={handleOnRegistration}
              onVerification={handleOnVerification}
              setIsSubmitting={setSubmitting}
              setIsRegistration={setIsRegistration}
              isRegistration={isRegistration}
              addAnother={false}
              backButton={
                <Button
                  className="text-white p-0 mb-3"
                  variant="link"
                  onClick={handleBack}
                  disabled={submitting}
                >
                  Go back
                </Button>
              }
              processing={processing}
            />
          </div>
        )}
      </>
    );
  },
);
