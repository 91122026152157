import * as React from 'react';

const styles = require('../styles/Avatar.module.scss');

export interface AvatarProps {
  url: string;
}

export const Avatar: React.FunctionComponent<AvatarProps> = ({ url }) => (
  <img src={url} className={styles.avatar} alt="Profile" />
);
