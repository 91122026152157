import * as React from 'react';
import cs from 'classnames';
import { useBoolean } from '../hooks/useBoolean';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretRight, faCaretDown } from '@fortawesome/free-solid-svg-icons';

const styles = require('../styles/CaretCollapse.module.scss');

export interface CaretCollapseProps {
  title: React.ReactNode;
  className?: string;
}

export const CaretCollapse: React.FunctionComponent<CaretCollapseProps> = ({
  title,
  children,
  className,
}) => {
  const collapse = useBoolean(true);

  return (
    <div className={cs(styles.container, className)}>
      <div className={styles.title} onClick={collapse.toggle}>
        <span className={styles.caret}>
          {collapse.state ? (
            <FontAwesomeIcon icon={faCaretRight} />
          ) : (
            <FontAwesomeIcon icon={faCaretDown} />
          )}
        </span>
        {title}
      </div>

      {!collapse.state && <div className={styles.body}>{children}</div>}
    </div>
  );
};
