import * as React from 'react';
// import Check from '../../frontend/images/check.png';
import { ReactComponent as CoursePlaceholder } from '../images/placeholder.svg';

const styles = require('../styles/Module.module.scss');

interface ModuleProps {
  courseName: string;
  moduleName: string;
  moduleImage: any;
  moduleProgress: string;
}

export const Module: React.FC<ModuleProps> = ({
  courseName,
  moduleName,
  moduleImage,
  moduleProgress,
}) => {
  const moduleStep = moduleName.split(':')[0];
  const name = moduleName.split(':')[1];
  return (
    <div className={styles.moduleCard}>
      <small>{moduleStep}</small>
      <h5 className={styles.noWrap}>{name}</h5>
      <div>
        {moduleImage ? (
          <img className={styles.cardImg} src={moduleImage} alt="module" />
        ) : (
          <CoursePlaceholder className={styles.cardImg} />
        )}
        {/* <small>Cody B.</small> */}
        {/* <p className={styles.cardTitle}>{moduleName}</p> */}
      </div>
      {/* <footer>
        <div className={styles.progressBar}>
          <header>
            <span>Progress</span>
            <span>
              Completed <img src={Check} alt="check-mark" />
            </span>
          </header>
          <div className={styles.bar}>
            <div className={styles.filled}></div>
          </div>
        </div>
      </footer> */}
    </div>
  );
};
