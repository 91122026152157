import * as React from 'react';
import { Badge } from 'react-bootstrap';
import * as moment from 'moment';

export interface DateRangeBadgeProps {
  startDate: string | Date;
  endDate: string | Date;
  className?: string;
}

export const DateRangeBadge: React.FunctionComponent<DateRangeBadgeProps> = ({
  startDate,
  endDate,
  className,
}) => {
  return (
    <Badge variant="info" className={'text-white' + (className ? ` ${className}` : '')}>
      {moment.utc(startDate).format('DD MMM YYYY')} -{' '}
      {moment.utc(endDate).format('DD MMM YYYY')}
    </Badge>
  );
};
