import { User } from '../entities/User';

export enum AuthResponseErrorCode {
  NoRole = 'NO_ROLE',
  StudentRole = 'STUDENT_ROLE',
}

export interface AuthResponse {
  tokens?: AuthTokens;
  user?: User;
  error?: AuthResponseErrorCode;
  canvasInstallUrl?: string;
}

export interface AuthTokens {
  accessToken: string;
  refreshToken: string;
}
