export const palestineCities = [
  {
    name: 'East Jerusalem',
    countryCode: 'PS',
    latitude: '31.9',
    longitude: '35.2',
  },
  {
    name: 'Hebron',
    countryCode: 'PS',
    latitude: '31.9',
    longitude: '35.2',
  },
  {
    name: 'Nablus',
    countryCode: 'PS',
    latitude: '31.9',
    longitude: '35.2',
  },
  {
    name: 'Tulkarm',
    countryCode: 'PS',
    latitude: '31.9',
    longitude: '35.2',
  },
  {
    name: 'Qalqiliya',
    countryCode: 'PS',
    latitude: '31.9',
    longitude: '35.2',
  },
  {
    name: 'Jenin',
    countryCode: 'PS',
    latitude: '31.9',
    longitude: '35.2',
  },
  {
    name: 'Ramallah',
    countryCode: 'PS',
    latitude: '31.9',
    longitude: '35.2',
  },
  {
    name: 'Bethlehem',
    countryCode: 'PS',
    latitude: '31.9',
    longitude: '35.2',
  },
  {
    name: 'Tubas',
    countryCode: 'PS',
    latitude: '31.9',
    longitude: '35.2',
  },
  {
    name: 'Jericho',
    countryCode: 'PS',
    latitude: '31.9',
    longitude: '35.2',
  },
  {
    name: 'Salfit',
    countryCode: 'PS',
    latitude: '31.9',
    longitude: '35.2',
  },
];
