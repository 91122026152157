import 'isomorphic-fetch';

export class ApiError extends Error {
  public type = 'ApiError';

  constructor(
    public readonly status: number,
    public readonly meta: ApiErrorDetails,
  ) {
    super(meta ? meta.message : 'unknown error');
  }
}

export interface ApiErrorDetails {
  message: string;
  status: number;
  code?: string;
  [key: string]: any;
}

export interface RequestConfig extends RequestInit {
  url: string;
  headers: Record<string, string>;
}

export interface ApiRequestConfigurator {
  beforeRequest?: (request: RequestConfig) => void | Promise<void>;

  afterRequest?: (
    response: Response | Error,
    retry: () => Promise<any>,
    client: ApiClient,
    request: RequestConfig,
  ) => void | Promise<void>;
}

export class ApiClient {
  public bearerToken?: string = undefined;

  constructor(
    protected baseUrl: string = '',
    private configurators: ApiRequestConfigurator[] = [],
  ) {}

  setBearerToken(bearerToken: string) {
    this.bearerToken = bearerToken;
  }

  async request(
    method: string,
    url: string,
    body?: object,
    headers?: Record<string, string>,
  ) {
    const requestConfig: RequestConfig = {
      url: this.baseUrl + url,
      method,
      headers: {
        Accept: 'application/json',
      },
      redirect: 'manual',
    };

    if (body) {
      requestConfig.headers['Content-type'] = 'application/json';
      requestConfig.body = JSON.stringify(body);
    }

    return this.requestWithConfig(requestConfig, headers);
  }

  async requestWithConfig(
    requestConfig: RequestConfig,
    headers?: Record<string, string>,
  ) {
    if (this.bearerToken) {
      requestConfig.headers['Authorization'] = `Bearer ${this.bearerToken}`;
    }

    Object.assign(requestConfig.headers, headers);

    await this.runHook('beforeRequest', requestConfig);

    let response: Response;

    try {
      response = await fetch(requestConfig.url, requestConfig);

      const result = await this.runHook(
        'afterRequest',
        response,
        () => this.requestWithConfig(requestConfig),
        this,
        requestConfig,
      );

      if (result) {
        return result;
      }

      switch (response.status) {
        case 200:
        case 201:
          return response.headers.get('Content-Type') && response.headers.get('Content-Type')!.includes('application/json') ? response.json() : response;

        case 204:
          return null;

        default:
          const responseJson = await response
            .json()
            .catch(() => ({ error: { message: 'unspecified' } }));

          throw new ApiError(response.status, responseJson.error);
      }
    } catch (err) {
      await this.runHook('afterRequest', err);
      throw err;
    }
  }

  private async runHook<K extends keyof ApiRequestConfigurator>(
    name: K,
    ...args: any[]
  ) {
    let result = null;

    for (const configurator of this.configurators) {
      if (configurator[name]) {
        const hook = configurator[name] as any;
        result = (await hook.apply(configurator, args)) || result;
      }
    }

    return result;
  }

  get<TResponse = any>(url: string): Promise<TResponse> {
    return this.request('GET', url);
  }

  post<TResponse = any, TRequest = any>(
    url: string,
    body: TRequest,
  ): Promise<TResponse> {
    return this.request('POST', url, body as any);
  }

  put<TResponse = any, TRequest = any>(
    url: string,
    body: TRequest,
  ): Promise<TResponse> {
    return this.request('PUT', url, body as any);
  }

  patch<TResponse = any, TRequest = any>(
    url: string,
    body: TRequest,
  ): Promise<TResponse> {
    return this.request('PATCH', url, body as any);
  }

  delete<TResponse = any>(url: string): Promise<TResponse> {
    return this.request('DELETE', url);
  }
}
