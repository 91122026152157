import { ChangeEvent, useCallback, FormEventHandler } from 'react';
import { Field, useField } from './useField';
import { ValidatorFn } from './useValidation';

export interface InputField<T> extends Field<T> {
  onChange: FormEventHandler<any>;
}

export function useInputField(
  initialState: string,
  validators: ValidatorFn<string>[],
): InputField<string> {
  const field = useField(initialState, validators);

  const onChange = useCallback<FormEventHandler<any>>(
    (e: ChangeEvent<any>) => {
      field.setValue((e.target as any).value);
    },
    [field],
  );

  return {
    ...field,
    onChange,
  };
}

export function useInputNumberField(
  initialState: number | string,
  validators: ValidatorFn<number | string>[],
): InputField<number | string> {
  const field = useField(initialState, validators);

  const onChange = useCallback<FormEventHandler<any>>(
    (e: ChangeEvent<any>) => {
      field.setValue((e.target as any).value);
    },
    [field],
  );

  return {
    ...field,
    onChange,
  };
}
