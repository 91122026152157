import {
  DependencyList,
  useCallback,
  useState,
  useRef,
  useEffect,
} from 'react';

export function useAsyncCallback<T extends (...args: any[]) => Promise<any>>(
  callback: T,
  deps: DependencyList = [],
) {
  const [busy, setBusy] = useState(0);
  const mounted = useRef(false);

  useEffect(() => {
    mounted.current = true;

    return () => {
      mounted.current = false;
    };
  }, []);

  const call = useCallback(async (...args: any[]) => {
    setBusy(busy => busy + 1);

    try {
      return await callback(...args);
    } finally {
      if (mounted.current) {
        setBusy(busy => busy - 1);
      }
    }
    // TODO: fix
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps) as T;

  return { call, busy: busy > 0 };
}
