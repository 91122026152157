import * as React from 'react';
import { Modal, Form, Col } from 'react-bootstrap';
import { ModalProps } from './ModalProps';
import { Player } from '@lottiefiles/react-lottie-player';

export interface GlobalFacilitatorCommunityModalProps extends ModalProps {
  show: boolean;
  onHide: () => void;
}

export const GlobalFacilitatorCommunityModal: React.FunctionComponent<
  GlobalFacilitatorCommunityModalProps
> = ({ show, onHide }) => {
  return (
    <Modal
      backdrop
      show={show}
      onHide={onHide}
      size="lg"
      contentClassName="p-4 border-radius"
    >
      <Modal.Body>
        <Form.Row>
          <Form.Row>
            <Col xs={7}>
              <h5>Join The Community!</h5>
              <p>
                Join our <b>Global Nomads Facilitator Community</b> on LinkedIn!
                Our community is your hub for:
              </p>
              <ul>
                <li>
                  Reflecting on your experiences and sharing successes and best
                  practices
                </li>
                <li>
                  Building connections and exchanging ideas with your global
                  peers
                </li>
                <li>
                  Supporting with facilitating the Student to World program
                </li>
                <li>Providing valuable resources to support your journey</li>
              </ul>

              <p>
                We're thrilled to have you! Let's collaborate to support
                students globally and learn from one another!
              </p>
            </Col>
            <Col xs={5}>
              <Player
                autoplay
                keepLastFrame={true}
                src={
                  'https://lottie.host/26fd1cee-4b8f-4fc4-95ac-9dfd627c1b5f/jwJuWBQBPV.json'
                }
              />
            </Col>
          </Form.Row>
          <Col xs={12}>
            <div className="item-link mt-4">
              <span>Click </span>
              <a
                target={'_blank'}
                rel="noreferrer noopener"
                className={'text-center'}
                href={'https://www.linkedin.com/groups/12893011/'}
              >
                here
              </a>
              <span>
                {' '}
                to join or copy and paste the following link to your browser to
                access it:{' '}
                <a
                  target="_blank"
                  href="https://www.linkedin.com/groups/12893011/"
                  rel="noreferrer noopener"
                  className="text-center"
                >
                  https://www.linkedin.com/groups/12893011/
                </a>
              </span>
            </div>
          </Col>
        </Form.Row>
      </Modal.Body>
    </Modal>
  );
};
