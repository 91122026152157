import * as React from 'react';
import { UserGender } from '../../model/entities/User';
import { RadioGroup } from './RadioGroupField';

export interface GenderFieldProps {
  name?: string;
  value?: UserGender | string;
  onChange: (value: any) => void;
}

export const GenderField: React.FunctionComponent<GenderFieldProps> = ({
  name,
  value,
  onChange,
}) => {
  return (
    <RadioGroup
      name={name}
      inline
      value={value}
      onChange={onChange}
      options={[
        { value: UserGender.Female, label: 'Female' },
        { value: UserGender.Male, label: 'Male' },
        { value: UserGender.NonBinary, label: 'Non-binary' },
        { value: UserGender.PreferNotToSay, label: 'Prefer not to say' },
        { value: UserGender.Other, label: 'Other' },
      ]}
    />
  );
};
