import * as React from 'react';
import { Race } from '../../model/entities/User';
import { Select } from './Select';

const options = [
  { value: Race.AmericanIndian, label: 'American Indian' },
  { value: Race.Asian, label: 'Asian' },
  {
    value: Race.BlackAfricanAmericanAfricanBritish,
    label: 'Black / African American / African British',
  },
  { value: Race.Latinx, label: 'Latinx' },
  { value: Race.MultipleEthnicGroups, label: 'Multiple ethnic groups' },
  { value: Race.OtherEthnicGroup, label: 'Other ethnic group' },
  { value: Race.White, label: 'White' },
  { value: Race.PacificIslander, label: 'Pacific Islander' },
  { value: Race.PreferNotToSay, label: 'Prefer not to say' },
];

export interface RaceFieldProps {
  name?: string;
  value?: Race;
  onChange: (value: Race) => void;
  isInvalid?: boolean;
}

export const RaceField: React.FunctionComponent<RaceFieldProps> = ({
  name,
  value,
  onChange,
  isInvalid,
}) => {
  return (
    <Select
      name={name}
      value={options.find((x) => x.value === value)}
      onChange={(value: any) => {
        onChange(value.value);
      }}
      options={options}
      isInvalid={isInvalid}
    />
  );
};
