import { useRef, useEffect } from 'react';
import * as _ from 'lodash';

export type ListenerMap = {
  [K in keyof HTMLElementEventMap]?: (e: HTMLElementEventMap[K]) => void;
};

/**
 * Subscribes the given element up to multiple event listeners, and returns a function to unsubscribe.
 * @param element
 * @param listeners
 */
export function subscribeToDom<TElement extends HTMLElement>(
  element: TElement,
  listeners: ListenerMap,
) {
  _.forEach(listeners, (listener, type) =>
    element.addEventListener(type, listener as any),
  );

  return () => {
    _.forEach(listeners, (listener, type) =>
      element.removeEventListener(type, listener as any),
    );
  };
}

export function useSubscribeToDom<T extends HTMLElement>(
  listeners: ListenerMap,
  _ref?: React.RefObject<T>,
) {
  const defaultRef = useRef<T>(null);
  const ref = _ref || defaultRef;

  // subscribe to the dom events on mount
  useEffect(() => {
    if (!ref.current) return;

    return subscribeToDom(ref.current, listeners);
  }, [listeners, ref]);

  return ref;
}
