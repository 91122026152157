import * as React from 'react';
import { Dropdown } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons';
import { useApiClientContext } from '../hooks/useApiClientContext';

export interface ViewInCanvasLinkProps {
  url: string;
}

export const ViewInCanvasLink: React.FunctionComponent<
  ViewInCanvasLinkProps
> = ({ url }) => {
  const { canvasInstallUrl } = useApiClientContext();

  return (
    <Dropdown.Item href={`https://${canvasInstallUrl}${url}`} target="_blank">
      View in Canvas
      <FontAwesomeIcon icon={faExternalLinkAlt} className="ml-2" />
    </Dropdown.Item>
  );
};
