import * as React from 'react';
import { Nav, NavDropdown, Navbar } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import { auth } from 'firebase';
import { Avatar } from './Avatar';
import { useUser } from '../hooks/useUser';
import { Route } from '../Route';
import { useApiClientContext } from '../hooks/useApiClientContext';
import logo from '../images/gng-logo.png';

export interface TopNavbarProps {
  brand?: React.ReactNode;
  routes: Route[];
}

export const TopNavbar: React.FunctionComponent<TopNavbarProps> = ({
  brand,
  routes,
  children,
}) => {
  const { setAuth } = useApiClientContext();
  const user = useUser();

  const signOut = () => {
    setAuth(null);
    auth().signOut();
  };

  return (
    <div>
      <Navbar variant="light" bg="white" expand="lg">
        <Navbar.Brand as={NavLink} to={'/classes'} className="font-size-14">
          <img src={logo} height={40} alt="GNG" className="mr-2" />
          {brand}
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse className="justify-content-end">
          <Nav>
            {user && (
              <NavDropdown
                title={
                  <span>
                    {user.avatarUrl && <Avatar url={user.avatarUrl} />}
                    <span className="font-size-14">{user.name}</span>
                  </span>
                }
                id="user-nav-dropdown"
              >
                {routes
                  .filter((route) => route.options.title)
                  .map((route, index) => (
                    <NavDropdown.Item
                      as={NavLink}
                      to={route.formatPath({})}
                      key={index}
                    >
                      {route.options.title}
                    </NavDropdown.Item>
                  ))}
                <NavDropdown.Item onClick={signOut}>Sign out</NavDropdown.Item>
              </NavDropdown>
            )}
          </Nav>
        </Navbar.Collapse>
      </Navbar>
      {children}
    </div>
  );
};
