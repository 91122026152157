import * as React from 'react';
import { Modal, Button } from 'react-bootstrap';
import { ModalProps } from './ModalProps';
import { BusyButton } from '../components/Busy';
import { UserSearch } from '../components/UserSearch';
import { User } from '../../model/entities/User';
import { List } from '../components/List';
import { ListItem } from '../components/ListItem';
import { IconButton } from '../components/IconButton';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import * as _ from 'lodash';

export interface ChooseAdminModalProps extends ModalProps {
  title: React.ReactNode;
  onChoose: (users: User[]) => void;
  busy?: boolean;
  action: React.ReactNode;
  existingUsers?: User[];
}

export const ChooseAdminModal: React.FunctionComponent<
  ChooseAdminModalProps
> = ({ show, onHide, title, onChoose, busy, action, existingUsers }) => {
  const [users, setUsers] = React.useState<User[]>([]);

  const disabledUsers = React.useMemo(
    () => _.concat(users, existingUsers || []),
    [users, existingUsers],
  );

  return (
    <Modal backdrop show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <UserSearch
          onChangeUser={(user: User) => {
            !users.find(el => el.id === user.id) && setUsers([user, ...users]);
          }}
          disabledUsers={disabledUsers}
          value={null}
          includeCanvas
        />

        <List
          className="mt-3"
          items={users}
          renderItem={user => (
            <ListItem
              key={user.id}
              title={user.name}
              right={
                <IconButton
                  icon={faTimes}
                  onClick={() => {
                    setUsers(users.filter(x => x.id !== user.id));
                  }}
                  variant="danger"
                />
              }
            />
          )}
        />
      </Modal.Body>

      <Modal.Footer>
        <Button variant="outline-secondary" onClick={onHide} disabled={busy}>
          Cancel
        </Button>

        <BusyButton
          busy={busy}
          disabled={users.length === 0}
          onClick={() => {
            if (users.length > 0) {
              onChoose(users);
            }
          }}
        >
          {action}
        </BusyButton>
      </Modal.Footer>
    </Modal>
  );
};
