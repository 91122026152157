import { Entity } from './Entity';

export interface Class extends Entity {
  name: string;
  updated: any;
  courses: ClassCourse[];
  selfRegistration?: {
    enabled?: boolean;
    token?: string;
    limit?: number;
    waitlistUrl?: string;
    parentDetails?: boolean;
    gradeLevel?: boolean;
    languages?: boolean;
    gender?: boolean;
    age?: boolean;
    address?: boolean;
    terms?: boolean;
    termsPartner?: string;
    school?: boolean;
    individualCourseEnrol?: boolean;
    race?: boolean;
    religion?: boolean;
    disability?: boolean;
    marginalizedIdentity?: boolean;

    // virtual
    limitReached?: boolean;
    termsUrl?: string;
    privacyUrl?: string;
  };
  startDate?: any;
  endDate?: any;
  students?: string[];
  delivery: ClassDelivery[];
  environment?: ClassEnvironment;
  sessionsPerWeek?: number;
  active: boolean;
}

export interface ClassCourse {
  id: number;
  name: string;
  sectionId?: number;
  classId?: string;
  uuid: string;
  title: string;
}

export interface Course {
  id: string;
  title?: string;
  name?: string;
}

type Banner = {
  url: string;
};

export interface ModuleT {
  title: string;
  banner: Banner;
  order: number;
}

export enum ClassDelivery {
  OnlineCourse = 'online course',
  Video = 'video',
  LiveEvent = 'live event',
}

export enum ClassEnvironment {
  CoreSubject = 'core subject',
  ElectiveClass = 'elective class',
  AdvisoryPeriod = 'advisory period',
  AfterSchoolClub = 'after school club',
  NonSchoolRelatedClub = 'non-school related club',
}

export interface ModalState {
  visible: boolean;
  show?: void;
  hide?: void;
}

export interface AvailableCourseSectionData {
  courseName: object[];
  numberOfStudents: number | undefined;
}
