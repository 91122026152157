import * as React from 'react';
import parse from 'react-html-parser';
import { Modal, Form, Col } from 'react-bootstrap';
import { ModalProps } from './ModalProps';
import { Player } from '@lottiefiles/react-lottie-player';

export interface FaqsModalProps extends ModalProps {
  show: boolean;
  content: any;
}

export const FaqsModal: React.FunctionComponent<FaqsModalProps> = ({
  show,
  onHide,
  content,
}) => {
  return (
    <Modal
      backdrop
      show={show}
      onHide={onHide}
      size="lg"
      contentClassName="p-4 border-radius"
    >
      <Modal.Body>
        <Form.Row>
          <Form.Row>
            <Col xs={7}>
              <h5>Frequently Asked Questions.</h5>
              {parse(content?.data.landingPage.pageContent.html)}
            </Col>
            <Col xs={5}>
              <Player
                autoplay
                keepLastFrame={true}
                src={
                  'https://assets1.lottiefiles.com/packages/lf20_ssIwdK.json'
                }
              />
            </Col>
          </Form.Row>
          <Col xs={12}></Col>
        </Form.Row>
      </Modal.Body>
    </Modal>
  );
};
