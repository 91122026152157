import * as Papa from 'papaparse';

export function parseCsv(csv: string) {
  return new Promise<Papa.ParseResult>((resolve, reject) => {
    Papa.parse(csv, {
      complete: resolve,
      error: reject,
    });
  });
}
