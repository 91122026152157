import * as React from 'react';
import * as _ from 'lodash';
import { Dropdown } from 'react-bootstrap';
import { IconButton } from './IconButton';
import { faEllipsisV } from '@fortawesome/free-solid-svg-icons';

export const OverflowMenu: React.FunctionComponent = ({ children }) => {
  const id = _.uniqueId('overflow-menu');

  return (
    <Dropdown onClick={(e: any) => e.stopPropagation()}>
      <Dropdown.Toggle id={id} as={ToggleButton} />
      <Dropdown.Menu alignRight>{children}</Dropdown.Menu>
    </Dropdown>
  );
};

const ToggleButton = React.forwardRef((props: any, ref: any) => (
  <div ref={ref}>
    <IconButton
      variant="highlight-1"
      icon={faEllipsisV}
      onClick={props.onClick}
    >
      {props.children}
    </IconButton>
  </div>
));
