import React from 'react';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

export interface SkeletonItemInterface {
  count?: number;
  width?: string | number;
  height?: string | number;
  circle?: boolean;
  className?: string;
  direction?: 'ltr' | 'rtl';
}

const SkeletonItem = ({
  count,
  width,
  height,
  circle,
  className,
  direction,
}: SkeletonItemInterface) => {
  return (
    <Skeleton
      count={count}
      width={width}
      height={height}
      circle={circle}
      className={className}
      direction={direction}
    />
  );
};

export default SkeletonItem;
