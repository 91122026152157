import { Entity } from './Entity';
import { ClassCourse } from './Class';

export enum UserRole {
  Admin = 'admin',
  Educator = 'educator',
  Student = 'student',
}

interface additionFormInterface {
  schoolName: string;
  schoolAddress: string;
  educationType: string;
  countryAdditional: string;
}

export interface User extends Entity {
  created: any;
  loginId: string;
  name: string;
  first_name?: string;
  last_name?: string;
  username?: string;
  classes: string[];
  role?: UserRole;
  email: string;
  admin?: boolean;
  avatarUrl?: string;
  accessRequested?: boolean;
  courses: ClassCourse[];
  email_verified?: boolean;

  // student fields
  initialPassword?: string;
  gradeLevel?: string;
  gender?: UserGender;
  age?: string;
  languages?: KnownLanguage[];
  school?: string;
  address?: Address;
  additionalEmail?: string;
  terms?: boolean;
  termsPartner?: boolean;
  phone?: string;
  termsTransfer?: boolean;
  race?: Race;
  religion?: Religion;
  disability?: Disability;
  marginalizedIdentity?: string;

  showPopup?: boolean;
  additionalForm?: additionFormInterface;
}

export enum UserGender {
  Male = 'male',
  Female = 'female',
  NonBinary = 'non_binary',
  PreferNotToSay = 'prefer_not_say',
  Other = 'other',
}

export enum LanguageProficiency {
  Native = 'native',
  Fluent = 'fluent',
  Intermediate = 'intermediate',
  Basic = 'basic',
  None = 'none',
}

export interface KnownLanguage {
  language: string;
  proficiency: LanguageProficiency;
}

export interface Address {
  city: any;
  state: string;
  country: any;
}

export enum Race {
  AmericanIndian = 'american indian',
  Asian = 'asian',
  BlackAfricanAmericanAfricanBritish = 'black / african american / african british',
  PacificIslander = 'pacific islander',
  Latinx = 'latinx',
  White = 'white',
  MultipleEthnicGroups = 'multiple ethnic groups',
  OtherEthnicGroup = 'other ethnic group',
  PreferNotToSay = 'prefer not to say',
}

export enum Religion {
  Muslim = 'muslim',
  ChristianCatholic = 'christian - catholic',
  Buddhist = 'buddhist',
  Hindu = 'hindu',
  ChristianProtestant = 'christian - protestant',
  Other = 'other',
  PreferNotToSay = 'prefer not to say',
}

export enum Disability {
  Yes = 'yes',
  No = 'no',
  PreferNotToSay = 'prefer not to say',
}
