import * as React from 'react';
import ReactSelect from 'react-select';
import { Props } from 'react-select/lib/Select';
import { StylesConfig } from 'react-select/lib/styles';

export const Select = <T extends {}>({
  isMulti,
  options,
  getOptionLabel,
  getOptionValue,
  value,
  onChange,
  placeholder,
  isInvalid,
  isDisabled,
  ...rest
}: Props<T>) => {
  const customStyles: StylesConfig = {
    control: (base, state) => ({
      ...base,
      // state.isFocused can display different borderColor if you need it
      borderColor: state.isFocused ?
        '#ddd' : !isInvalid ?
        '#ddd' : '#dc3545',
      // overwrittes hover style
      '&:hover': {
        borderColor: state.isFocused ?
          '#ddd' : !isInvalid ?
          '#ddd' : '#dc3545'
      }
    })
  };

  return (
    <ReactSelect
      isMulti={isMulti}
      styles={customStyles}
      placeholder={placeholder}
      options={options}
      getOptionLabel={getOptionLabel}
      getOptionValue={getOptionValue}
      value={value}
      onChange={onChange}
      isDisabled={isDisabled}
      {...rest}
    />
  );
}
