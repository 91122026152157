import * as React from 'react';
import {Formik, FormikConfig} from 'formik';
import * as yup from 'yup';
import {Col, Form} from 'react-bootstrap';
import _ from 'lodash';
import {BusyButton} from './Busy';
import {User} from '../../model/entities/User';
import {CountryFieldSelect} from './CountryFieldSelect';
import {CityFieldSelect} from './CityFieldSelect';
import {ApiClient} from '../../util/ApiClient';
import {useCreateNotification} from '../hooks/useCreateNotification';
import {
    popupCenter,
} from '../routes/selfRegistrationRoute';
import {Country} from 'country-state-city';
import {City} from 'country-state-city';

const schemaGenerator = () =>
    yup.object().shape({
        firstName: yup.string().required(),
        lastName: yup.string().required(),
        email: yup.string().email().required(),
        password: yup.string().min(8).required(),
        confirmPassword: yup
            .string()
            .oneOf([yup.ref('password')], 'Both passwords need to be the same')
            .required(),
        country: yup.object().required(),
        address: yup.object().when(['country'], {
            is: (country) => {
                if (country !== undefined) {
                    const getCitByCountry: any = City.getCitiesOfCountry(country.value.iso_code);
                    return getCitByCountry.length > 0;
                }
                return true;
            },
            then: yup.object().required(),
        }),
        terms: yup.bool().oneOf([true], 'terms must be accepted').required(),
        termsTransfer: yup
            .bool()
            .oneOf([true], 'terms must be accepted')
            .required(),
    });


interface Props {
    onRegistration: (user: User, password: string) => Promise<any>;
    setIsSubmitting?: (submitting: boolean) => void;
    processing?: boolean;
}

export const EducatorRegistrationForm: React.FC<Props> = ({
                                                              onRegistration,
                                                              setIsSubmitting,
                                                              processing,
                                                          }) => {
    const client = new ApiClient('/api');
    const createNotification = useCreateNotification();
    const countries = Country.getAllCountries();
    const allCountries: any = countries.map((country) => {
        return {
            label: country.name !== 'Palestinian Territory Occupied' ? country.name : 'Palestine',
            value: {
                country_name: country.name,
                iso_code: country.isoCode,
                latitude: country.latitude,
                longitude: country.longitude,
            },
        };
    });
    const [inputCityValue, setInputCityValue] = React.useState();

    const onSubmitHandler: FormikConfig<any>['onSubmit'] = async (
        values,
        {setSubmitting},
    ) => {
        try {

            setIsSubmitting && setIsSubmitting(true);

            const {firstName, lastName, confirmPassword, ...formValues} = values;

            const data = {
                name: `${firstName} ${lastName}`,
                ...formValues,
                address: JSON.stringify({ country: values.country, city: values.address }),
                additionalForm: {
                    educationType: formValues.educationType,
                    schoolName: formValues.schoolName,
                    schoolAddress: formValues.schoolAddress,
                    countryAdditional: formValues.countryAdditional,

                    languagePreferences: formValues.languagePreferences,
                    fundingType: formValues.fundingType,
                    schoolEnvironmentCategory: formValues.schoolEnvironmentCategory,
                    schoolGenderDistribution: formValues.schoolGenderDistribution,
                    fundedSchool: formValues.fundedSchool,
                }
            };

            // console.log(values);
            // console.log(data);

            const response = await client.post(`/educators`, data);

            console.log(response);

            setSubmitting(false);
            setIsSubmitting && setIsSubmitting(false);
            onRegistration(response.data, values.password);
        } catch (error: any) {
            setSubmitting(false);
            setIsSubmitting && setIsSubmitting(false);
            console.error('error', error.message);
            createNotification('Server error', error.message, 'danger', {
                cause: _.get(error, 'meta.details.message'),
            });
        }
    };

    const schema = schemaGenerator();

    return (
        <Formik
            validationSchema={schema}
            onSubmit={onSubmitHandler}
            initialValues={{
                firstName: '',
                lastName: '',
                email: '',
                password: '',
                confirmPassword: '',
                address: '',
                country: '',
                terms: false,
                termsTransfer: false,

                showPopup: true,

                cannotProvide: false,

                educationType: '',
                schoolName: '',
                schoolAddress: '',
                countryAdditional: '',

                languagePreferences: '',
                fundingType: '',
                schoolEnvironmentCategory: '',
                schoolGenderDistribution: '',
                fundedSchool: '',

            }}
        >
            {({
                  handleSubmit,
                  handleChange,
                  values,
                  touched,
                  errors,
                  isSubmitting,
                  setFieldValue,
              }) => {
                return (
                    (
                        <Form noValidate onSubmit={handleSubmit}>
                            <Form.Row>
                                <Form.Group as={Col} controlId="validationFormikFirstName">
                                    <Form.Label>First Name</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="firstName"
                                        onChange={handleChange}
                                        isInvalid={touched.firstName && !!errors.firstName}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {errors.firstName}
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Form.Row>
                            <Form.Row>
                                <Form.Group as={Col} controlId="validationFormikLastName">
                                    <Form.Label>Last Name</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="lastName"
                                        onChange={handleChange}
                                        isInvalid={touched.lastName && !!errors.lastName}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {errors.lastName}
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Form.Row>
                            <Form.Row>
                                <Form.Group as={Col} controlId="validationFormikEmail">
                                    <Form.Label>Email</Form.Label>
                                    <Form.Control
                                        type="email"
                                        aria-describedby="inputGroupPrepend"
                                        name="email"
                                        onChange={handleChange}
                                        isInvalid={touched.email && !!errors.email}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {errors.email}
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Form.Row>
                            <Form.Row>
                                <Form.Group as={Col} controlId="validationFormikPassword">
                                    <Form.Label>Password</Form.Label>
                                    <Form.Control
                                        type="password"
                                        aria-describedby="inputGroupPrepend"
                                        name="password"
                                        onChange={handleChange}
                                        isInvalid={touched.password && !!errors.password}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {errors.password}
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Form.Row>
                            <Form.Row>
                                <Form.Group as={Col} controlId="validationFormikConfirmPassword">
                                    <Form.Label>Confirm Password</Form.Label>
                                    <Form.Control
                                        type="password"
                                        aria-describedby="inputGroupPrepend"
                                        name="confirmPassword"
                                        onChange={handleChange}
                                        isInvalid={touched.confirmPassword && !!errors.confirmPassword}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {errors.confirmPassword}
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Form.Row>
                            <Form.Row>
                                <Form.Group as={Col} controlId="validationFormikCountry">
                                    <Form.Label>Country</Form.Label>
                                    <CountryFieldSelect
                                        name="country"
                                        options={allCountries}
                                        setFieldValue={setFieldValue}
                                        value={values.country}
                                        setInputCityValue={setInputCityValue}
                                        isInvalid={touched.country && !!errors.country}
                                        placeholder=""
                                        className="text-dark"
                                    />
                                    <Form.Control.Feedback type="invalid" className={touched.country && !!errors.country ? ' d-block' : ''}>
                                        {errors.country}
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Form.Row>
                            <Form.Row>
                                <Form.Group as={Col} controlId="validationFormikAddress">
                                    <Form.Label>Location</Form.Label>
                                    <CityFieldSelect
                                        name="address"
                                        options={inputCityValue}
                                        setFieldValue={setFieldValue}
                                        value={values.address}
                                        setInputCityValue={setInputCityValue}
                                        isInvalid={touched.address && !!errors.address}
                                        placeholder=""
                                        className="text-dark"
                                    />
                                    <Form.Control.Feedback type="invalid" className={touched.address && !!errors.address ? ' d-block' : ''}>
                                        {errors.address}
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Form.Row>

                            <Form.Row>
                                <Form.Group>
                                    <Form.Check id="validationFormikTerms">
                                        <Form.Check.Input
                                            required
                                            name="terms"
                                            type="checkbox"
                                            isInvalid={touched.terms && !!errors.terms}
                                            onChange={handleChange}
                                        />
                                        <Form.Check.Label>
                                            I agree to Global Nomads{' '}
                                            <a
                                                href="https://gng.org/privacy/"
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    popupCenter({
                                                        url: 'https://gng.org/privacy/',
                                                        title: "GNG's Privacy Policy",
                                                        w: 900,
                                                        h: 500,
                                                    });
                                                    return false;
                                                }}
                                            >
                                                Privacy Policy
                                            </a>{' '}
                                            and{' '}
                                            <a
                                                href="https://content.gng.org/student-to-world/terms-and-conditions"
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    popupCenter({
                                                        url: 'https://content.gng.org/student-to-world/terms-and-conditions',
                                                        title: 'Student to World Terms and Conditions',
                                                        w: 900,
                                                        h: 500,
                                                    });
                                                    return false;
                                                }}
                                            >
                                                Terms of Service
                                            </a>
                                            .
                                        </Form.Check.Label>
                                        <Form.Control.Feedback type="invalid">
                                            {errors.terms}
                                        </Form.Control.Feedback>
                                    </Form.Check>
                                </Form.Group>
                            </Form.Row>
                            <Form.Row>
                                <Form.Group>
                                    <Form.Check id="validationFormikTermsTransfer">
                                        <Form.Check.Input
                                            required
                                            name="termsTransfer"
                                            type="checkbox"
                                            isInvalid={touched.termsTransfer && !!errors.termsTransfer}
                                            onChange={handleChange}
                                        />
                                        <Form.Check.Label>
                                            I agree Global Nomads can transfer information generated by my
                                            class's use of Global Nomads to the US, for the purpose of
                                            providing us with the service.
                                        </Form.Check.Label>
                                        <Form.Control.Feedback type="invalid">
                                            {errors.termsTransfer}
                                        </Form.Control.Feedback>
                                    </Form.Check>
                                </Form.Group>
                            </Form.Row>
                            <BusyButton type="submit" busy={isSubmitting || processing}>
                                Sign up
                            </BusyButton>
                        </Form>
                    )
                )
            }
            }
        </Formik>
    );
};
