import * as React from 'react';
import * as _ from 'lodash';
import { Player } from '@lottiefiles/react-lottie-player';
import { Button, Card } from 'react-bootstrap';
import 'survey-core/defaultV2.min.css';
import { StylesManager, Model } from 'survey-core';
import { Survey } from 'survey-react-ui';

import { Route } from '../Route';
import { ApiClient } from '../../util/ApiClient';
import { useCreateNotification } from '../hooks/useCreateNotification';
import { UsernameVerificationRegistration } from '../components/UsernameVerificationRegistration';

export const surveyRoute = new Route<{ id: string }>(
  '/sections/:id/survey',
  ({ match }) => {
    const client = new ApiClient('/api');
    const createNotification = useCreateNotification();

    StylesManager.applyTheme('defaultV2');
    const [survey, setSurvey] = React.useState<Model>();
    const [answerId, setAnswerId] = React.useState<string>();
    const [surveyCompleted, setSurveyCompleted] = React.useState(false);
    const [processing, setProcessing] = React.useState(false);

    React.useEffect(() => {
      document.title = 'Global Nomads Group - survey';
    }, []);

    const handleOnVerification = async (usersIds: number[]) => {
      try {
        console.log('usersIds', usersIds);
        if (!usersIds.length) {
          throw new Error('No user id.');
        }

        setProcessing(true);
        const response = await client.get(
          `/sections/${match.params.id}/students/${usersIds[0]}/survey`,
        );
        console.log('response', response);
        setSurvey(new Model(JSON.parse(response.data.survey.json)));
        setAnswerId(response.data.answerId);
        setProcessing(false);
      } catch (error: any) {
        setProcessing(false);
        console.error('error', error.message);
        createNotification('Server error', error.message, 'danger', {
          cause: _.get(error, 'meta.details.message'),
        });
      }
    };

    const setSurveyLocale = (event: any) => {
      console.log(event);
      if (survey) {
        survey.locale = event.target.value;
      }
    };

    const handleCompleteSurvey = async (result: any) => {
      await client.post(`/answers/${answerId}/sections/${match.params.id}`, {
        value: JSON.stringify(result.data),
      });
      setSurveyCompleted(true);
    };

    if (survey || surveyCompleted) {
      return (
        <div className="vh-100 d-flex flex-row">
          <div className="container">
            <div className="justify-content-center row">
              <div className="col-lg-8">
                <Card className="border-0">
                  <Card.Body>
                    {surveyCompleted ? (
                      <>
                        <Player
                          autoplay
                          keepLastFrame={true}
                          src="https://assets1.lottiefiles.com/temporary_files/i5pVEt.json"
                          speed={0.5}
                          style={{ height: '200px' }}
                        />
                        <h4 className="text-center">Thanks!</h4>
                        <p className="text-center mt-5">
                          I’m sharing a device with others <br />
                          <Button
                            variant="link"
                            className="p-0"
                            onClick={() => window.location.reload()}
                          >
                            submit another
                          </Button>
                        </p>
                      </>
                    ) : (
                      <>
                        <div className="text-right">
                          <select onChange={setSurveyLocale}>
                            <option value="">English</option>
                            <option value="ar">العربية</option>
                            <option value="es">Español</option>
                          </select>
                        </div>
                        <Survey
                          model={survey}
                          onComplete={handleCompleteSurvey}
                        />
                      </>
                    )}
                  </Card.Body>
                </Card>
              </div>
            </div>
          </div>
        </div>
      );
    }

    return (
      <UsernameVerificationRegistration
        onVerification={handleOnVerification}
        processing={processing}
      />
    );
  },
);
