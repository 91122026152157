import ReactDOM from 'react-dom';
import firebase from 'firebase/app';
import 'firebase/remote-config';
import 'firebase/analytics/';
import { App } from './frontend/App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import './frontend/styles/index.scss';
//TODO change this on redstone
const FIREBASE_CONFIG = process.env.REACT_APP_FIREBASE_CONFIG ?? ''
export const firebaseConfig = JSON.parse(FIREBASE_CONFIG);

firebase.initializeApp(firebaseConfig);
const remoteConfig = firebase.remoteConfig();
remoteConfig.defaultConfig = {
  educatorResources: JSON.stringify({
    community: 'https://facilitatorcommunity.gng.org/',
    faqs: 'https://gng.org/topics/',
    support: 'https://gng.org/#contact',
  }),
  topics: JSON.stringify([]),
};
remoteConfig.fetchAndActivate();
export { remoteConfig };

if (process.env.NODE_ENV === 'production') {
  firebase.analytics();
}

ReactDOM.render(<App />, document.getElementById('root'));
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// serviceWorkerRegistration.register({
//   onUpdate: () => {
//     const bc = new BroadcastChannel('app-update');
//     bc.postMessage('App has updated.');
//   },
// });

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
