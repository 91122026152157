import * as React from 'react';
import { Class } from '../../model/entities/Class';
import { useApiQuery } from '../hooks/useApiQuery';
import { User, UserRole } from '../../model/entities/User';
import { useBoolean } from '../hooks/useBoolean';
import { useUser } from '../hooks/useUser';
import { ChooseAdminModal } from '../modals/ChooseAdminModal';
import { useAsyncCallback } from '../hooks/useAsyncCallback';
import { useApiClient } from '../hooks/useApiClient';
import { useTrackingCode } from 'react-hubspot-tracking-code-hook';
import styles from '../styles/ClassEducatorsSection.module.scss';
import { UserCard } from '../components/UserCard';
import SkeletonItem from '../components/SkeletonItem';
import { QButton } from '../components/QButton';
import AddUserIcon from '../images/user.png';

export interface ClassEducatorsSectionProps {
  currentClass: Class;
}

export const ClassEducatorsSection: React.FunctionComponent<
  ClassEducatorsSectionProps
> = ({ currentClass }) => {
  const users = useApiQuery<User[]>(
    `/classes/${currentClass.id}/educators`,
    [],
    { observeUrl: '/users' },
  );

  const addModalVisible = useBoolean();
  const user = useUser();
  const client = useApiClient();

  const addUsers = useAsyncCallback(
    async (newUsers: User[]) => {
      for (const user of newUsers) {
        if (user.role) {
          await client.patch(`/users/${user.id}`, {
            classes: [...user.classes, currentClass.id],
          });
        }
      }

      users.reload();
      addModalVisible.setFalse();
    },
    [users.reload, addModalVisible, client],
  );
  const { setPathPageView, setIdentity } = useTrackingCode();
  React.useEffect(() => {
    if (currentClass?.id && user?.email) {
      setPathPageView(`/classes/${currentClass.id}/users`);
      setIdentity(user.email);
    }
  }, [user, currentClass, setPathPageView, setIdentity]);

  return (
    <>
      <div className={styles.wrapper}>
        {users.loading ? (
          <SkeletonItem count={1} width={1045} height={40} />
        ) : (
          <div className={styles.info}>
            <div>
              <h3>{currentClass.name}</h3>
              <p>
                School name:{' '}
                <b>{users.data && users.data[0]?.additionalForm?.schoolName}</b>{' '}
                &#x2022; Class ID:
                <b>{currentClass.id}</b>
              </p>
            </div>
            <div className={styles.btnWrapper}>
              {user.role === UserRole.Admin && (
                <QButton
                  variant="outline-danger"
                  onClick={addModalVisible.setTrue}
                >
                  Add educator <img src={AddUserIcon} alt="add-user-icon" />
                </QButton>
              )}
            </div>
          </div>
        )}

        <div className={styles.userCardWrapper}>
          {users.loading ? (
            <SkeletonItem count={1} width={1045} height={100} />
          ) : (
            users.data.map((user, key) => (
              <UserCard
                key={key}
                fullName={`${user.first_name} ${user.last_name}`}
                email={user.email}
              />
            ))
          )}
        </div>
        {addModalVisible.state && (
          <ChooseAdminModal
            title="Add user"
            action="Add"
            onHide={addModalVisible.setFalse}
            onChoose={addUsers.call}
            busy={addUsers.busy}
            existingUsers={users.data}
            show
          />
        )}
      </div>
    </>
  );
};
