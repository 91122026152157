import * as React from 'react';
// import parse from 'react-html-parser';
import { Modal } from 'react-bootstrap';
import { format } from 'date-fns';
import { ModalProps } from './ModalProps';
import { useApiQuery } from '../hooks/useApiQuery';
import { normaliseStatus } from '../featureToggles';
import BackButton from '../components/BackButton';
import DefaultUserAvatar from '../components/DefaultUserAvatar';
import PreviewUserAvatar from '../components/PreviewUserAvatar';
import SkeletonItem from '../components/SkeletonItem';
import styles from '../styles/SingleStoryModal.module.scss';

export interface SingleStoryModalProps extends ModalProps {
  show: boolean;
  content: any;
  statusFromUserStories: string;
}

export const StoryModal: React.FunctionComponent<SingleStoryModalProps> = ({
  show,
  onHide,
  content,
  statusFromUserStories,
}) => {
  const story = useApiQuery<any>(`/stories/${content.id}`);
  const status = story.data?.story?.storyStatus || statusFromUserStories;

  return (
    <Modal
      backdrop
      show={show}
      onHide={onHide}
      size="xl"
      contentClassName="border-radius"
    >
      {status === 'approved' ? (
        <div>
          <header className={styles.headerClasses}>
            <BackButton navigate={onHide} />
            <h1 className={styles.titleClasses}>
              {story.data?.story?.title || (
                <SkeletonItem count={1} width={100} />
              )}
            </h1>
          </header>
          <div>
            {/* <h2 className={styles.title}>{story.data?.story?.title}</h2> */}
            <div className={styles.story_heading}>
              <div className={styles.story_meta}>
                {false && (
                  <>
                    <div className={styles.author_info}>
                      {content?.account_customization_setting ? (
                        <PreviewUserAvatar
                          customStyles={styles.author_avatar}
                          userValues={
                            story.data?.story?.account_customization_setting
                          }
                        />
                      ) : (
                        <DefaultUserAvatar
                          customStyles={styles.author_avatar}
                        />
                      )}
                      <p className={styles.author_name}>
                        {story.data?.story?.fullName}
                      </p>
                    </div>
                  </>
                )}

                <div className={styles.story_data}>
                  {story.data?.story?.documentInStages &&
                    format(
                      new Date(normaliseStatus(story.data?.story)),
                      'MMM dd, yyyy',
                    )}
                </div>
              </div>
              <div className={styles.tags}>
                {story.data?.story?.tags?.map((tag: string, index: number) => (
                  <span key={index}>#{tag}</span>
                ))}
              </div>
            </div>

            {
              /* eslint-disable */
              // story.data?.story?.storyMediaStack?.filter(
              //   (mediaItem: any) => mediaItem.type === 'video',
              // )?.length !== 0 && (
              //   <div className={styles.video_wrap}>
              //     <video
              //       controls
              //       width={'100%'}
              //       height={'auto'}
              //       className={styles.video}
              //     >
              //       <source
              //         type="video/mp4"
              //         src={
              //           story.data?.story?.storyMediaStack?.filter(
              //             (mediaItem: any) => mediaItem.type === 'video',
              //           )[0]?.media?.url
              //         }
              //       />
              //     </video>
              //   </div>
              // )
              /* eslint-enable */
            }

            {story.data?.story?.storyMediaStack?.filter(
              (item: any) => item.cover && item.type === 'image',
            )?.length > 0 && (
              <>
                <img
                  key={
                    story.data?.story?.storyMediaStack?.filter(
                      (item: any) => item.cover,
                    )[0]?.id
                  }
                  src={
                    story.data?.story?.storyMediaStack?.filter(
                      (item: any) => item.cover,
                    )[0]?.media?.url
                  }
                  className={styles.cover_img}
                  alt=""
                />
                <p className={styles.cover_img_caption}>
                  {
                    story.data?.story?.storyMediaStack?.filter(
                      (item: any) => item.cover,
                    )[0]?.caption
                  }
                </p>
              </>
            )}
            <p className={styles.text}>{story.data?.story?.description}</p>
            {/* <div className={styles.gallery}>{storyMediaStackContent}</div> */}
          </div>
        </div>
      ) : (
        <div>
          <header className={styles.headerClasses}>
            <BackButton navigate={onHide} />
            <h1 className={styles.titleClasses}>Story</h1>
          </header>
          <div>
            <div className={styles.story_heading_not_approved}>
              <div className={styles.story_meta}>
                <p>
                  This story is <span>{status}</span> and will be available when
                  approved.
                </p>
              </div>
            </div>
          </div>
        </div>
      )}
    </Modal>
  );
};
