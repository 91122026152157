import * as React from 'react';
import { User } from '../../model/entities/User';
import { components, Async as AsyncSelect } from 'react-select';
import { Props } from '../util/Props';
import { FieldOption } from '../util/FieldOption';
import { ListItemRow } from './ListItem';
import { Badge } from 'react-bootstrap';
import { useUser } from '../hooks/useUser';
import * as _ from 'lodash';
import { useApiClient } from '../hooks/useApiClient';
import { debouncePromise } from '../util/debouncePromise';

export type SelectProps = Props<AsyncSelect<FieldOption>>;

export interface UserSearchProps {
  value?: User | null;
  onChange?(value?: string): void;
  onChangeUser?(user?: User): void;
  isClearable?: boolean;
  disabledUsers?: User[];
  includeCanvas?: boolean;
  busy?: boolean;
}

export const UserSearch: React.FunctionComponent<UserSearchProps> = ({
  value,
  onChange,
  onChangeUser,
  isClearable,
  disabledUsers = [],
  includeCanvas,
  busy,
}) => {
  const client = useApiClient();
  const user = useUser();

  const searchUsers = React.useMemo(
    () =>
      debouncePromise(async (query: string) => {
        const { data } = await client.get<{ data: User[] }>(
          `/users?includeCanvas=${includeCanvas ||
            false}&query=${encodeURIComponent(query)}`,
        );

        return _.orderBy(
          data
            .map(x => ({
              ...x,
              you: x.id === user.id,
              canvas: !x.role && !x.accessRequested,
            }))
            .filter(x => !disabledUsers.find(user => user.id === x.id)),
          ['you', 'canvas', 'name'],
          ['desc', 'asc', 'asc'],
        );
      }, 300),
    [client, disabledUsers, includeCanvas, user.id],
  );

  return (
    <AsyncSelect
      cacheOptions
      loadOptions={searchUsers}
      getOptionValue={x => x.id!}
      getOptionLabel={x => x.name}
      onChange={(user: any) => {
        if (Array.isArray(user)) return;
        if (onChange) {
          onChange(user ? user.id! : undefined);
        }
        if (onChangeUser) {
          onChangeUser(user || undefined);
        }
      }}
      placeholder="Search users"
      components={{ Option: UserOption }}
      isClearable={isClearable}
      value={value}
      isLoading={busy}
    />
  );
};

const UserOption: React.StatelessComponent<
  SelectProps['components']['Option']
> = props => {
  return (
    <components.Option {...props}>
      <ListItemRow
        title={props.data.name}
        meta={props.data.loginId}
        right={
          props.data.you ? (
            <Badge pill variant="primary">
              You
            </Badge>
          ) : props.data.canvas ? (
            <Badge pill variant="light">
              From Canvas
            </Badge>
          ) : (
            undefined
          )
        }
      />
    </components.Option>
  );
};
