import * as React from 'react';
import * as _ from 'lodash';
import {Select} from "../Select";

const languagesData = require('countries-list/dist/languages.all.min.json');

const languages = _.orderBy(
    _.map(languagesData, ({name, native}, value) => ({
        value,
        label: `${name} (${native})`,
    })),
    language => language.label,
);

export interface LanguageFieldProps {
    isMulti: boolean;
    name: string;
    value?: string;
    placeholder?: string;
    className?: string;
    onChange: (value: string) => void;
    isInvalid?: boolean;
}

export const LanguagesFormField: React.FunctionComponent<LanguageFieldProps> = ({
    isMulti,
    name,
    value,
    placeholder,
    className,
    onChange,
    isInvalid,
}) => {
    return (
        <Select
            isMulti={isMulti}
            name={name}
            options={languages}
            value={languages.find(x => x.value === value)}
            placeholder={placeholder}
            onChange={(value: any) => onChange(value ? value : null)}
            className={className}
            isInvalid={isInvalid}
        />
    );
};
