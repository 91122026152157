import * as React from 'react';
import cs from 'classnames';
import { useDragAndDrop } from '../hooks/useDragAndDrop';

const styles = require('../styles/Dropzone.module.scss');

export interface DropzoneProps {
  onDrop: (file: File) => void;
  placeholder?: React.ReactNode;
}

export const Dropzone: React.FunctionComponent<DropzoneProps> = ({
  onDrop,
  placeholder,
}) => {
  const { dropzoneRef, activated } = useDragAndDrop<HTMLDivElement>(data => {
    if (!data) return;
    if (data.files.length > 1) return;
    onDrop(data.files[0]);
  });

  const onChange = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (!e.target || !e.target.files) return;
      onDrop(e.target.files[0]);
    },
    [onDrop],
  );

  const onClick = React.useCallback(() => {
    if (inputRef.current) inputRef.current.click();
  }, []);

  const inputRef = React.useRef<HTMLInputElement>(null);

  return (
    <div
      className={cs(styles.container, { [styles.activated]: activated })}
      onClick={onClick}
      ref={dropzoneRef}
    >
      <div className={styles.placeholder}>
        {placeholder || 'Drop a file or click to select one from your device'}
      </div>

      <input
        type="file"
        ref={inputRef}
        className={styles.input}
        onChange={onChange}
      />
    </div>
  );
};
