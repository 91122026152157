import * as React from 'react';
import { Form } from 'react-bootstrap';
import { FieldOptionOrValue } from '../util/FieldOption';

export interface RadioGroupProps {
  name?: string;
  inline?: boolean;
  options: FieldOptionOrValue[];
  value?: any;
  onChange?: (value: any) => void;
}

export const RadioGroup: React.StatelessComponent<RadioGroupProps> = ({
  name,
  inline,
  options,
  value,
  onChange,
}) => (
  <div>
    {options.map(item => {
      const itemValue = typeof item === 'string' ? item : item.value;
      const itemText =
        typeof item === 'string' ? item : item.label || item.value;

      return (
        <Form.Check
          key={itemValue}
          name={name}
          value={itemValue}
          inline={inline}
          label={itemText}
          type="radio"
          id={`option_${itemValue}`}
          checked={value === itemValue}
          onChange={onChange}
        />
      );
    })}
  </div>
);
