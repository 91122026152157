import * as React from 'react';
import { Button, Col, Form, Modal, FormControl } from 'react-bootstrap';
import { FormGroup } from '../FormGroup';
import { Select } from '../Select';
import { useUser } from '../../hooks/useUser';
import { Formik, FormikConfig } from 'formik';
import { setUserInfoInterface } from '../Dashboard';
import { LanguagesFormField } from './LanguagesFormField';
import * as yup from 'yup';
import _ from 'lodash';
import { useCreateNotification } from '../../hooks/useCreateNotification';
import { useApiClient } from '../../hooks/useApiClient';
import { useTrackingCode } from 'react-hubspot-tracking-code-hook';

type StepTwoProps = {
  userInfo: setUserInfoInterface;
  openModalAddClass: Function;
};

const fundingTypeOption = [
  { value: 'Public', label: 'Public' },
  { value: 'Private', label: 'Private' },
  { value: 'Charter', label: 'Charter' },
  { value: 'Nonprofit', label: 'Nonprofit' },
];
const schoolEnvironmentCategoryOption = [
  { value: 'Urban', label: 'Urban' },
  { value: 'Suburban', label: 'Suburban' },
  { value: 'Rural', label: 'Rural' },
];
const fundedSchoolOption = [
  { value: 'No Title I Funding', label: 'No Title I Funding' },
  { value: 'Targeted Assistance', label: 'Targeted Assistance' },
  { value: 'School Wide Assistance', label: 'School Wide Assistance' },
];
const schoolGenderDistributionOption = [
  { value: 'Single gender', label: 'Single gender' },
  { value: 'Co-educational', label: 'Co-educational' },
];

const studentCategoryOptions = [
  { value: 'Privileged', label: 'Privileged' },
  { value: 'Disadvantaged', label: 'Disadvantaged' },
  { value: 'Neither', label: 'Neither' },
  { value: 'Both', label: 'Both' },
];

const MENA_COUNTRIES = [
  'Algeria',
  'Bahrain',
  'Egypt',
  'Iran',
  'Iraq',
  'Israel',
  'Jordan',
  'Kuwait',
  'Lebanon',
  'Libya',
  'Morocco',
  'Oman',
  'Qatar',
  'Saudi Arabia',
  'Syria',
  'Tunisia',
  'United Arab Emirates',
  'Yemen',
];

export const StepTwo: React.FunctionComponent<StepTwoProps> = ({
  userInfo,
  openModalAddClass,
}) => {
  const user = useUser();
  const { setTrackEvent } = useTrackingCode();
  const client = useApiClient();

  const createNotification = useCreateNotification();

  const isUsSchool = userInfo.countryAdditional.includes('United States');
  const isMenaSchool = MENA_COUNTRIES.some(country => userInfo.countryAdditional.includes(country));

  const [showModal, setShowModal] = React.useState(true);

  const onSubmitHandler: FormikConfig<any>['onSubmit'] = async (
    values,
    { setSubmitting },
  ) => {
    console.log('onSubmitHandler 2');
    // console.log(values);

    setSubmitting(false);
    setShowModal(false);

    let languageList = values?.languagePreferences?.map((lang: any) => {
      return lang.label;
    });

    sendDataToFirebase({
      additionalForm: {
        ...userInfo,
        fundingType: values?.fundingTypeValue?.label,
        schoolEnvironmentCategory:
          values?.schoolEnvironmentCategoryValue?.label,
        schoolGenderDistribution:
          values?.schoolGenderDistributionOptionValue?.label,
        fundedSchool: values?.fundedSchoolValue?.label || '',
        languagePreferences: languageList?.join() || '',
        bipoc: values?.bipoc?.label,
      },
      showPopup: false,
    });
    setTrackEvent({
      eventId: 'Added School info step two.',
      value: user.email,
    });
    console.log('Added School info step one.', user.email);
  };

  const closeSecondModal = async () => {
    setShowModal(false);
    try {
      const data = {
        additionalForm: { ...userInfo },
        cannotProvide: true,
        showPopup: false,
      };
      await client.patch(`/users/${user.id}`, data);
    } catch (error: any) {
      console.error('error', error.message);
      createNotification('Server error', error.message, 'danger', {
        cause: _.get(error, 'meta.details.message'),
      });
    }
    setTrackEvent({
      eventId: 'Added School info step one. Empty',
      value: user.email,
    });
    console.log('Added School info step one. Empty', user.email);
    openModalAddClass();
  };

  const sendDataToFirebase = async (data: any) => {
    try {
      const response = await client.patch(`/users/${user.id}`, data);
      console.log(response);
    } catch (error: any) {
      console.error('error', error.message);
      createNotification('Server error', error.message, 'danger', {
        cause: _.get(error, 'meta.details.message'),
      });
    }
    openModalAddClass();
  };

  const schemaGenerator = () => {
    if (isUsSchool) {
      return yup.object().shape({
        languagePreferences: yup.array().min(1, 'Required'),
        fundingTypeValue: yup.object().required('Required').nullable(),
        schoolEnvironmentCategoryValue: yup
          .object()
          .required('Required')
          .nullable(),
        schoolGenderDistributionOptionValue: yup
          .object()
          .required('Required')
          .nullable(),
        fundedSchoolValue: yup.object().required('Required').nullable(),
        bipoc: yup.string().required('Required'),
      });
    }
    return yup.object().shape({
      languagePreferences: yup.array().min(1, 'Required'),
      fundingTypeValue: yup.object().required('Required').nullable(),
      schoolEnvironmentCategoryValue: yup
        .object()
        .required('Required')
        .nullable(),
      schoolGenderDistributionOptionValue: yup
        .object()
        .required('Required')
        .nullable(),
    });
  };

  const schema = schemaGenerator();

  return (
    <Modal
      backdrop
      show={showModal}
      size="lg"
      contentClassName="p-5 border-radius"
    >
      <Formik
        initialValues={{
          languagePreferences: [],
          fundingTypeValue: null,
          schoolEnvironmentCategoryValue: null,
          schoolGenderDistributionOptionValue: null,
          fundedSchoolValue: null,
          studentCategoryEconomicsValue: null,
          studentCategoryRaceValue: null,
          studentCategoryReligionValue: null,
          bipoc: '',
        }}
        validationSchema={schema}
        onSubmit={onSubmitHandler}
      >
        {({
          handleSubmit,
          handleChange,
          values,
          touched,
          errors,
          setFieldValue,
          handleBlur,
        }) => {
          // console.log(errors);
          // console.log(values);
          return (
            <Form noValidate onSubmit={handleSubmit}>
              <Modal.Header className={'modal-header-custom'}>
                <Modal.Title>Learning about your organization</Modal.Title>
              </Modal.Header>

              <Modal.Body className={'modal-body-custom'}>
                <p>
                Due to generous funding from our donors, we are able to provide our online courses free of charge. We do however need to prove the impact of our programs to those important stakeholders, so we need to request some additional information from. If you cannot provide this information at this time, select the option below and we’ll follow up with you later.
                </p>

                <Form.Row>
                  <FormGroup as={Col} label="Funding Type">
                    <Select
                      placeholder="How is your Organization funded?"
                      name="fundingTypeValue"
                      options={fundingTypeOption}
                      getOptionValue={(x) => x.value}
                      getOptionLabel={(x) => x.label}
                      onChange={(value) =>
                        setFieldValue('fundingTypeValue', value)
                      }
                      value={values.fundingTypeValue}
                      isInvalid={
                        touched.fundingTypeValue && !!errors.fundingTypeValue
                      }
                      onBlur={handleBlur('fundingTypeValue')}
                      className={errors.fundingTypeValue ? ' is-invalid' : ''}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.fundingTypeValue}
                    </Form.Control.Feedback>
                  </FormGroup>
                </Form.Row>

                <Form.Row>
                  <FormGroup as={Col} label="Community environment">
                    <Select
                      placeholder={'Community environment'}
                      name="schoolEnvironmentCategoryValue"
                      options={schoolEnvironmentCategoryOption}
                      getOptionValue={(x) => x.value}
                      getOptionLabel={(x) => x.label}
                      onChange={(value) =>
                        setFieldValue('schoolEnvironmentCategoryValue', value)
                      }
                      value={values.schoolEnvironmentCategoryValue}
                      isInvalid={
                        touched.schoolEnvironmentCategoryValue &&
                        !!errors.schoolEnvironmentCategoryValue
                      }
                      onBlur={handleBlur('schoolEnvironmentCategoryValue')}
                      className={
                        errors.schoolEnvironmentCategoryValue
                          ? ' is-invalid'
                          : ''
                      }
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.schoolEnvironmentCategoryValue}
                    </Form.Control.Feedback>
                  </FormGroup>
                </Form.Row>

                <Form.Row>
                  <FormGroup as={Col} label="Gender distribution">
                    <Select
                      placeholder="Single gender or co-educational?"
                      name="schoolGenderDistributionOptionValue"
                      options={schoolGenderDistributionOption}
                      getOptionValue={(x) => x.value}
                      getOptionLabel={(x) => x.label}
                      onChange={(value) =>
                        setFieldValue(
                          'schoolGenderDistributionOptionValue',
                          value,
                        )
                      }
                      value={values.schoolGenderDistributionOptionValue}
                      isInvalid={
                        touched.schoolGenderDistributionOptionValue &&
                        !!errors.schoolGenderDistributionOptionValue
                      }
                      onBlur={handleBlur('schoolGenderDistributionOptionValue')}
                      className={
                        errors.schoolGenderDistributionOptionValue
                          ? ' is-invalid'
                          : ''
                      }
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.schoolGenderDistributionOptionValue}
                    </Form.Control.Feedback>
                  </FormGroup>
                </Form.Row>

                {isUsSchool && (
                  <React.Fragment>
                    <Form.Row>
                      <FormGroup
                        as={Col}
                        label="Are you a Title 1 funded School?"
                      >
                        <Select
                          placeholder="Are you a Title 1 funded School?"
                          name="fundedSchoolValue"
                          options={fundedSchoolOption}
                          getOptionValue={(x) => x.value}
                          getOptionLabel={(x) => x.label}
                          onChange={(value) =>
                            setFieldValue('fundedSchoolValue', value)
                          }
                          value={values.fundedSchoolValue}
                          isInvalid={
                            touched.fundedSchoolValue &&
                            !!errors.fundedSchoolValue
                          }
                          onBlur={handleBlur('fundedSchoolValue')}
                          className={
                            errors.fundedSchoolValue ? ' is-invalid' : ''
                          }
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.fundedSchoolValue}
                        </Form.Control.Feedback>
                      </FormGroup>
                    </Form.Row>
                    <Form.Row>
                      <FormGroup as={Col} label="BIPOC(%)">
                        <FormControl
                          placeholder="BIPOC(%)"
                          name="bipoc"
                          type="number"
                          min="0"
                          max="100"
                          value={values.bipoc}
                          onChange={handleChange}
                          isInvalid={touched.bipoc && !!errors.bipoc}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.bipoc}
                        </Form.Control.Feedback>
                      </FormGroup>
                    </Form.Row>
                  </React.Fragment>
                )}

                {!isUsSchool && !isMenaSchool &&(
                  <React.Fragment>
                    <Form.Row>
                      <FormGroup
                        as={Col}
                        label="Based on economics, are the students in your school considered ?"
                      >
                        <Select
                          placeholder="Privileged, Disadvantaged, Neither, Both"
                          name="studentCategoryEconomicsValue"
                          options={studentCategoryOptions}
                          getOptionValue={(x) => x.value}
                          getOptionLabel={(x) => x.label}
                          onChange={(value) =>
                            setFieldValue(
                              'studentCategoryEconomicsValue',
                              value,
                            )
                          }
                          value={values.studentCategoryEconomicsValue}
                          isInvalid={
                            touched.studentCategoryEconomicsValue &&
                            !!errors.studentCategoryEconomicsValue
                          }
                          onBlur={handleBlur('studentCategoryEconomicsValue')}
                          className={
                            errors.studentCategoryEconomicsValue
                              ? ' is-invalid'
                              : ''
                          }
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.studentCategoryEconomicsValue}
                        </Form.Control.Feedback>
                      </FormGroup>
                    </Form.Row>
                    <Form.Row>
                      <FormGroup
                        as={Col}
                        label="Based on race are the students in your school considered ?"
                      >
                        <Select
                          placeholder="Privileged, Disadvantaged, Neither, Both"
                          name="studentCategoryRaceValue"
                          options={studentCategoryOptions}
                          getOptionValue={(x) => x.value}
                          getOptionLabel={(x) => x.label}
                          onChange={(value) =>
                            setFieldValue('studentCategoryRaceValue', value)
                          }
                          value={values.studentCategoryRaceValue}
                          isInvalid={
                            touched.studentCategoryRaceValue &&
                            !!errors.studentCategoryRaceValue
                          }
                          onBlur={handleBlur('studentCategoryRaceValue')}
                          className={
                            errors.studentCategoryRaceValue ? ' is-invalid' : ''
                          }
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.studentCategoryRaceValue}
                        </Form.Control.Feedback>
                      </FormGroup>
                    </Form.Row>
                    <Form.Row>
                      <FormGroup
                        as={Col}
                        label="Based on religion are the students in your school considered ?"
                      >
                        <Select
                          placeholder="Privileged, Disadvantaged, Neither, Both"
                          name="studentCategoryReligionValue"
                          options={studentCategoryOptions}
                          getOptionValue={(x) => x.value}
                          getOptionLabel={(x) => x.label}
                          onChange={(value) =>
                            setFieldValue('studentCategoryReligionValue', value)
                          }
                          value={values.studentCategoryReligionValue}
                          isInvalid={
                            touched.studentCategoryReligionValue &&
                            !!errors.studentCategoryReligionValue
                          }
                          onBlur={handleBlur('studentCategoryReligionValue')}
                          className={
                            errors.studentCategoryReligionValue
                              ? ' is-invalid'
                              : ''
                          }
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.studentCategoryReligionValue}
                        </Form.Control.Feedback>
                      </FormGroup>
                    </Form.Row>
                  </React.Fragment>
                )}

                <Form.Row>
                  <FormGroup as={Col} label="Language(s) of instruction">
                    <LanguagesFormField
                      isMulti
                      name="languagePreferences"
                      placeholder="Choose your language(s) of instruction"
                      // value={values.languagePreferences}
                      onChange={(value) => {
                        setFieldValue('languagePreferences', value);
                      }}
                      className={
                        errors.languagePreferences ? ' is-invalid' : ''
                      }
                      isInvalid={
                        touched.languagePreferences &&
                        !!errors.languagePreferences
                      }
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.languagePreferences}
                    </Form.Control.Feedback>
                  </FormGroup>
                </Form.Row>
              </Modal.Body>

              <Modal.Footer className={'modal-footer-custom'}>
                <div
                  className={'cannot-provide'}
                  onClick={() => {
                    closeSecondModal();
                  }}
                >
                  I cannot provide this information
                </div>

                <Button type="submit" variant="btn btn-primary">
                  Submit
                </Button>
              </Modal.Footer>
            </Form>
          );
        }}
      </Formik>
    </Modal>
  );
};
