import * as React from 'react';
import cs from 'classnames';

const styles = require('../styles/Toggle.module.scss');

export interface ToggleProps {
  value?: boolean;
  onChange?: (value: boolean) => void;
  className?: string;
  disabled?: boolean;
}

export const Toggle: React.FunctionComponent<ToggleProps> = ({
  value,
  onChange,
  children,
  className,
  disabled,
}) => {
  return (
    <div
      className={cs(styles.container, className, {
        [styles.disabled]: disabled,
      })}
      onClick={onChange && !disabled ? () => onChange(!value) : undefined}
    >
      <div className={cs(styles.slider, { [styles.toggled]: value })}>
        <div className={styles.knob} />
      </div>
      {children}
    </div>
  );
};
