import * as React from 'react';
import { Player } from '@lottiefiles/react-lottie-player';
import OtpInput from 'react-otp-input';
import { Route } from '../Route';
import _ from 'lodash';
import logo from '../images/gng-logo.png';
import { Class } from '../../model/entities/Class';
import { BusyButton } from '../components/Busy';
import { ApiClient } from '../../util/ApiClient';
import { useCreateNotification } from '../hooks/useCreateNotification';
import { RegistrationForm } from '../components/RegistrationForm';
import canvasLogo from '../images/canvas-logo.png';
import { Button } from 'react-bootstrap';
import { User } from '../../model/entities/User';

export const joinRoute = new Route<any>('/join/:token', ({ match }) => {
  const client = new ApiClient('/api');
  const [token, setToken] = React.useState('');
  const [currentClass, setCurrentClass] = React.useState<Class | null>(null);
  const [canvasUrl, setCanvasUrl] = React.useState('');
  const [loading, setLoading] = React.useState(false);
  const [user, setUser] = React.useState<User>();
  const createNotification = useCreateNotification();

  React.useEffect(() => {
    document.title = 'Global Nomads Group - Join';

    if (match.params.token && match.params.token !== 'null') {
      setToken(match.params.token);
    }
  }, [match.params.token]);

  const join = async () => {
    try {
      setLoading(true);
      const currentClass = await client.get(
        `/classes/self-registration/${token}`,
      );
      setCurrentClass(currentClass.data.class);
      setCanvasUrl(currentClass.data.canvasUrl);
      setLoading(false);
    } catch (err: any) {
      setLoading(false);
      createNotification('Information', err.message, 'info', {
        cause: _.get(err, 'meta.details.message'),
        hideAfter: 3000,
      });
    }
  };

  const handleOnRegistration = async (user: User) => {
    setUser(user);
  };

  return (
    <div className="vh-100 d-flex flex-row">
      <div className="container">
        <div className="row justify-content-center mt-5">
          <div className="col-lg-5 text-center">
            <h1 className="font-weight-bold">Join a class</h1>
            <img
              alt="Global Nomads Group Logo showing a circle surrounded by a crescent shape on two sides with the text Global Nomads Group surrounding it."
              src={logo}
              width={180}
            />
            <Player
              autoplay
              keepLastFrame={true}
              src="https://assets1.lottiefiles.com/packages/lf20_Sog6Ae.json"
              className="border-radius"
              style={{ width: '300px' }}
            />
          </div>
          <div className="col-lg-5">
            {!currentClass ? (
              <>
                <h4 className="font-weight-bold text-center mt-3">
                  Enter class code
                </h4>
                <OtpInput
                  value={token}
                  onChange={setToken}
                  numInputs={6}
                  inputStyle={{
                    width: '52px',
                    height: '56px',
                    margin: '0 0.3rem',
                    borderRadius: '17px',
                    border: '2px solid #E0E0E0',
                    fontSize: '30px',
                    fontFamily: "'Fira Mono', monospace",
                  }}
                  containerStyle={{
                    justifyContent: 'center',
                  }}
                />
                <div className="text-center">
                  <BusyButton onClick={join} className="mt-4" busy={loading}>
                    JOIN
                  </BusyButton>
                </div>
              </>
            ) : !user ? (
              <RegistrationForm
                currentClass={currentClass}
                onRegistration={handleOnRegistration}
                email={false}
              />
            ) : (
              <>
                <h3>Welcome</h3>
                <p>
                  You have successfully joined{' '}
                  <strong className="text-primary">{currentClass!.name}</strong>
                  .
                </p>
                <p>
                  Write down your Username and Password.
                  <br />
                  <strong>Username:</strong> {user.loginId}
                  <br />
                  <strong>Password:</strong> {user.initialPassword}
                </p>
                <img
                  src={canvasLogo}
                  alt="Canvas"
                  height={25}
                  className="mb-2"
                />

                <p className="mb-0">
                  We use <strong>Canvas</strong> for our online learning
                  platform.
                </p>
                <Button
                  href={canvasUrl}
                  target="_blank"
                  variant="primary"
                  className="mt-3"
                >
                  Log In
                </Button>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
});
