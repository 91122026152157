import * as React from 'react';
import { FieldArray, Formik, FormikConfig } from 'formik';
import { Button, Col, Form, InputGroup, Row } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faMinusCircle,
  faPlusCircle,
  faUserSlash,
} from '@fortawesome/free-solid-svg-icons';
import _ from 'lodash';
import { BusyButton } from './Busy';
import { ApiClient } from '../../util/ApiClient';
import { useCreateNotification } from '../hooks/useCreateNotification';

interface Props {
  onVerification: (usersIds: number[]) => Promise<any>;
  onShowRegistration?: () => void;
  addAnother?: boolean;
  allowRegistration?: boolean;
  processing?: boolean;
}

export const UsernameVerification: React.FC<Props> = ({
  onVerification,
  onShowRegistration,
  addAnother,
  allowRegistration = true,
  processing,
}) => {
  const client = new ApiClient('/api');
  const createNotification = useCreateNotification();

  const [usernamesVerification, setUsernamesVerification] = React.useState<{
    [key: string]: number | null | undefined;
  }>();

  const onSubmitHandler: FormikConfig<any>['onSubmit'] = async (
    values,
    { setSubmitting },
  ) => {
    const usernames = values.usernames.map((username: string) =>
      username.trim(),
    );
    console.log(usernames);
    try {
      if (
        usernames.filter((username: string) => username.length >= 4).length !==
        values.usernames.length
      ) {
        setUsernamesVerification({});
        return;
      }
      const response = await client.get(
        `/users/verify-usernames?usernames=${encodeURIComponent(
          usernames.join(','),
        )}`,
      );
      console.log('response', response);
      setUsernamesVerification(response.data);

      if (
        Object.keys(response.data).length ===
        Object.values(response.data).filter(
          (value) => typeof value === 'number',
        ).length
      ) {
        setSubmitting(false);
        onVerification(Object.values(response.data));
      }
    } catch (error: any) {
      setSubmitting(false);
      console.error('error', error.message);
      createNotification('Server error', error.message, 'danger', {
        cause: _.get(error, 'meta.details.message'),
      });
    }
  };

  return (
    <Formik
      onSubmit={onSubmitHandler}
      initialValues={{ usernames: [''] }}
      render={({ values, handleSubmit, handleChange, isSubmitting }) => (
        <Form noValidate onSubmit={handleSubmit}>
          <FieldArray
            name="usernames"
            render={(array) => (
              <React.Fragment>
                {values.usernames.map((value, i) => (
                  <Row
                    className={
                      'justify-content-center' + (i !== 0 ? ' mt-4' : '')
                    }
                    key={i}
                  >
                    <Col>
                      <Form.Label>
                        Log in with your Global Nomads username
                      </Form.Label>
                      <InputGroup>
                        <Form.Control
                          required
                          name={`usernames.${i}`}
                          onChange={handleChange}
                          isInvalid={
                            usernamesVerification &&
                            (!value ||
                              value.length < 4 ||
                              usernamesVerification[value] === null)
                          }
                        />

                        <Button
                          variant="highlight-0"
                          onClick={() =>
                            (i > 0 || values.usernames.length > 1) &&
                            array.remove(i)
                          }
                          className="text-white"
                        >
                          <FontAwesomeIcon
                            icon={faMinusCircle}
                            color={
                              i > 0 || values.usernames.length > 1
                                ? undefined
                                : 'transparent'
                            }
                          />
                        </Button>
                        {usernamesVerification && (
                          <Form.Control.Feedback type="invalid">
                            {!value || value.length < 4
                              ? 'the minimum length is 4'
                              : usernamesVerification[value] === null
                              ? 'that username doesn’t exist or is not an student, check again'
                              : ''}
                          </Form.Control.Feedback>
                        )}
                      </InputGroup>
                    </Col>
                  </Row>
                ))}
                {allowRegistration && (
                  <Row>
                    <Col>
                      <Button
                        variant="link"
                        className="p-0 text-white mt-3 mb-2"
                        onClick={onShowRegistration}
                      >
                        <FontAwesomeIcon icon={faUserSlash} className="mr-2" />I
                        don't have a Global Nomads username
                      </Button>
                    </Col>
                  </Row>
                )}
                {addAnother && (
                  <Row>
                    <Col>
                      <Button
                        variant="highlight-0"
                        onClick={() => array.push('')}
                        className="border-0 p-0 text-white mt-3"
                      >
                        <FontAwesomeIcon icon={faPlusCircle} className="mr-2" />
                        {values.usernames.length === 1
                          ? 'I’m sharing a device with others'
                          : 'Add another'}
                      </Button>
                    </Col>
                  </Row>
                )}
              </React.Fragment>
            )}
          />
          <Row className="mt-4">
            <Col>
              <BusyButton
                type="submit"
                variant="outline-secondary"
                className="pl-5 pr-5 float-right text-white border-white"
                style={{
                  marginRight: '48px',
                }}
                busy={isSubmitting || processing}
              >
                Submit
              </BusyButton>
            </Col>
          </Row>
        </Form>
      )}
    />
  );
};
