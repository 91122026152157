export function readFileAsString(file: File) {
  const reader = new FileReader();

  return new Promise<string>((resolve, reject) => {
    reader.onload = () => {
      resolve(reader.result as any);
    };

    reader.onerror = reject;

    reader.readAsText(file);
  });
}
