import { useState } from 'react';
import { useObserveResults } from './useObserveResults';
import { ObserveResultsOptions } from '../util/AuthenticatedApiClient';

export interface UseApiQueryResult<T> {
  data: T;
  loading: boolean;
  reload: () => void;
}

export function useApiQuery<T = any>(
  url: string,
): UseApiQueryResult<T | undefined>;
export function useApiQuery<T = any>(
  url: string,
  initialValue: T,
  options?: ObserveResultsOptions,
): UseApiQueryResult<T>;
export function useApiQuery(
  url: string,
  initialValue?: any,
  options: ObserveResultsOptions = {},
) {
  const [data, setData] = useState(initialValue);

  const { loading, reload } = useObserveResults<any>(
    url,
    result => {
      setData(result.data);
    },
    options,
  );

  return { data, loading, reload };
}
