import * as React from 'react';
import { useState } from 'react';
import { TopNavbar } from './TopNavbar';
import { classListRoute } from '../routes/classListRoute';
import { classDetailsRoute } from '../routes/classDetailsRoute';
import { courseDetailsRoute } from '../routes/courseDetailsRoute';
import { userStoriesRoute } from '../routes/userStoriesRoute';
import { userListRoute } from '../routes/userListRoute';
// import { reviewQueueRoute } from '../routes/reviewQueueRoute';
// import { surveyListRoute } from '../routes/surveyListRoute';
import { useRoutes } from '../hooks/useRoutes';
import { RouteSwitch } from './RouteSwitch';
// import { progressTrackerRoute } from '../routes/progressTrackerRoute';
// import { surveyDetailsRoute } from '../routes/surveyDetailsRoute';

import { Container } from 'react-bootstrap';
import { useUser } from '../hooks/useUser';
import { StepOne } from './AdditionalForm/StepOne';
import { StepTwo } from './AdditionalForm/StepTwo';
import { EditClassModal } from '../modals/EditClassModal';
import { useEditModalState } from '../hooks/useEditModalState';
import { Class } from '../../model/entities/Class';

export interface setUserInfoInterface {
  educationType: string;
  schoolName: string;
  schoolAddress: string;
  countryAdditional: string;

  languagePreferences: string;
  fundingType: string;
  schoolEnvironmentCategory: string;
  schoolGenderDistribution: string;
  fundedSchool: string;
}

export const Dashboard: React.FunctionComponent = () => {
  const filteredRoutes = useRoutes(routes as any);

  const modalState = useEditModalState<Class>();

  const user = useUser();

  const [currentStep, setCurrentStep] = React.useState(1);

  const [userInfo, setUserInfo] = useState<setUserInfoInterface>({
    educationType: '',
    schoolName: '',
    schoolAddress: '',
    countryAdditional: '',

    languagePreferences: '',
    fundingType: '',
    schoolEnvironmentCategory: '',
    schoolGenderDistribution: '',
    fundedSchool: '',
  });

  const nextHandler = (value: any) => {
    setUserInfo(value);
    setCurrentStep(currentStep + 1);
  };

  const openModalAddClass = () => {
    modalState.show();
  };

  const renderStepAdditionalForm = (step: number) => {
    switch (step) {
      case 1:
        return (
          <StepOne
            nextHandler={nextHandler}
            userInfo={userInfo}
            openModalAddClass={openModalAddClass}
          />
        );
      case 2:
        return (
          <StepTwo userInfo={userInfo} openModalAddClass={openModalAddClass} />
        );
    }
  };

  return (
    <Container fluid className="mx-0 px-0">
      <TopNavbar
        brand="Global Nomads - Classroom Manager"
        routes={filteredRoutes}
      >
        <Container>
          <RouteSwitch routes={filteredRoutes} />
          {user.showPopup && renderStepAdditionalForm(currentStep)}
          {modalState.visible && (
            <EditClassModal
              show
              onHide={modalState.hide}
              editClass={modalState.entity}
            />
          )}
        </Container>
      </TopNavbar>
    </Container>
  );
};

const routes = [
  classListRoute,
  classDetailsRoute,
  courseDetailsRoute,
  userListRoute,
  userStoriesRoute,
  // reviewQueueRoute,
  // progressTrackerRoute,
  // surveyListRoute,
  // surveyDetailsRoute,
].filter((x) => x.options.enabled);
