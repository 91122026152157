import * as React from 'react';

const styles = require('../styles/StatusIcon.module.scss');

export interface StatusIconProps {
  img: string;
  label: string;
}

export const StatusIcon: React.FunctionComponent<StatusIconProps> = ({
  img,
  label,
}) => (
  <span className={styles.storyStatus}>
    {img && <img src={img} alt="user" />} <small>{label}</small>
  </span>
);
