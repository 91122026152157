export function debouncePromise<T extends (...args: any[]) => Promise<any>>(
  fn: T,
  delay: number,
): T {
  let wait: Promise<any> | null;
  let nextArgs: any[] | null;
  let nextContext: any;

  const next = (): any => {
    if (nextArgs) {
      const result = fn.apply(nextContext, nextArgs);
      wait = mintime(result, delay).then(next);

      nextArgs = null;
      return result;
    } else {
      wait = null;
    }
  };

  return function(this: any, ...args: any[]) {
    if (!wait) {
      wait = timeout(delay).then(next);
    }

    nextArgs = args;
    nextContext = this;
    return wait;
  } as any;
}

// Get a promise that will resolve after timeout ms
function timeout(timeout: number) {
  return new Promise<any>(resolve => setTimeout(resolve, timeout));
}

// Get a promise that will resolve with the given promise value after
// at least timeout ms
function mintime<T>(promise: Promise<T>, min: number): Promise<T> {
  return Promise.all([promise, timeout(min)]).then(([x]) => x);
}
