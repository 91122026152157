import * as React from 'react';
import { Class } from '../../model/entities/Class';
import { List } from './List';
import { ListItem } from './ListItem';
import { IconButton } from './IconButton';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import Select from 'react-select';
import { useApiQuery } from '../hooks/useApiQuery';

export interface ClassesFieldProps {
  value?: string[];
  onChange?: (value: string[]) => void;
}

export const ClassesField: React.FunctionComponent<ClassesFieldProps> = ({
  value,
  onChange,
}) => {
  const classes = useApiQuery<Class[]>('/classes', []);

  return (
    <>
      <Select
        options={classes.data}
        getOptionValue={x => x.id!}
        getOptionLabel={x => x.name}
        onChange={cls => {
          if (!cls || Array.isArray(cls)) return;
          !value!.find(el => el === (cls as any).id) && onChange!([...value!, (cls as any).id!]);
        }}
        placeholder="Search classes"
        value={null}
      />

      <List
        className="mt-3"
        items={value!}
        placeholder="No classes"
        renderItem={id => {
          const cls = classes.data.find(x => x.id === id);

          return cls ? (
            <ListItem
              key={id}
              title={cls.name}
              right={
                <IconButton
                  icon={faTimes}
                  onClick={() => {
                    onChange!(value!.filter(x => x !== id));
                  }}
                  variant="danger"
                />
              }
            />
          ) : null;
        }}
      />
    </>
  );
};
