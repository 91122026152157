import * as React from 'react';
import cs from 'classnames';
import * as _ from 'lodash';
import { ListGroup } from 'react-bootstrap';
import { Props } from '../util/Props';
import { CenteredBusySpinner } from './Busy';

const styles = require('../styles/List.module.scss');

export interface ListProps<T = any> extends Props<typeof ListGroup> {
  loading?: boolean;
  items: T[];
  placeholder?: React.ReactNode;
  renderItem: (item: T, index: number) => React.ReactNode;
  groupBy?: _.ValueIteratee<T>;
  sortGroupsBy?: _.ListIteratee<T>;
  sortGroupsOrder?: 'desc' | 'asc';
  renderGroupHeader?: (header: any) => React.ReactNode;
  more?: boolean;
  onLoadMoreClick?: () => void;
  loadingMore?: boolean;
}

export function List<T>({
  loading,
  placeholder = 'Nothing to show',
  renderItem,
  items,
  groupBy,
  sortGroupsBy,
  sortGroupsOrder,
  renderGroupHeader,
  more,
  onLoadMoreClick,
  loadingMore,
  ...props
}: ListProps<T>) {
  const groups = groupBy ? _.groupBy(items, groupBy) : items;

  return (
    <ListGroup {...props}>
      {loading ? (
        <CenteredBusySpinner variant="primary" animation="border" />
      ) : items.length === 0 ? (
        <ListGroup.Item className="text-muted">{placeholder}</ListGroup.Item>
      ) : groupBy ? (
        _.orderBy(
          Object.keys(groups) as any[],
          sortGroupsBy,
          sortGroupsOrder || 'asc',
        ).map(header => {
          const items = (groups as _.Dictionary<T[]>)[header as any];

          return (
            <React.Fragment key={header as any}>
              {header && (
                <ListGroup.Item className={styles.groupHeader}>
                  {renderGroupHeader ? renderGroupHeader(header) : header}
                </ListGroup.Item>
              )}
              {items.map(renderItem)}
            </React.Fragment>
          );
        })
      ) : (
        items.map(renderItem)
      )}

      {more ? (
        loadingMore ? (
          <ListGroup.Item>
            <CenteredBusySpinner variant="primary" animation="border" />
          </ListGroup.Item>
        ) : (
          <ListGroup.Item
            className={cs(styles.more, 'clickable')}
            onClick={onLoadMoreClick}
          >
            Load more
          </ListGroup.Item>
        )
      ) : null}
    </ListGroup>
  );
}
