import * as React from 'react';
import {Button, Col, Form, FormControl, Modal} from "react-bootstrap";
import {FormGroup} from "../FormGroup";
import {Select} from "../Select";
import {useUser} from "../../hooks/useUser";
import {Formik, FormikConfig} from "formik";
import * as yup from "yup";
import './AdditionalForm.css';
import {setUserInfoInterface} from "../Dashboard";
import {CountryFormField} from "./CountryFormField";
import {useField} from "../../hooks/useField";
// import {ApiClient} from "../../../util/ApiClient";
import _ from 'lodash';
import {useCreateNotification} from "../../hooks/useCreateNotification";
import {useApiClient} from "../../hooks/useApiClient";
import {useTrackingCode} from "react-hubspot-tracking-code-hook";
// import {useAsyncCallback} from "../../hooks/useAsyncCallback";
// import {ClassCourse} from "../../../model/entities/Class";


type StepOneProps = {
    nextHandler: (value: any) => void,
    openModalAddClass: Function,
    userInfo: setUserInfoInterface,
}

export const StepOne: React.FunctionComponent<StepOneProps> = ({nextHandler, userInfo,openModalAddClass}) => {
    // const client = new ApiClient('/api');
    const client = useApiClient();
    const { setTrackEvent } = useTrackingCode();
    const user = useUser();
    const createNotification = useCreateNotification();
    const educationTypeOption = [
        {value: 'School', label: 'School'},
        {value: 'Homeschool', label: 'Homeschool'},
        {value: 'Virtual/Online School', label: 'Virtual/Online School'},
        {value: 'Community Based Organization', label: 'Community Based Organization'},
        {value: 'Youth Development Center', label: 'Youth Development Center'},
        {value: 'Teacher Development Center', label: 'Teacher Development Center'},
        {value: 'Higher Education Institution', label: 'Higher Education Institution'},
        {value: 'Higher Education - Community College', label: 'Higher Education - Community College'}
    ];

    const country = useField<string>((user && user.address && user.address.country) || '', []);
    const [showModal, setShowModal] = React.useState(true);
    const [isHomeSchool, setHomeSchool] = React.useState(false);

    // eslint-disable-next-line
    const closeFirstModal = async() => {
        setShowModal(false);
        openModalAddClass();

        const data = {
            cannotProvide: true,
            showPopup: false
        };
        setTrackEvent({ eventId: 'Added School info step one. Empty', value: user.email })
        console.log('Added School info step one. Empty', user.email)
        sendDataToFirebase(data);
    };


    const sendDataToFirebase = async (data: any) => {
        try {
            const response = await client.patch(`/users/${user.id}`, data);
            console.log(response);
        } catch (error: any) {

            console.error('error', error.message);
            createNotification('Server error', error.message, 'danger', {
                cause: _.get(error, 'meta.details.message'),
            });
        }

    };

    const schemaGenerator = () =>
        yup.object().shape({
            educationType: yup.object().required('Required').nullable(),
            schoolName: yup.string().required('Required'),
            schoolAddress: yup.string().required('Required'),
        });
    const homeSchoolSchemaGenerator = () =>
        yup.object().shape({
            educationType: yup.object().required('Required').nullable(),
            schoolAddress: yup.string().required('Required'),
        });
    const schema = schemaGenerator();
    const schemaTwo = homeSchoolSchemaGenerator();


    const onSubmitHandler: FormikConfig<any>['onSubmit'] = async ( values,{setSubmitting}) => {
        console.log('onSubmitHandler 1');
        // console.log(values);

        setSubmitting(false);
        nextHandler({
            ...userInfo,
            educationType: values.educationType.label,
            schoolName: values.schoolName,
            schoolAddress: values.schoolAddress,
            countryAdditional: values.countryAdditional,
        });
        setTrackEvent({ eventId: 'Added School info step one.', value: user.email })
        console.log('Added School info step one.', user.email)
    };

    return (
        <Modal backdrop show={showModal} size="lg" contentClassName="p-5 border-radius">
            <Formik
                initialValues={{
                    educationType: {
                        label: 'School',
                        value: 'School'
                    },
                    schoolName: '',
                    schoolAddress: '',
                    countryAdditional: country.value,
                    // checkBoxCannotProvide: false
                }}
                validationSchema={isHomeSchool ? schemaTwo : schema}
                onSubmit={onSubmitHandler}
            >
                {({
                      handleSubmit,
                      handleChange,
                      values,
                      touched,
                      errors,
                      setFieldValue,
                      handleBlur
                  }) => {
                    // console.log(touched);
                    // console.log(errors);
                    // console.log(values);
                    setHomeSchool(values.educationType.value === 'Homeschool');
                    return (
                    <Form noValidate onSubmit={handleSubmit}>

                            <Modal.Header className={'modal-header-custom'}>
                                <Modal.Title>Learning about your organization</Modal.Title>
                            </Modal.Header>

                            <Modal.Body className={'modal-body-custom'}>
                                <p>To get you and your Students started with our Online Courses we need to know a bit about your School or Organisation.</p>

                                <Form.Row>
                                    <FormGroup as={Col} label="Education Type">
                                        <Select
                                            placeholder="Education Type"
                                            name="educationType"
                                            options={educationTypeOption}
                                            getOptionValue={(x) => x.value}
                                            getOptionLabel={(x) => x.label}
                                            onChange={(value) => setFieldValue('educationType',value)}
                                            value={values.educationType}
                                            isInvalid={touched.educationType && !!errors.educationType}
                                            onBlur={handleBlur('educationType')}
                                            className={(errors.educationType ? ' is-invalid' : '')}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {errors.educationType}
                                        </Form.Control.Feedback>
                                    </FormGroup>

                                </Form.Row>

                                {!isHomeSchool && <Form.Row>
                                    <FormGroup as={Col} label="School name">
                                        <FormControl
                                            placeholder="School name"
                                            name="schoolName"
                                            type="text"
                                            value={values.schoolName}
                                            onChange={handleChange}
                                            isInvalid={touched.schoolName && !!errors.schoolName}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {errors.schoolName}
                                        </Form.Control.Feedback>

                                    </FormGroup>

                                </Form.Row>}

                                <Form.Row>
                                    <FormGroup as={Col} label={isHomeSchool ? 'Homeschool address' : 'School address'}>
                                        <Form.Control
                                            placeholder={isHomeSchool ? 'Homeschool address' : 'School address'}
                                            name="schoolAddress"
                                            type="text"
                                            value={values.schoolAddress}
                                            onChange={handleChange}
                                            isInvalid={touched.schoolAddress && !!errors.schoolAddress}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {errors.schoolAddress}
                                        </Form.Control.Feedback>
                                    </FormGroup>

                                </Form.Row>

                                <Form.Row>
                                    <FormGroup as={Col} label="Country">
                                        <CountryFormField
                                            name="countryAdditional"
                                            placeholder="Country"
                                            value={values.countryAdditional}
                                            onChange={(value) => setFieldValue('countryAdditional',value)}
                                        />
                                    </FormGroup>
                                </Form.Row>

                            </Modal.Body>

                            <Modal.Footer className={'modal-footer-custom'}>

                                {/* <div className={'cannot-provide'}  onClick={ () =>{closeFirstModal();} } >I cannot provide this information</div> */}

                                <Button
                                    type="submit"
                                    variant="btn btn-primary"
                                >
                                    Submit
                                </Button>

                            </Modal.Footer>

                    </Form>
                )}}
            </Formik>
        </Modal>

    );
};
