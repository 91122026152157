import * as React from 'react';
import cs from 'classnames';
import { Props } from '../util/Props';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

export interface IconButtonProps extends Props<Button> {
  icon?: IconProp;
  inverse?: boolean;
}

export const IconButton: React.FunctionComponent<IconButtonProps> = ({
  icon,
  className,
  children,
  inverse,
  ...props
}) => (
  <Button {...props} className={cs('icon-button', className, { inverse })}>
    {icon && (
      <FontAwesomeIcon icon={icon} className={children ? 'mr-2' : undefined} />
    )}
    {children}
  </Button>
);
