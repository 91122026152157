import * as React from 'react';
import { Route } from '../Route';
import { Card, Button, FormControl } from 'react-bootstrap';
import Autocomplete from 'react-google-autocomplete';
import _ from 'lodash';
import { useAsyncEffect } from '../hooks/useAsyncEffect';
import { useApiClient } from '../hooks/useApiClient';
import { BusySpinner, BusyButton } from '../components/Busy';
import { Class, ClassCourse } from '../../model/entities/Class';
import { GenderField } from '../components/GenderField';
import { KnownLanguagesField } from '../components/KnownLanguagesField';
import { CountryField } from '../components/CountryField';
import { useAsyncCallback } from '../hooks/useAsyncCallback';
import { CheckboxGroup } from '../components/Checkbox';
import { useInputField } from '../hooks/useInputField';
import { required } from '../validators/required';
import { emailFormat } from '../validators/emailFormat';
import { useField } from '../hooks/useField';
import {
  UserGender,
  KnownLanguage,
  Address,
  Race,
  Religion,
  Disability,
} from '../../model/entities/User';
import { FormGroup } from '../components/FormGroup';
import { validateAll } from '../util/validateAll';
import { Select } from '../components/Select';
import logo from '../images/gng-logo.png';
import canvasLogo from '../images/canvas-logo.png';
import { RaceField } from '../components/RaceField';
import { ReligionField } from '../components/ReligionField';
import { DisabilityField } from '../components/Disability';
const styles = require('../styles/SelfRegistrationPage.module.scss');

export const GOOGLE_MAPS_API_KEY = 'AIzaSyAKlYVrhQ9Kio8sqNx3PBs8HsRcwHeA1Do';

// https://stackoverflow.com/a/16861050
export const popupCenter = ({ url, title, w, h }: Popup) => {
  // Fixes dual-screen position                             Most browsers      Firefox
  const dualScreenLeft =
    window.screenLeft !== undefined ? window.screenLeft : window.screenX;
  const dualScreenTop =
    window.screenTop !== undefined ? window.screenTop : window.screenY;

  const width = window.innerWidth
    ? window.innerWidth
    : document.documentElement.clientWidth
    ? document.documentElement.clientWidth
    : window.screen.width;
  const height = window.innerHeight
    ? window.innerHeight
    : document.documentElement.clientHeight
    ? document.documentElement.clientHeight
    : window.screen.height;

  const systemZoom = width / window.screen.availWidth;
  const left = (width - w) / 2 / systemZoom + dualScreenLeft;
  const top = (height - h) / 2 / systemZoom + dualScreenTop;
  window.open(
    url,
    title,
    `
    scrollbars=yes,
    width=${w / systemZoom}, 
    height=${h / systemZoom}, 
    top=${top}, 
    left=${left}
    `,
  );
};

// https://stackoverflow.com/a/2880929
const getURLParams = () => {
  const URLParams: { [key: string]: any } = {};
  let match,
    pl = /\+/g, // Regex for replacing addition symbol with a space
    search = /([^&=]+)=?([^&]*)/g,
    decode = function (s: string) {
      return decodeURIComponent(s.replace(pl, ' '));
    },
    query = window.location.search.substring(1);

  while ((match = search.exec(query)) !== null) {
    if (decode(match[1]) in URLParams) {
      if (!Array.isArray(URLParams[decode(match[1])])) {
        URLParams[decode(match[1])] = [URLParams[decode(match[1])]];
      }
      URLParams[decode(match[1])].push(decode(match[2]));
    } else {
      URLParams[decode(match[1])] = decode(match[2]);
    }
  }
  return URLParams;
};

const getURLCoursesIds = () => {
  const URLParams = getURLParams();
  const queryCourses = URLParams['courses[]'];
  let coursesIds: number[] = [];
  switch (typeof queryCourses) {
    case 'string':
      coursesIds = isNaN(parseInt(queryCourses))
        ? []
        : [parseInt(queryCourses)];
      break;
    case 'object':
      coursesIds = queryCourses.map((courseId: any) => parseInt(courseId));
  }
  return coursesIds;
};

interface Popup {
  url: string;
  title: string;
  w: number;
  h: number;
}

export const selfRegistrationConfirmRoute = new Route<any>(
  '/self-registration/:token/:email',
  ({ match, location }) => {
    const client = useApiClient();
    const [error, setError] = React.useState(false);
    const [currentClass, setCurrentClass] = React.useState<Class | null>(null);
    const [canvasUrl, setCanvasUrl] = React.useState('');
    const [registered, setRegistered] = React.useState(false);

    useAsyncEffect(async () => {
      try {
        const response = await client.post(
          `/users${location.pathname}${location.search}`,
          {},
        );
        setCurrentClass(response.data.class);
        setCanvasUrl(response.data.canvasUrl);
        setRegistered(true);
      } catch (err) {
        setError(true);
      }
    }, [match.params.token]);

    return (
      <div className={styles.container}>
        <div className="justify-content-center row">
          <div className="col-lg-4">
            <Card className={styles.card}>
              <Card.Header className="border-0 bg-transparent">
                <Card.Title className="align-items-center d-flex flex-row font-weight-bold">
                  <img src={logo} height={40} alt="GNG" className="mr-4" />{' '}
                  Registration
                </Card.Title>
              </Card.Header>

              <Card.Body>
                {error ? (
                  <>
                    <h3 className="text-danger">Error</h3>

                    <p>
                      Sorry, there was an error. Please contact Global Nomads
                      Group for help.
                    </p>
                  </>
                ) : registered ? (
                  <>
                    <h3>Welcome</h3>

                    <p>
                      You have successfully signed up for{' '}
                      <strong className="text-primary">
                        {currentClass!.name}
                      </strong>
                      .
                    </p>

                    <Card>
                      <Card.Body>
                        <img
                          src={canvasLogo}
                          alt="Canvas"
                          height={25}
                          className="mb-2"
                        />

                        <p className="mb-0">
                          We use <strong>Canvas</strong> for our online learning
                          platform.
                        </p>
                        <Button
                          href={canvasUrl}
                          variant="primary"
                          className="mt-3"
                        >
                          Log In
                        </Button>
                      </Card.Body>
                    </Card>
                  </>
                ) : (
                  <>
                    <div className="d-flex flex-row align-items-center mb-4">
                      <BusySpinner size="sm" />
                      <div className="ml-4">
                        We're registering you in the class, won't be a moment.
                      </div>
                    </div>
                  </>
                )}
              </Card.Body>
            </Card>
          </div>
        </div>
      </div>
    );
  },
);

export const selfRegistrationRoute = new Route<{ token: string }>(
  '/self-registration/:token',
  ({ match }) => {
    const client = useApiClient();
    const [error, setError] = React.useState(false);
    const [registered, setRegistered] = React.useState(false);
    const [userExists, setUserExists] = React.useState(false);
    const [currentClass, setCurrentClass] = React.useState<Class | null>(null);

    const loading = useAsyncEffect(async () => {
      try {
        const currentClass = await client.get(
          `/classes/self-registration/${match.params.token}`,
        );
        setCurrentClass(currentClass.data.class);
      } catch (err) {
        setError(true);
      }
    }, [match.params.token]);

    return (
      <div className={styles.container}>
        <div className="justify-content-center row">
          <div className="col-lg-4">
            <Card className={styles.card}>
              <Card.Header className="border-0 bg-transparent">
                <Card.Title className="align-items-center d-flex flex-row font-weight-bold">
                  <img src={logo} height={40} alt="GNG" className="mr-4" />{' '}
                  Student Sign up
                </Card.Title>
              </Card.Header>

              <Card.Body>
                {error ? (
                  <>
                    <h3 className="text-danger">Error</h3>

                    <p>
                      Sorry, there was an error. Please contact Global Nomads
                      Group for help.
                    </p>
                  </>
                ) : userExists ? (
                  <>
                    <h3>Confirm the registration</h3>

                    <p>
                      Please check your email and click the link to confirm the
                      registration.
                    </p>
                  </>
                ) : registered ? (
                  <>
                    <h3>Welcome</h3>

                    <p>
                      You have successfully signed up for{' '}
                      <strong className="text-primary">
                        {currentClass!.name}
                      </strong>
                      . <strong>Please check your email!</strong>
                    </p>

                    <Card>
                      <Card.Body>
                        <img
                          src={canvasLogo}
                          alt="Canvas"
                          height={25}
                          className="mb-2"
                        />

                        <p className="mb-0">
                          We use <strong>Canvas</strong> for our online learning
                          platform. Check your email for final steps to create
                          your password and get started.
                        </p>
                      </Card.Body>
                    </Card>
                  </>
                ) : currentClass &&
                  currentClass.selfRegistration &&
                  currentClass.selfRegistration.limitReached ? (
                  <>
                    <h3>Class full</h3>
                    <p>Sorry, but the class is currently full.</p>
                    {currentClass.selfRegistration.waitlistUrl && (
                      <Button
                        href={currentClass.selfRegistration.waitlistUrl}
                        variant="primary"
                        className="mt-3"
                      >
                        Join waiting list
                      </Button>
                    )}
                  </>
                ) : (
                  <>
                    {loading || !currentClass ? (
                      <>
                        <div className="d-flex flex-row align-items-center mb-4">
                          <BusySpinner size="sm" />
                          <div className="ml-4">
                            We're just fetching the class details, won't be a
                            moment.
                          </div>
                        </div>
                      </>
                    ) : (
                      <>
                        <p>
                          You are registering for{' '}
                          <strong className="text-primary">
                            {currentClass.name}
                          </strong>
                        </p>
                      </>
                    )}

                    {currentClass &&
                      (currentClass.selfRegistration &&
                      currentClass.selfRegistration.enabled ? (
                        <SelfRegistrationForm
                          selfRegistration={currentClass.selfRegistration}
                          classCourses={currentClass.courses}
                          token={match.params.token}
                          setRegistered={setRegistered}
                          setUserExists={setUserExists}
                        />
                      ) : (
                        <p>The self-registration is not active.</p>
                      ))}
                  </>
                )}
              </Card.Body>
            </Card>
          </div>
        </div>
      </div>
    );
  },
);

interface SelfRegistrationFormProps {
  selfRegistration: NonNullable<Class['selfRegistration']>;
  classCourses: Class['courses'];
  token: string;
  setRegistered: (registered: boolean) => void;
  setUserExists: (exists: boolean) => void;
}

const SelfRegistrationForm: React.FunctionComponent<
  SelfRegistrationFormProps
> = ({
  selfRegistration,
  classCourses,
  token,
  setRegistered,
  setUserExists,
}) => {
  const client = useApiClient();

  const matchedCourses = _.intersectionWith(
    classCourses,
    getURLCoursesIds(),
    (value, other) => value.id === other,
  );
  const requiredCourses = selfRegistration.individualCourseEnrol
    ? [(value: any) => (!value || !value.length ? 'Required' : '')]
    : [];
  const courses = useField<ClassCourse[] | undefined>(
    matchedCourses.length ? matchedCourses : undefined,
    requiredCourses,
  );
  const name = useInputField('', [required]);
  const email = useInputField('', [required, emailFormat]);
  const requiredAge = selfRegistration.age ? [required] : [];
  const age = useInputField('', requiredAge);
  const requiredGender = selfRegistration.gender ? [required] : [];
  const gender = useField<UserGender | undefined>(undefined, requiredGender);
  const requiredRace = selfRegistration.race ? [required] : [];
  const race = useField<Race | undefined>(undefined, requiredRace);
  const requiredReligion = selfRegistration.religion ? [required] : [];
  const religion = useField<Religion | undefined>(undefined, requiredReligion);
  const requiredDisability = selfRegistration.disability ? [required] : [];
  const disability = useField<Disability | undefined>(
    undefined,
    requiredDisability,
  );
  const marginalizedIdentity = useInputField('', []);
  const requiredGradeLevel = selfRegistration.gradeLevel ? [required] : [];
  const gradeLevel = useInputField('', requiredGradeLevel);
  const school = useInputField('', []);
  const requiredAddress = selfRegistration.address ? [required] : [];
  const address = useField<Address | undefined>(undefined, requiredAddress);
  const requiredCountry = selfRegistration.address ? [required] : [];
  const country = useField<string>('', requiredCountry);
  const languages = useField<KnownLanguage[]>([], []);
  const requiredAdditionalEmail = selfRegistration.parentDetails
    ? [required, emailFormat]
    : [];
  const additionalEmail = useInputField('', requiredAdditionalEmail);
  const terms = useField(
    false,
    selfRegistration.terms
      ? [(value) => (!value ? 'You must agree to the terms' : '')]
      : [],
  );
  const termsPartner = useField(
    false,
    selfRegistration.termsPartner
      ? [(value) => (!value ? 'You must agree to the terms' : '')]
      : [],
  );

  const register = useAsyncCallback(
    async (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();

      if (
        !validateAll([
          courses,
          name,
          email,
          age,
          gender,
          race,
          religion,
          disability,
          marginalizedIdentity,
          gradeLevel,
          school,
          address,
          country,
          languages,
          additionalEmail,
          terms,
          termsPartner,
        ])
      )
        return;

      const response = await client.post(
        `/users/self-registration/${token}`,
        _.pickBy(
          {
            courses: courses.value,
            name: name.value,
            email: email.value,
            age: age.value || undefined,
            gender: gender.value,
            race: race.value,
            religion: religion.value,
            disability: disability.value,
            marginalizedIdentity: marginalizedIdentity.value,
            gradeLevel: gradeLevel.value,
            school: school.value,
            languages: languages.value.length ? languages.value : undefined,
            additionalEmail: additionalEmail.value,
            terms: terms.value,
            termsPartner: termsPartner.value,
            address: address.value
              ? {
                  ...address.value,
                  country: address.value.country
                    ? address.value.country
                    : country.value,
                }
              : address.value,
          },
          (value) => value !== '',
        ),
      );

      console.log('response', response);

      if (response.data && response.data.userExists) {
        setUserExists(true);
      } else {
        setRegistered(true);
      }
    },
    [
      token,
      courses,
      name,
      email,
      age,
      gender,
      race,
      religion,
      disability,
      marginalizedIdentity,
      gradeLevel,
      school,
      address,
      country,
      languages,
      additionalEmail,
      terms,
    ],
  );

  return (
    <form onSubmit={register.call}>
      <h5 className={styles.subheading}>Participant's details</h5>
      <p>Please enter in the details for the participating youth below.</p>

      {selfRegistration.individualCourseEnrol && (
        <FormGroup label="Courses" error={courses.error}>
          <Select
            isMulti
            options={classCourses}
            getOptionValue={(x) => x.id.toString()}
            getOptionLabel={(x) => x.name}
            onChange={(value) => {
              console.log(courses, value);
              courses.setValue(value as ClassCourse[]);
            }}
            placeholder="Courses"
            value={courses.value}
            isInvalid={!!courses.error}
          />
        </FormGroup>
      )}

      <FormGroup label="Name" error={name.error}>
        <FormControl
          placeholder="The full name of the participant"
          value={name.value}
          onChange={name.onChange}
          onBlur={name.validate}
          isInvalid={!!name.error}
        />
      </FormGroup>

      <FormGroup label="Email" error={email.error}>
        <FormControl
          placeholder="The participant's email address"
          value={email.value}
          onChange={email.onChange}
          onBlur={email.validate}
          isInvalid={!!email.error}
        />
      </FormGroup>

      {selfRegistration.gender && (
        <FormGroup label="Gender" error={gender.error}>
          <GenderField
            value={gender.value}
            onChange={(event) => gender.setValue(event.target.value)}
          />
        </FormGroup>
      )}

      {selfRegistration.age && (
        <FormGroup label="Age" error={age.error}>
          <FormControl
            type="number"
            placeholder="The participant's age in years"
            value={age.value}
            onChange={age.onChange}
            onBlur={age.validate}
            isInvalid={!!age.error}
          />
        </FormGroup>
      )}

      {selfRegistration.race && (
        <FormGroup label="Race" error={race.error}>
          <RaceField
            value={race.value}
            onChange={(value) => race.setValue(value)}
            isInvalid={!!race.error}
          />
        </FormGroup>
      )}

      {selfRegistration.religion && (
        <FormGroup label="Religion" error={religion.error}>
          <ReligionField
            value={religion.value}
            onChange={(value) => religion.setValue(value)}
            isInvalid={!!religion.error}
          />
        </FormGroup>
      )}

      {selfRegistration.disability && (
        <FormGroup label="Disability" error={disability.error}>
          <DisabilityField
            value={disability.value}
            onChange={(value) => disability.setValue(value)}
            isInvalid={!!disability.error}
          />
        </FormGroup>
      )}

      {selfRegistration.marginalizedIdentity && (
        <FormGroup label="Marginalized identity" optional>
          <FormControl
            placeholder="Any other identity that you consider marginalised"
            value={marginalizedIdentity.value}
            onChange={marginalizedIdentity.onChange}
          />
        </FormGroup>
      )}

      {selfRegistration.school && (
        <FormGroup error={school.error} label="School" optional>
          <FormControl
            placeholder="The name of the school the participant attends"
            value={school.value}
            onChange={school.onChange}
          />
        </FormGroup>
      )}

      {selfRegistration.gradeLevel && (
        <FormGroup label="Grade level" error={gradeLevel.error}>
          <FormControl
            placeholder="What year in school is the participant?"
            value={gradeLevel.value}
            onChange={gradeLevel.onChange}
            onBlur={gradeLevel.validate}
            isInvalid={!!gradeLevel.error}
          />
        </FormGroup>
      )}

      {selfRegistration.address && (
        <FormGroup label="Location" error={address.error}>
          <Autocomplete
            className={'form-control' + (address.error ? ' is-invalid' : '')}
            apiKey={GOOGLE_MAPS_API_KEY}
            onPlaceSelected={(place: any) => {
              console.log(place);
              if (!place.address_components) return;
              const cityItem = place.address_components.find((item: any) =>
                item.types.find((type: string) => type === 'locality'),
              );
              const stateItem = place.address_components.find((item: any) =>
                item.types.find(
                  (type: string) => type === 'administrative_area_level_1',
                ),
              );
              const countryItem = place.address_components.find((item: any) =>
                item.types.find((type: string) => type === 'country'),
              );
              const addressValue: Address = {
                city: (cityItem && cityItem.short_name) || '',
                state: (stateItem && stateItem.short_name) || '',
                country: (countryItem && countryItem.short_name) || '',
              };
              country.setValue(addressValue.country);
              address.setValue(addressValue);
            }}
            name="address"
            initialvalue={address.value}
            placeholder="Location"
            onChange={(event: any) => {
              if (event.target.value === '') {
                address.setValue(undefined);
              }
            }}
          />
        </FormGroup>
      )}

      {selfRegistration.address && (
        <FormGroup label="Country" error={country.error}>
          <CountryField
            placeholder="Country"
            value={country.value}
            onChange={country.setValue}
            isInvalid={!!country.error}
          />
        </FormGroup>
      )}

      {selfRegistration.languages && (
        <FormGroup label="Other languages" optional>
          <KnownLanguagesField
            value={languages.value}
            onChange={languages.setValue}
          />
        </FormGroup>
      )}

      {selfRegistration.parentDetails && (
        <>
          <h5 className={styles.subheading}>Parent/guardian's details</h5>

          <p>
            If you are registering as a youth participant (you must be at least
            13 years old), please include your parents'/guardians' email so that
            they can receive program updates.
          </p>

          <FormGroup label="Additional email" error={additionalEmail.error}>
            <FormControl
              placeholder="The parent/guardian's email address"
              value={additionalEmail.value}
              onChange={additionalEmail.onChange}
              onBlur={additionalEmail.validate}
              isInvalid={!!additionalEmail.error}
            />
          </FormGroup>
        </>
      )}

      {selfRegistration.terms && (
        <FormGroup error={terms.error}>
          <CheckboxGroup
            value={terms.value}
            onChange={terms.setValue}
            label={
              <>
                I certify that I am at least 13 years of age and have read and
                agree to{' '}
                <a
                  href={selfRegistration!.privacyUrl}
                  onClick={(e) => {
                    e.preventDefault();
                    popupCenter({
                      url: selfRegistration!.privacyUrl!,
                      title: "GNG's Privacy Policy",
                      w: 900,
                      h: 500,
                    });
                    return false;
                  }}
                >
                  GNG's Privacy Policy
                </a>{' '}
                and the{' '}
                <a
                  href={selfRegistration!.termsUrl}
                  onClick={(e) => {
                    e.preventDefault();
                    popupCenter({
                      url: selfRegistration!.termsUrl!,
                      title: 'Student to World Terms and Conditions',
                      w: 900,
                      h: 500,
                    });
                    return false;
                  }}
                >
                  Student to World Terms and Conditions
                </a>
                .
              </>
            }
          />
        </FormGroup>
      )}

      {selfRegistration.termsPartner && (
        <FormGroup error={termsPartner.error}>
          <CheckboxGroup
            value={termsPartner.value}
            onChange={termsPartner.setValue}
            label={
              <div
                dangerouslySetInnerHTML={{
                  __html: selfRegistration!.termsPartner,
                }}
              />
            }
          />
        </FormGroup>
      )}

      <BusyButton type="submit" busy={register.busy}>
        Sign up
      </BusyButton>
    </form>
  );
};
