import * as React from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEnvelope} from "@fortawesome/free-solid-svg-icons";
import ShowMoreText from "react-show-more-text";
import {BusyButton} from "../../components/Busy";
import {useApiClient} from "../../hooks/useApiClient";

const styles = require('../../styles/RecentSubmissions.module.scss');

export const StoryItem : React.FunctionComponent<any> = ({storyItem}) => {
    const [downloadPDF, setDownloadPDF] = React.useState<boolean>();

    const client = useApiClient();

    const downloadPDFFile = async (fileValue: any) => {
        try {
            // set downloading
            setDownloadPDF(true);
            // header
            const header = `<div style="text-align:center"><h2>${fileValue.fullName} submitted work in ${fileValue.module.title} : ${fileValue.module.hashtag && fileValue.module.hashtag} - ${fileValue.course.title}</h2><a href="https://gng.org/">gng.org</a></div>`;
            // content
            const content = header + (fileValue.description);
            // file name
            const fileName = `${fileValue.fullName} - ${fileValue.module.title} (${fileValue.course.title}).pdf`;

            // download submission
            const response = await client.post(
                `/submissions/download`,
                {
                    content,
                    fileName,
                }
            );
            // get the blob
            const blob = await response.blob();
            // create a URL for the blob
            const url = URL.createObjectURL(blob);
            // create an anchor element to "point" to it
            const anchor = document.createElement('a');
            anchor.href = url;

            // get the suggested filename for the file from the response headers
            anchor.download = fileName;

            // simulate a click on our anchor element
            anchor.click();

            // discard the object data
            URL.revokeObjectURL(url);

            // set downloading
            setDownloadPDF(false);
        } catch (error) {
            console.log(error);
            // set downloading
            setDownloadPDF(false);
        }
    }

    let createdAt = new Date(storyItem.createdAt).toDateString();

    return (
        <div className={`list-group-item`}>
            <div className={styles.groupWrap}>
                <div className={styles.storyInfo}>{storyItem.fullName} submitted work
                    in {storyItem.module.title} : {storyItem.module.hashtag && storyItem.module.hashtag + ' - '} {storyItem.course.title}</div>
                <div className={styles.shortInfo}>{storyItem.fullName}</div>
                <div className={styles.shortInfo}>{storyItem.userInfo?.email && <><FontAwesomeIcon
                    icon={faEnvelope}/> {storyItem.userInfo.email}</>}</div>
                <div className={styles.time}>{createdAt}</div>
            </div>

            <h5>{storyItem.title}</h5>
            <div className={styles.groupWrap}>
                <ShowMoreText lines={2}>
                    <div className={styles.courseDescription}
                         dangerouslySetInnerHTML={{__html: storyItem.description}}/>
                </ShowMoreText>
                <BusyButton
                    variant="outline-primary"
                    onClick={() => downloadPDFFile(storyItem)}
                    busy={downloadPDF}
                >
                    download PDF
                </BusyButton>
            </div>
        </div>
    );
};
