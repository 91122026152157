import * as React from 'react';
import { Modal, Button, FormControl } from 'react-bootstrap';
import _ from 'lodash';
import { BusyButton } from '../components/Busy';
import { useAsyncCallback } from '../hooks/useAsyncCallback';
import { ModalProps } from './ModalProps';
import { Class, ClassCourse } from '../../model/entities/Class';
import { User, UserGender, UserRole } from '../../model/entities/User';
import { useApiClient } from '../hooks/useApiClient';
import { useUser } from '../hooks/useUser';
import { CheckboxGroup } from '../components/Checkbox';
import { GenderField } from '../components/GenderField';
import { useInputField } from '../hooks/useInputField';
import { required } from '../validators/required';
import { emailFormat } from '../validators/emailFormat';
import { FormGroup } from '../components/FormGroup';
import { useField } from '../hooks/useField';
import { validateAll } from '../util/validateAll';
import '../styles/EditStudentModal.scss';
import { Select } from '../components/Select';
import { ModalCountryField } from '../components/ModalFields/ModalCountryField';
import { ModalCityFieldSelect } from '../components/ModalFields/ModalCityFieldSelect';

export interface EditStudentModalProps extends ModalProps {
  currentClass: Class;
  user?: User;
}

export const EditStudentModal: React.FunctionComponent<
  EditStudentModalProps
> = ({ show, onHide, currentClass, user }) => {
  const client = useApiClient();
  const currentUser = useUser();
  const [createAnother, setCreateAnother] = React.useState(true);
  const nameRef = React.useRef<any>();

  const name = useInputField(user ? user.name : '', [required]);
  const email = useInputField(user ? user.email : '', [emailFormat]);
  const password = useInputField('', []);
  const confirmPassword = useInputField('', [
    (value) => (value && value !== password.value ? 'Must match password' : ''),
  ]);
  // const gradeLevel = useInputField((user && user.gradeLevel) || '', [required]);
  const gender = useField<UserGender>(
    (user && (user.gender as any)) || UserGender.PreferNotToSay,
    [required],
  );
  const age = useInputField((user && user.age) || '', [required]);
  const country = useField<any>((user && user.address?.country) || '', []);
  const address = useField<any>((user && user.address?.city) || '', []);
  const courses = useField<ClassCourse[]>(
    user
      ? _.intersectionWith(
          user.courses,
          currentClass.courses.map((course) => ({
            ...course,
            classId: currentClass.id,
          })),
          _.isEqual,
        )
      : !currentClass.selfRegistration ||
        !currentClass.selfRegistration.individualCourseEnrol
      ? currentClass.courses
      : [],
    [],
  );

  const [inputCityValue, setInputCityValue] = React.useState();

  const editStudent = useAsyncCallback(
    async (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();

      if (
        !validateAll([
          name,
          email,
          password,
          confirmPassword,
          gender,
          age,
          // address,
          country,
          courses,
        ])
      )
        return;

      const values = {
        name: name.value,
        email: email.value,
        password: password.value,
        confirmPassword: confirmPassword.value,
        gender: gender.value,
        age: age.value,
        courses: courses.value,
        address: {
          country: country.value || currentUser.address?.country,
          city: address.value || currentUser.address?.city,
        },
      };

      if (user) {
        await client.update(
          `/classes/${currentClass.id}/students`,
          user.id!,
          values,
        );
      } else {
        await client.create(`/classes/${currentClass.id}/students`, values);

        if (createAnother) {
          name.setValue('', false);
          email.setValue('', false);
          password.setValue('', false);
          confirmPassword.setValue('', false);
          gender.setValue(UserGender.PreferNotToSay, false);
          age.setValue('', false);
          address.setValue('', false);
          country.setValue('', false);
          nameRef.current!.focus();
          courses.setValue(
            !currentClass.selfRegistration ||
              !currentClass.selfRegistration.individualCourseEnrol
              ? currentClass.courses
              : [],
          );
          return;
        }
      }

      onHide();
    },
    [
      user,
      currentClass,
      createAnother,
      name,
      email,
      password,
      confirmPassword,
      gender,
      age,
      address,
      country,
      courses,
    ],
  );

  return (
    <Modal backdrop show={show} onHide={onHide}>
      <form onSubmit={editStudent.call}>
        <Modal.Header closeButton>
          <Modal.Title>{user ? 'Edit' : 'Create'} student</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <FormGroup label="Name" error={name.error}>
            <FormControl
              placeholder="Student's full name"
              ref={nameRef}
              autoFocus
              value={name.value}
              onChange={name.onChange}
              onBlur={name.validate}
              isInvalid={!!name.error}
            />
          </FormGroup>

          {currentUser.role === UserRole.Admin && (
            <>
              <FormGroup label="Email" error={email.error} optional>
                <FormControl
                  type="email"
                  placeholder="The student's email address, if they have one"
                  value={email.value}
                  onChange={email.onChange}
                  onBlur={email.validate}
                  isInvalid={!!email.error}
                  disabled={!!user}
                />
              </FormGroup>

              {!user && (
                <>
                  <FormGroup
                    label="Password"
                    help="Leave blank to autogenerate a password"
                    error={password.error}
                    optional
                  >
                    <FormControl
                      type="password"
                      placeholder="The password the student will use to sign in"
                      value={password.value}
                      onChange={password.onChange}
                      onBlur={password.validate}
                      isInvalid={!!password.error}
                    />
                  </FormGroup>

                  {password.value && (
                    <FormGroup
                      label="Confirm password"
                      error={confirmPassword.error}
                    >
                      <FormControl
                        type="password"
                        placeholder="Type the password again"
                        value={confirmPassword.value}
                        onChange={confirmPassword.onChange}
                        onBlur={confirmPassword.validate}
                        isInvalid={!!confirmPassword.error}
                      />
                    </FormGroup>
                  )}
                </>
              )}
            </>
          )}

          <FormGroup label="Gender" error={gender.error}>
            <GenderField
              value={gender.value}
              onChange={(event) => gender.setValue(event.target.value)}
            />
          </FormGroup>

          <FormGroup label="Age" error={age.error}>
            <FormControl
              placeholder="The student's age"
              value={age.value}
              onChange={age.onChange}
              onBlur={age.validate}
              isInvalid={!!age.error}
            />
          </FormGroup>

          {/* reduce form fields user has to fill */}
          <FormGroup label="Country">
            <ModalCountryField
              value={country.value}
              onChange={country.setValue}
              placeholder="Country"
              isInvalid={!!country.error}
              setInputCityValue={setInputCityValue}
              resetCity={address.setValue}
            />
          </FormGroup>

          <FormGroup label="City">
            <ModalCityFieldSelect
              options={inputCityValue}
              value={address.value}
              onChange={address.setValue}
              placeholder="City"
              isInvalid={!!address.error}
              name="address"
            />
          </FormGroup>

          <FormGroup label="Courses" error={courses.error}>
            <Select
              isMulti
              options={currentClass.courses}
              getOptionValue={(x) => x.uuid}
              getOptionLabel={(x) => x.name}
              onChange={(value) => courses.setValue(value as ClassCourse[])}
              placeholder="Courses"
              value={courses.value}
              isDisabled={
                !currentClass.selfRegistration ||
                !currentClass.selfRegistration.individualCourseEnrol
              }
              isInvalid={!!courses.error}
            />
          </FormGroup>
        </Modal.Body>

        <Modal.Footer>
          {!user && (
            <CheckboxGroup
              label="Create another"
              value={createAnother}
              onChange={setCreateAnother}
              className="mr-4 mb-0"
            />
          )}
          <Button
            variant="outline-secondary"
            onClick={onHide}
            disabled={editStudent.busy}
          >
            Cancel
          </Button>
          <BusyButton variant="primary" type="submit" busy={editStudent.busy}>
            Save
            {!user && createAnother && <> and continue</>}
          </BusyButton>
        </Modal.Footer>
      </form>
    </Modal>
  );
};
