import * as React from 'react';
import { Column } from 'react-table';
import { Class } from '../../model/entities/Class';
import { RedstoneTable, Data } from '../components/Table';
import { Dropdown } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCopy,
  faEllipsisV,
  faPencilAlt,
  faTrashAlt,
  faFile,
  faShare
} from '@fortawesome/free-solid-svg-icons';
import { useBoolean } from '../hooks/useBoolean';
import { User, UserRole } from '../../model/entities/User';
import { EditStudentModal } from '../modals/EditStudentModal';
import * as copy from 'copy-text-to-clipboard';
import { ImportStudentsModal } from '../modals/ImportStudentsModal';
import { useApiQuery } from '../hooks/useApiQuery';
import { useEditModalState } from '../hooks/useEditModalState';
import { DeleteModal } from '../modals/DeleteModal';
import { ResetStudentPasswordModal } from '../modals/ResetStudentPasswordModal';
import { featureToggles } from '../featureToggles';
import { useUser } from '../hooks/useUser';
import { InviteParticipantssModal } from '../modals/InviteParticipantsModal';
import { QButton } from '../components/QButton';
import AddUserIcon from '../images/user.png';
import styles from '../styles/ClassStudentSection.module.scss';
import Avatar from '../images/Avatar.png';
// const countriesData = require('countries-list/dist/countries.min.json');

export interface ClassStudentSectionProps {
  currentClass: Class;
}

const UserComponent = ({ name }: any) => (
  <div className={styles.profile}>
    <img src={Avatar} alt="avatar" />
    <small>{name}</small>
  </div>
);

const PasswordComponent = ({ initialPassword }: any) => (
  <div
    onClick={(e) => {
      e.preventDefault();
      e.stopPropagation();
      copy(initialPassword!);
    }}
  >
    <span className="text-muted font-size-sm">
      <FontAwesomeIcon icon={faCopy} className="mr-2" />
      <span className={styles.passwd}>{initialPassword}</span>
    </span>
  </div>
);

const ActionsComponent = ({
  editModal,
  deleteModal,
  resetPasswordModal,
  user,
  isDropdownOpen,
}: any) => (
  <div className={styles.actionWrap}>
    <ul
      className={`${styles.actionWrapMenu} ${
        isDropdownOpen ? `${styles.show}` : ''
      }`}
    >
      <Dropdown.Item onClick={() => editModal.show(user)}>
        <FontAwesomeIcon icon={faPencilAlt} className="mr-2" />
        Edit
      </Dropdown.Item>
      <Dropdown.Item onClick={() => deleteModal.show(user)}>
        <FontAwesomeIcon icon={faTrashAlt} className="mr-2" color="#DC2626" />
        <span style={{ color: '#DC2626' }}>Delete</span>
      </Dropdown.Item>
      {featureToggles.resetPassword && user.initialPassword && (
        <Dropdown.Item onClick={() => resetPasswordModal.show(user)}>
          Reset password
        </Dropdown.Item>
      )}
    </ul>
  </div>
);

export const ClassStudentSection: React.FunctionComponent<
  ClassStudentSectionProps
> = ({ currentClass }) => {
  const editModal = useEditModalState<User>();
  const deleteModal = useEditModalState<User>();
  const resetPasswordModal = useEditModalState<User>();
  const user = useUser();

  const importModalVisible = useBoolean();
  const inviteParticipantsModalVisible = useBoolean();

  const [openDropdown, setOpenDropdown] = React.useState(null);

  const users = useApiQuery<User[]>(`/classes/${currentClass.id}/students`, []);

  const columns = React.useMemo<Column<Partial<Data>>[]>(
    () => [
      { Header: 'Student', accessor: 'student' },
      { Header: 'Email', accessor: 'email' },
      { Header: 'Password', accessor: 'password' },
      { Header: '', accessor: 'actions' },
    ],
    [],
  );

  const toggleDropdown = (id: any) => {
    if (openDropdown === id) {
      setOpenDropdown(null);
    } else {
      setOpenDropdown(id);
    }
  };

  const data: any = users.data.map((user) => {
    return {
      id: user.id,
      student: (
        <UserComponent
          name={`${user.first_name} ${
            typeof user.last_name !== 'string' ? '' : user.last_name
          }`}
        />
      ),
      email: <span className="font-size-sm">{user.email}</span>,
      password: <PasswordComponent initialPassword={user.initialPassword} />,
      actions: (
        <>
          <FontAwesomeIcon
            icon={faEllipsisV}
            color="#C86E99"
            onClick={() => toggleDropdown(user.id)}
          />
          <ActionsComponent
            editModal={editModal}
            deleteModal={deleteModal}
            resetPasswordModal={resetPasswordModal}
            user={user}
            isDropdownOpen={openDropdown === user.id}
          />
        </>
      ),
    };
  });

  return (
    <section>
      <div className={styles.sectionInfo}>
        <div>
          <p>Class participants</p>
        </div>
        <div>
          <QButton
            variant="outline-danger"
            className={`${styles.btnWrap} mr-2`}
            onClick={() => editModal.show()}
          >
            Add <img src={AddUserIcon} alt="add-user-icon" />
          </QButton>
          <QButton
            variant="outline-danger"
            className={`${styles.btnWrap} ${
              user.role === UserRole.Admin ? 'mr-2' : ''
            }`}
            onClick={inviteParticipantsModalVisible.setTrue}
          >
            invite <FontAwesomeIcon icon={faShare} />
          </QButton>
          {typeof FileReader !== 'undefined' &&
            user.role === UserRole.Admin && (
              <QButton
                variant="outline-danger"
                className={styles.btnWrap}
                onClick={inviteParticipantsModalVisible.setTrue}
              >
                Add from file <FontAwesomeIcon icon={faFile} />
              </QButton>
            )}
        </div>
      </div>
      <div className={``}>
        <RedstoneTable
          columns={columns}
          data={data || []}
          onClick={(id: any) => {
            if (id) {
            }
          }}
        />
      </div>

      {editModal.visible && (
        <EditStudentModal
          show
          onHide={editModal.hide}
          currentClass={currentClass}
          user={editModal.entity}
        />
      )}

      {deleteModal.entity && (
        <DeleteModal
          show={deleteModal.visible}
          onHide={deleteModal.hide}
          resource={`/classes/${currentClass.id}/students`}
          id={deleteModal.entity.id!}
          title="Delete student"
        >
          You are about to delete <strong>{deleteModal.entity.name}</strong>.
        </DeleteModal>
      )}

      {resetPasswordModal.entity && (
        <ResetStudentPasswordModal
          show={resetPasswordModal.visible}
          onHide={resetPasswordModal.hide}
          student={resetPasswordModal.entity}
          classId={currentClass.id!}
        />
      )}

      {importModalVisible.state && (
        <ImportStudentsModal
          show
          onHide={importModalVisible.setFalse}
          currentClass={currentClass}
        />
      )}

      {inviteParticipantsModalVisible.state && (
        <InviteParticipantssModal
          show={true}
          currentClass={currentClass}
          onHide={inviteParticipantsModalVisible.setFalse}
        />
      )}
    </section>
  );
};
