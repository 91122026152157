import React, { MouseEventHandler } from 'react';
import { Link } from 'react-router-dom';
import styles from '../styles/BackButton.module.scss';
import { ReactComponent as DesktopIcon } from '../images/Caret.svg';

const BackButton = ({ navigate }: { navigate: string | MouseEventHandler }) => {
  const text = 'Back';
  const Icon = <DesktopIcon className={styles.icon} />;

  if (!navigate) return null;

  return typeof navigate === 'function' ? (
    <button onClick={navigate} className={styles.back}>
      {Icon}
      {text}
    </button>
  ) : (
    <Link to={navigate} className={styles.back}>
      {Icon}
      {text}
    </Link>
  );
};

export default BackButton;
