import * as React from 'react';
import { Disability } from '../../model/entities/User';
import { Select } from './Select';

const options = [
  { value: Disability.Yes, label: 'Yes' },
  { value: Disability.No, label: 'No' },
  { value: Disability.PreferNotToSay, label: 'Prefer not to say' },
];

export interface DisabilityFieldProps {
  name?: string;
  value?: Disability | string;
  onChange: (value: Disability) => void;
  isInvalid?: boolean;
}

export const DisabilityField: React.FunctionComponent<DisabilityFieldProps> = ({
  name,
  value,
  onChange,
  isInvalid,
}) => {
  return (
    <Select
      name={name}
      value={options.find((x) => x.value === value)}
      onChange={(value: any) => {
        onChange(value.value);
      }}
      options={options}
      isInvalid={isInvalid}
    />
  );
};
