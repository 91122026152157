import * as React from 'react';
import {Select} from './Select';

interface OptionType {
    value: string;
    label: object;
}

export interface CityFieldProps {
    options?: OptionType[];
    value: any;
    placeholder?: string;
    isInvalid?: boolean;
    className?: string;
    name?: string;
    setFieldValue?: any;
    setInputCityValue?: any;
}

export const CityFieldSelect: React.FunctionComponent<CityFieldProps> = ({
                                                                                   options,
                                                                                   value,
                                                                                   // onChange,
                                                                                   placeholder,
                                                                                   isInvalid,
                                                                                   className,
                                                                                   name,
                                                                                   setFieldValue,
                                                                               }) => {
    const onChangeSelect = (optionValue: any, setFieldValue: Function) => {
        setFieldValue(name, optionValue);
    };
    return (
        <Select
            name={name}
            options={options}
            value={value}
            onChange={(optionValue) => onChangeSelect(optionValue, setFieldValue)}
            placeholder={placeholder}
            isInvalid={isInvalid}
            className={className}
        />
    );
};
