import { useState, useCallback } from 'react';
import { useSubscribeToDom } from './useSubscribeToDom';

export function useDragAndDrop<TElement extends HTMLElement>(
  onDrop: (data: DataTransfer | null) => void,
) {
  // whether or not something is being dragged over the dropzone
  const [activated, setActivated] = useState(false);

  // handle various drag events
  const handleDropEvent = useCallback(
    (e: DragEvent) => {
      e.preventDefault();
      e.stopPropagation();

      setActivated(e.type === 'dragenter' || e.type === 'dragover');

      if (e.type === 'drop') {
        onDrop(e.dataTransfer);
      }
    },
    [onDrop],
  );

  // bind the events on mount
  const dropzoneRef = useSubscribeToDom<TElement>({
    dragenter: handleDropEvent,
    dragover: handleDropEvent,
    dragleave: handleDropEvent,
    drop: handleDropEvent,
  });

  return { dropzoneRef, activated };
}
