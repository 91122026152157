import * as React from 'react';
import {Select} from '../Select';
import {City, Country} from "country-state-city";
import {palestineCities} from "../../country/palestineCities";

const countriesNew = Country.getAllCountries();
const allCountries: any = countriesNew.map((country) => {
    return {
        label: country.name !== 'Palestinian Territory Occupied' ? country.name : 'Palestine',
        value: {
            country_name: country.name,
            iso_code: country.isoCode,
            latitude: country.latitude,
            longitude: country.longitude,
        },
    };
});

interface valueType {
    label: string;
    value: object;
}

export interface CountryFieldProps {
    value: valueType;
    onChange: (value: string) => void;
    placeholder?: string;
    isInvalid?: boolean;
    className?: string;
    setInputCityValue?: any;
    resetCity?: any;
}

export const ModalCountryField: React.FunctionComponent<CountryFieldProps> = ({
                                                                                  value,
                                                                                  onChange,
                                                                                  placeholder,
                                                                                  isInvalid,
                                                                                  className,
                                                                                  setInputCityValue,
                                                                                  resetCity,
                                                                              }) => {

    const onChangeSelect = (optionValue: any) => {
        onChange(optionValue)
        resetCity(null);
        const getCitByCountry = City.getCitiesOfCountry(optionValue.value.iso_code);
        let getCitiesBySelectedCountry: any = optionValue.value.iso_code === 'PS' ? palestineCities : getCitByCountry;
        const getNewCitiesBySelectedCountry = getCitiesBySelectedCountry.map((city: { name: string, countryCode: string, latitude: string, longitude: string, }) => {
            return {
                label: city.name,
                value: {
                    city_name: city.name,
                    country_code: city.countryCode,
                    latitude: city.latitude,
                    longitude: city.longitude,
                },
            };
        });
        setInputCityValue(getNewCitiesBySelectedCountry);
    };
    return (
        <Select
            options={allCountries}
            value={allCountries.find((countryValue: any) => countryValue.label === value.label)}
            onChange={(optionValue) => onChangeSelect(optionValue)}
            placeholder={placeholder}
            isInvalid={isInvalid}
            className={className}
        />
    );
};
