const env: keyof typeof environments = process.env.RELEASE_ENVIRONMENT as any;

const environments = {
  local: {
    reviewQueue: true,
    resetPassword: true,
  },
  development: {
    reviewQueue: true,
    resetPassword: false,
  },
  production: {
    reviewQueue: true,
    resetPassword: false,
  },
};

export const featureToggles = environments[env] || environments.production;

export const normaliseStatus = (data: any) => {
  if (data?.documentInStages?.length === 1)
    return data?.documentInStages[0]?.createdAt;
  return data?.documentInStages?.filter(
    (item: any) => item.stage === 'PUBLISHED',
  )[0]?.publishedAt;
};
