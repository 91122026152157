import { Route, RouteParams } from '../Route';
import { useUser } from './useUser';
import { UserRole } from '../../model/entities/User';

export function useRoutes<T extends RouteParams>(routes: Route<T>[]) {
  const user = useUser();

  return routes.filter(
    route =>
      route.options.enabled &&
      (user.role === UserRole.Admin || !route.options.adminOnly),
  );
}
