import * as React from 'react';
// , faEnvelope, faUser
import { faDownload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import _ from 'lodash';
import { Col, Form, Row } from 'react-bootstrap';
// import ShowMoreText from "react-show-more-text";
// import { CanvasSubmission } from '../../model/canvas/CanvasSubmission';
// import { Assignment } from '../../model/entities/Assignment';
import { ClassCourse } from '../../model/entities/Class';
import { BusyButton } from '../components/Busy';
// import { List } from '../components/List';
// import { ListItem } from '../components/ListItem';
import { PageSection } from '../components/PageSection';
import { Select } from '../components/Select';
import { useApiClient } from '../hooks/useApiClient';
// import { useApiQuery } from '../hooks/useApiQuery';
import { useAsyncEffect } from '../hooks/useAsyncEffect';
import { useField } from '../hooks/useField';
import { Route } from '../Route';
import { ClassContext } from '../routes/classDetailsRoute';
// import { useApiClientContext } from '../hooks/useApiClientContext';
import {useTrackingCode} from "react-hubspot-tracking-code-hook";
import { useUser } from '../hooks/useUser';
import { useState } from "react";
import { StoryItem } from "./ActivityTab/storyItem";


// interface AssignmentSubmission {
//   assignment: Assignment;
//   submission: CanvasSubmission;
// }

export const classActivityTabRoute = new Route<{ id: string }>(
  '/classes/:id/activity',
  () => {
    const currentClass = React.useContext(ClassContext);
    if (!currentClass) return null;

    const course = useField<ClassCourse | undefined>(currentClass.courses.length ? currentClass.courses[0] : undefined, []);
    // const [submissions, setSubmissions] = React.useState<AssignmentSubmission[]>();
    // const [filteredSubmissions, setFilteredSubmissions] = React.useState<AssignmentSubmission[]>();
    // const [loadingSubmissions, setLoadingSubmissions] = React.useState<boolean>();
    // const [search, setSearch] = React.useState<string>();
    const [downloading, setDownloading] = React.useState<boolean>();

    const client = useApiClient();

    // const assignments = useApiQuery<Assignment[]>(
    //   `/classes/${currentClass?.id}/courses/${course.value && course.value.id}/assignments`,
    //   [],
    //   { observeUrl: `/classes/${currentClass?.id}/courses/${course.value && course.value.id}/assignments` }
    // );

    const [stories, setStories] = useState([]);

    useAsyncEffect(async () => {
      let query = await client.get(`/classes/${currentClass?.id}/courses/${course.value && course.value.id}/assignments`,);
      setStories(query.stories);
    }, []);

      // useAsyncEffect(async () => {
      //   setLoadingSubmissions(true);
      // const allSubmissions: AssignmentSubmission[] = await Promise.all(assignments.data.map(async assignment => {
      //   const { data: submissions } = await client.get(
      //     `/classes/${assignment.classId}/assignments/${assignment.id}/submissions`,
      //   );
      //   return submissions.map((submission: CanvasSubmission) => ({
      //     assignment,
      //     submission,
      //   }));
      // }));
      // console.log(assignments.data);
      // console.log(_.flatten(allSubmissions).sort((a, b) => Date.parse(a.submission.submitted_at || '') - Date.parse(b.submission.submitted_at || '')));
      // setSubmissions(_.flatten(allSubmissions).sort((a, b) => Date.parse(b.submission.submitted_at || '') - Date.parse(a.submission.submitted_at || '')));
      // setLoadingSubmissions(false);
      // }, [assignments.data]);
    // const filterSubmissions = (search: string) => {
    //   if (!submissions) return;
    //
    //
    //   setFilteredSubmissions(
    //     submissions.filter(submission => {
    //       return (
    //         submission.submission.redstone_student_name.toLowerCase().includes(search.toLowerCase()) ||
    //         submission.assignment.course.name.toLowerCase().includes(search.toLowerCase()) ||
    //         submission.assignment.modules.find(module => module.name.toLowerCase().includes(search.toLowerCase()))
    //       );
    //     })
    //   );
    // }
    // const downloadAllSubmissions = async () => {
    //   if (!submissions || !course.value) return;
    //
    //   try {
    //     // set downloading
    //     setDownloading(true);
    //
    //     // submissions to download
    //     const submissionsToDownload = submissions.map(assignmentSubmission => {
    //       // header
    //       const header = `<div style="text-align:center"><h2>${assignmentSubmission.submission.redstone_student_name} (${assignmentSubmission.submission.redstone_student_loginId}) - ${assignmentSubmission.assignment.name} (${assignmentSubmission.assignment.course.name})</h2><a href="https://gng.org/">gng.org</a></div>`;
    //       // content
    //       const content = header + (assignmentSubmission.submission.body || (assignmentSubmission.submission.discussion_entries && assignmentSubmission.submission.discussion_entries.length && assignmentSubmission.submission.discussion_entries[0].message) || '');
    //       // file name
    //       const fileName = `${assignmentSubmission.submission.redstone_student_name} - ${assignmentSubmission.assignment.name} (${assignmentSubmission.assignment.course.name}).pdf`;
    //       // return content and file name
    //       return {
    //         content,
    //         fileName,
    //       }
    //     })
    //
    //     // download all submissions
    //     const response = await client.post(
    //       `/sections/${course.value.sectionId}/submissions/downloadall`,
    //       {
    //         submissions: submissionsToDownload,
    //       }
    //     );
    //
    //     // create an anchor element to "point" to it
    //     const anchor = document.createElement('a');
    //     anchor.href = response.data;
    //
    //     // get the suggested filename for the file from the response headers
    //     anchor.download = `${course.value.name}-submissions.zip`;
    //
    //     // simulate a click on our anchor element
    //     anchor.click();
    //
    //     // set downloading
    //     setDownloading(false);
    //   } catch (error) {
    //     console.log(error);
    //     // set downloading
    //     setDownloading(false);
    //   }
    // }

      const downloadAllStories = async () => {
          try {
              // set downloading
              setDownloading(true);

              // submissions to download
              const submissionsToDownload = stories.map((assignmentSubmission: any) => {
                  console.log(assignmentSubmission);

                  // header
                  // const header = `<div style="text-align:center"><h2>${assignmentSubmission.submission.redstone_student_name} (${assignmentSubmission.submission.redstone_student_loginId}) - ${assignmentSubmission.assignment.name} (${assignmentSubmission.assignment.course.name})</h2><a href="https://gng.org/">gng.org</a></div>`;
                  // // content
                  // const content = header + (assignmentSubmission.submission.body || (assignmentSubmission.submission.discussion_entries && assignmentSubmission.submission.discussion_entries.length && assignmentSubmission.submission.discussion_entries[0].message) || '');
                  // // file name
                  // const fileName = `${assignmentSubmission.submission.redstone_student_name} - ${assignmentSubmission.assignment.name} (${assignmentSubmission.assignment.course.name}).pdf`;
                  // // return content and file name

                  // header
                  const header = `<div style="text-align:center"><h2>${assignmentSubmission.fullName} submitted work in ${assignmentSubmission.module.title} : ${assignmentSubmission.module.hashtag && assignmentSubmission.module.hashtag} - ${assignmentSubmission.course.title}</h2><a href="https://gng.org/">gng.org</a></div>`;
                  // content
                  const content = header + (assignmentSubmission.description);
                  // file name
                  const fileName = `${assignmentSubmission.fullName} - ${assignmentSubmission.module.title} (${assignmentSubmission.course.title}).pdf`;


                  return {
                      content,
                      fileName,
                  }
              })
              // download all submissions
              const response = await client.post(
                  // `/sections/${course.value.sectionId}/submissions/downloadall`,
                  `/sections/${currentClass?.id}/submissions/downloadall`,
                  {
                      submissions: submissionsToDownload,
                  }
              );

              // create an anchor element to "point" to it
              const anchor = document.createElement('a');
              anchor.href = response.data;

              // get the suggested filename for the file from the response headers
              anchor.download = `all-submissions.zip`;
              // anchor.download = `${course.value.name}-submissions.zip`;

              // simulate a click on our anchor element
              anchor.click();

              // set downloading
              setDownloading(false);
          } catch (error) {
              console.log(error);
              // set downloading
              setDownloading(false);
          }
      }
      const user = useUser();
      const {setPathPageView, setIdentity} = useTrackingCode();
      React.useEffect(() => {
          if (user?.email && currentClass?.id) {
              setPathPageView(`/classes/${currentClass.id}/activity`);
              setIdentity(user.email);
          }
      }, [user, currentClass, setPathPageView, setIdentity]);

      return (
          <div>
              <PageSection title="Course Activity">
                  <Form>
                      {currentClass.courses.length > 0 && (
                          <Form.Group as={Row}>
                              <Col lg={6}>
                                  <Select
                                      options={currentClass.courses}
                                      getOptionValue={x => x.uuid}
                                      getOptionLabel={x => x.name}
                                      onChange={async (value) => {
                                          // console.log(currentClass.courses, value);
                                          course.setValue(value as ClassCourse);
                                          // @ts-ignore
                                          let query = await client.get(`/classes/${currentClass?.id}/courses/${course.value && course.value.id}/assignments/${value.uuid}`);
                                          setStories(query.stories);
                                      }}
                                      placeholder="Courses"
                                      // value={course.value}
                                      isInvalid={!!course.error}
                                  />
                              </Col>
                          </Form.Group>
                      )}
                      <Form.Group as={Row}>
                          <Col lg={6}>
                              <Form.Control
                                  placeholder="Search"
                                  aria-label="Search"
                                  onChange={(e: any) => {
                                      console.log(e.target.value)
                                      // setSearch(e.target.value);
                                      // filterSubmissions(e.target.value);
                                  }}
                                  // disabled={loadingSubmissions}
                              />
                          </Col>
                          <Col lg={6}>
                              <BusyButton
                                  variant="outline-secondary"
                                  className="float-right"
                                  // disabled={loadingSubmissions || (stories && !stories.length)}
                                  disabled={stories && !stories.length}
                                  onClick={downloadAllStories}
                                  busy={downloading}
                              >
                                  <><FontAwesomeIcon icon={faDownload} className="mr-2"/>Download all submissions</>
                              </BusyButton>
                          </Col>
                      </Form.Group>
                  </Form>
                  {/*<List*/}
                  {/*    items={(!search ? submissions : filteredSubmissions) || []}*/}
                  {/*    loading={assignments.loading || loadingSubmissions}*/}
                  {/*    renderItem={(item, index) => {*/}
                  {/*        return (*/}
                  {/*            <Submission*/}
                  {/*                key={index}*/}
                  {/*                assignmentSubmission={item}*/}
                  {/*            />*/}
                  {/*        );*/}
                  {/*    }}*/}
                  {/*/>*/}
              </PageSection>
              <PageSection title="Recent submissions">
                  {
                      stories.length > 0 ?
                          stories.map((storyItem: any, index: number) => {
                              return (
                                  <StoryItem key={index} storyItem={storyItem}/>
                              )
                          })
                          :
                          <div className="list-group-item">
                              <div>Story is empty</div>
                          </div>
                  }
              </PageSection>
          </div>
      )
  },
  { title: 'Activity' },
);

// interface AssignmentSubmissionProps {
//   assignmentSubmission: AssignmentSubmission
// }

// const Submission: React.FunctionComponent<AssignmentSubmissionProps> = ({
//   assignmentSubmission,
// }) => {
//   const client = useApiClient();
//   const [downloading, setDownloading] = React.useState<boolean>();
//   const { canvasInstallUrl } = useApiClientContext();
//   const body = assignmentSubmission.submission.body || (assignmentSubmission.submission.discussion_entries && assignmentSubmission.submission.discussion_entries.length ? assignmentSubmission.submission.discussion_entries[0].message : undefined);
//
//   const downloadSubmission = async (assignment: Assignment, submission: CanvasSubmission) => {
//     try {
//       // set downloading
//       setDownloading(true);
//       // header
//       const header = `<div style="text-align:center"><h2>${submission.redstone_student_name} (${submission.redstone_student_loginId}) - ${assignment.name} (${assignment.course.name})</h2><a href="https://gng.org/">gng.org</a></div>`;
//       // content
//       const content = header + (submission.body || (submission.discussion_entries && submission.discussion_entries.length && submission.discussion_entries[0].message) || '');
//       // file name
//       const fileName = `${assignmentSubmission.submission.redstone_student_name} - ${assignmentSubmission.assignment.name} (${assignmentSubmission.assignment.course.name}).pdf`;
//
//       // download submission
//       const response = await client.post(
//         `/submissions/download`,
//         {
//           content,
//           fileName,
//         }
//       );
//
//       // get the blob
//       const blob = await response.blob();
//
//       // create a URL for the blob
//       const url = URL.createObjectURL(blob);
//
//       // create an anchor element to "point" to it
//       const anchor = document.createElement('a');
//       anchor.href = url;
//
//       // get the suggested filename for the file from the response headers
//       anchor.download = fileName;
//
//       // simulate a click on our anchor element
//       anchor.click();
//
//       // discard the object data
//       URL.revokeObjectURL(url);
//
//       // set downloading
//       setDownloading(false);
//     } catch (error) {
//       console.log(error);
//       // set downloading
//       setDownloading(false);
//     }
//   }
//
//   return (
//     <ListItem
//       title={`${assignmentSubmission.submission.redstone_student_name} submitted work in ${assignmentSubmission.assignment.modules.map(module => module.name).join(', ')} - ${assignmentSubmission.assignment.course.name}`}
//       meta={
//         <>
//           <span className="mr-3">
//             <a href={`https://${canvasInstallUrl}/accounts/1/users/${assignmentSubmission.submission.user_id}`} target="_blank" rel="noreferrer">{assignmentSubmission.submission.redstone_student_name}</a>
//           </span>
//           <span className="mr-3">
//             <FontAwesomeIcon icon={faUser} /> <a href={`https://${canvasInstallUrl}/accounts/1/users/${assignmentSubmission.submission.user_id}`} target="_blank" rel="noreferrer">{assignmentSubmission.submission.redstone_student_loginId}</a>
//           </span>
//           <span className="mr-3">
//             <FontAwesomeIcon icon={faEnvelope} /> {assignmentSubmission.submission.redstone_student_email}
//           </span>
//         </>
//       }
//       right={
//         <small className="text-muted text-nowrap">{assignmentSubmission.submission.submitted_at && new Date(assignmentSubmission.submission.submitted_at).toString().substring(0, new Date(assignmentSubmission.submission.submitted_at).toString().indexOf('(') - 1)}</small>
//       }
//       className="mb-4"
//     >
//       {body && (
//         <Row>
//           <Col lg={10}>
//             {/*
//               the image and the show more option are not shown when the body is short
//               so only use the ShowMoreText component when the body is larger than 800
//             */}
//             {body && body.length > 800 ? (
//               <ShowMoreText lines={2}>
//                 <div
//                   dangerouslySetInnerHTML={{ __html: body }}
//                 />
//               </ShowMoreText>
//             ) : (
//               <div
//                 dangerouslySetInnerHTML={{ __html: body }}
//               />
//             )}
//           </Col>
//           <Col lg={2} className="text-right">
//             <BusyButton
//               variant="light"
//               onClick={() => downloadSubmission(assignmentSubmission.assignment, assignmentSubmission.submission)}
//               busy={downloading}
//             >
//               .pdf
//             </BusyButton>
//           </Col>
//         </Row>
//       )}
//     </ListItem>
//   );
// };
