import { useState, useCallback } from 'react';

export function useBooleanGeneric(stateFn = useState) {
  return (initialState = false) => {
    const [state, setState] = stateFn(initialState);

    // TODO: should probably do something about this
    /* eslint-disable react-hooks/rules-of-hooks */
    const toggle = useCallback(() => {
      setState(state => !state);
    }, [setState]);

    const setTrue = useCallback(() => {
      setState(true);
    }, [setState]);

    const setFalse = useCallback(() => {
      setState(false);
    }, [setState]);
    /* eslint-enable react-hooks/rules-of-hooks */

    return { toggle, setTrue, setFalse, state, setState };
  };
}

// TODO: should probably do something about this
/* eslint-disable react-hooks/rules-of-hooks */
export const useBoolean = useBooleanGeneric(useState);
