import * as React from 'react';
import { Column } from 'react-table';
import Select from 'react-select';
import { Route } from '../Route';
import { PageContent } from '../components/PageContent';
import { useApiQuery } from '../hooks/useApiQuery';
import { Class, Course, ModuleT } from '../../model/entities/Class';
import { CenteredBusySpinner } from '../components/Busy';
import { Module } from '../components/Module';
import { RedstoneTable, Data } from '../components/Table';
// import RightArrowIcon from '../images/r-arrow.svg';
import Approved from '../../frontend/images/check.png';
import InWriting from '../../frontend/images/in-writing.png';
import InReview from '../../frontend/images/in-progress.png';
import Rejected from '../../frontend/images/rejected.png';
import SkeletonItem from '../components/SkeletonItem';
import { StatusIcon } from '../components/StatusIcon';

const styles = require('../styles/CourseDetailsSection.module.scss');

export const CourseContext = React.createContext<Class | null>(null);

export const courseDetailsRoute = new Route<{ courseId: string }>(
  '/courses/:courseId',
  ({ match, history }) => {
    const [selectedOption, setSelectedOption] = React.useState<any>();
    const [menuIsOpen, setmenuIsOpen] = React.useState<boolean>(false);

    const courseId = match.params.courseId.split(':')[0];
    const classId = match.params.courseId.split(':')[1];

    const currentCourse = useApiQuery<any>(`/courses/${courseId}`);
    const currentInsights = useApiQuery<any>(
      `/classes/${classId}/studentstories/${courseId}`,
    );
    const courses = useApiQuery<any>(`/classes/${classId}`);

    const moduleMapper = (): any => {
      return {
        1: {
          approved: <StatusIcon img={Approved} label="Complete" />,
          in_writing: <StatusIcon img={InWriting} label="In progress" />,
          in_review: <StatusIcon img={InReview} label="In review" />,
          rejected: <StatusIcon img={Rejected} label="Rejected" />,
        },
        2: {
          approved: <StatusIcon img={Approved} label="Complete" />,
          in_writing: <StatusIcon img={InWriting} label="In progress" />,
          in_review: <StatusIcon img={InReview} label="In review" />,
          rejected: <StatusIcon img={Rejected} label="Rejected" />,
        },
        3: {
          approved: <StatusIcon img={Approved} label="Complete" />,
          in_writing: <StatusIcon img={InWriting} label="In progress" />,
          in_review: <StatusIcon img={InReview} label="In review" />,
          rejected: <StatusIcon img={Rejected} label="Rejected" />,
        },
        4: {
          approved: <StatusIcon img={Approved} label="Complete" />,
          in_writing: <StatusIcon img={InWriting} label="In progress" />,
          in_review: <StatusIcon img={InReview} label="In review" />,
          rejected: <StatusIcon img={Rejected} label="Rejected" />,
        },
      };
    };

    const moduleMapped = moduleMapper();

    const getEachModuleInfo = (stories: any) => {
      let modules: any = {
        module1: <StatusIcon img={''} label="Not started yet" />,
        module2: <StatusIcon img={''} label="Not started yet" />,
        module3: <StatusIcon img={''} label="Not started yet" />,
        module4: <StatusIcon img={''} label="Not started yet" />,
      };
      stories.forEach((story: any) => {
        modules[`module${story.module?.order}`] =
          moduleMapped[story.module?.order][story.storyStatus];
      });
      return modules;
    };

    const data: any = [];
    Object.keys(currentInsights?.data || []).forEach((idx) => {
      Object.keys(currentInsights?.data[idx]).forEach((userId) => {
        const user = currentInsights?.data[idx][userId]['user'];
        const stories = currentInsights?.data[idx][userId]['stories'];
        const modules = getEachModuleInfo(stories);
        data.push({
          id: user.id,
          studentName: `${user.first_name} ${user.last_name}`,
          ...modules,
        });
      });
    });

    const handleChange = (option: any) => {
      setSelectedOption(option);
      history.push(`/courses/${option?.value}:${classId}`);
    };

    const opts = courses?.data?.courses.map((course: Course) => ({
      value: course.id,
      label: course.name,
    }));

    const setDefaultValue = React.useCallback(() => {
      setSelectedOption({
        value: currentCourse?.data?.id,
        label: currentCourse?.data?.title,
      });
    }, [
      setSelectedOption,
      currentCourse?.data?.id,
      currentCourse?.data?.title,
    ]);

    React.useEffect(() => {
      setDefaultValue();
    }, [setDefaultValue]);

    const columns = React.useMemo<Column<Partial<Data>>[]>(
      () => [
        { Header: 'Student name', accessor: 'studentName' },
        { Header: 'Module 1', accessor: 'module1' },
        { Header: 'Module 2', accessor: 'module2' },
        { Header: 'Module 3', accessor: 'module3' },
        { Header: 'Module 4', accessor: 'module4' },
        { Header: '', accessor: 'actions' },
      ],
      [],
    );

    return (
      <PageContent
        title={
          currentCourse.data?.title || <SkeletonItem count={1} width={100} />
        }
        breadcrumbs={[{ title: 'Courses', url: '/' }]}
        actions={<></>}
        typeA={false}
        callback={() => {}}
      >
        {currentCourse.loading || !currentCourse.data ? (
          <CenteredBusySpinner />
        ) : (
          <CourseContext.Provider value={currentCourse.data}>
            <div className={styles.mainWrapper}>
              <div className={styles.courseSelect}>
                <Select
                  value={selectedOption}
                  onChange={handleChange}
                  options={opts}
                  menuIsOpen={menuIsOpen}
                  onMenuOpen={() => setmenuIsOpen(true)}
                  onMenuClose={() => setmenuIsOpen(false)}
                />
              </div>
              <div className={styles.gridRow}>
                {currentCourse?.data?.modules.map((module: ModuleT) => (
                  <div className={styles.gridItem} key={module.order}>
                    <Module
                      courseName={currentCourse?.data?.title}
                      moduleName={module?.title}
                      moduleImage={module?.banner?.url}
                      moduleProgress=""
                    />
                  </div>
                ))}
              </div>
              <section>
                <div>
                  <p>Student statistics</p>
                </div>
                <div className={`${styles.tblWrapper} row`}>
                  {currentInsights.loading || !currentInsights.data ? (
                    <SkeletonItem count={1} width={1110} height={100} />
                  ) : (
                    <RedstoneTable
                      columns={columns}
                      data={data || []}
                      onClick={(id: any) => {
                        if (id) {
                          history.push(`/userStories/${id}:${courseId}`);
                        }
                      }}
                    />
                  )}
                </div>
              </section>
            </div>
          </CourseContext.Provider>
        )}
      </PageContent>
    );
  },
  { title: 'Course' },
);
