import * as React from 'react';
import { Container, Col } from 'react-bootstrap';

const styles = require('../styles/Footer.module.scss');

export const Footer: React.FunctionComponent = () => {
  return (
    <footer className={styles.footer}>
      <Container>
        <Col>
          © Global Nomads Group: 209 West 29th Street, Suite 224, New York, NY 10001 USA +1 212.529.0377
        </Col>
      </Container>
    </footer>
  );
};
