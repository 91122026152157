import * as React from 'react';
import Toast from 'react-bootstrap/Toast';
import { Fade } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faExclamationTriangle,
  faInfoCircle,
} from '@fortawesome/free-solid-svg-icons';
import * as _ from 'lodash';
import { CaretCollapse } from './CaretCollapse';
import { faCheckCircle } from '@fortawesome/free-regular-svg-icons';

const styles = require('../styles/NotificationArea.module.scss');

export type NotificationVariant = 'info' | 'danger' | 'success';

export interface Notification {
  id: string;
  title: string;
  body: React.ReactNode;
  variant: NotificationVariant;
  options?: NotificationOptions;
}

export interface NotificationOptions {
  cause?: React.ReactNode;
  hideAfter?: number;
}

export interface CreateNotificationFunction {
  (
    title: string,
    body: React.ReactNode,
    variant?: NotificationVariant,
    options?: NotificationOptions,
  ): void;
}

export interface NotificationContextInterface {
  notifications: Notification[];
  createNotification: CreateNotificationFunction;
}

export const NotificationContext = React.createContext<NotificationContextInterface | null>(
  null,
);

const icons = {
  info: faInfoCircle,
  danger: faExclamationTriangle,
  success: faCheckCircle,
};

export const NotificationProvider: React.FunctionComponent = ({ children }) => {
  const [notifications, setNotifications] = React.useState<Notification[]>([]);

  const createNotification = React.useCallback(
    (
      title: string,
      body: React.ReactNode,
      variant: NotificationVariant = 'danger',
      options: NotificationOptions = {},
    ) => {
      setNotifications(notifications => [
        ...notifications,
        {
          id: _.uniqueId('notification'),
          title,
          body,
          options,
          variant,
        },
      ]);
    },
    [setNotifications],
  );

  const removeNotification = (notification: Notification) => {
    setNotifications(notifications =>
      notifications.filter(x => x !== notification),
    );
  };

  const context = React.useMemo(() => {
    return {
      notifications,
      createNotification,
    };
  }, [notifications, createNotification]);

  return (
    <NotificationContext.Provider value={context}>
      {children}
      <div className={styles.container}>
        {notifications.map(notification => {
          const delay =
            notification.options && notification.options.hideAfter
              ? notification.options.hideAfter
              : undefined;

          return (
            <Toast
              key={notification.id}
              show
              onClose={() => removeNotification(notification)}
              transition={Fade}
              delay={delay}
              autohide={delay ? true : false}
              className={styles.toast}
            >
              <Toast.Header className={`text-${notification.variant}`}>
                <FontAwesomeIcon
                  icon={icons[notification.variant]}
                  className="mr-2"
                />
                <strong className="mr-auto">{notification.title}</strong>
              </Toast.Header>
              <Toast.Body>
                {notification.body}

                {notification.options && notification.options.cause && (
                  <CaretCollapse title="More details">
                    {notification.options.cause}
                  </CaretCollapse>
                )}
              </Toast.Body>
            </Toast>
          );
        })}
      </div>
    </NotificationContext.Provider>
  );
};
