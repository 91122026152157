import { useContext } from 'react';
import { AuthTokensContext } from '../components/AuthTokensProvider';

export function useAuthTokensContext() {
  const context = useContext(AuthTokensContext);

  if (!context) {
    throw new Error('useAuthTokensContext outside AuthTokensProvider');
  }

  return context;
}
