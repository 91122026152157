import React, { useState } from 'react';
const styles = require('../styles/Tooltip.module.scss');

export interface TooltipProps {
  value?: boolean;
  onChange?: (value: boolean) => void;
  text: string;
}

export const Tooltip: React.FunctionComponent<TooltipProps> = ({
  text,
  children,
}) => {
  const [showTooltip, setShowTooltip] = useState(false);

  return (
    <div className={styles.tooltipContainer}>
      <div
        className={styles.tooltipTrigger}
        onMouseEnter={() => setShowTooltip(true)}
        onMouseLeave={() => setShowTooltip(false)}
      >
        {children}
      </div>
      {showTooltip && <div className={styles.tooltipText}>{text}</div>}
    </div>
  );
};

export default Tooltip;
