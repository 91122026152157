import * as React from 'react';
import { Spinner, Button } from 'react-bootstrap';
import { Props } from '../util/Props';

type SpinnerProps = Props<typeof Spinner>;

export interface BusyProps extends Partial<SpinnerProps> {
  busy?: boolean;
}

export const Busy: React.StatelessComponent<BusyProps> = ({
  busy,
  ...props
}) => {
  return busy ? <BusySpinner {...props} /> : null;
};

export const BusySpinner: React.StatelessComponent<Partial<SpinnerProps>> = (
  props,
) => <Spinner animation="border" variant="primary" {...props} />;

export const CenteredBusySpinner: React.StatelessComponent<
  Partial<SpinnerProps>
> = (props) => (
  <div className="d-flex justify-content-center">
    <BusySpinner {...props} />
  </div>
);

export const BusyButton: React.StatelessComponent<
  Props<typeof Button> & { busy?: boolean }
> = ({ children, busy, disabled, ...props }) => {
  return (
    <Button {...props} disabled={disabled || busy}>
      {children}
      {busy && <BusySpinner className="ml-2" size="sm" />}
    </Button>
  );
};
