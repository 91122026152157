import * as React from 'react';
import ReactDatePicker, { ReactDatePickerProps } from 'react-datepicker';

export interface DatePickerProps {
  value?: Date;
  onChange: (value: Date) => void;
  placeholder?: string;
  isClearable?: boolean;
  onBlur?: ReactDatePickerProps['onBlur'];
  selectsStart?: boolean;
  selectsEnd?: boolean;
  startDate?: Date;
  endDate?: Date;
  minDate?: Date;
  selected?: Date;
  dateFormat?: string;
}

export const DatePicker: React.FunctionComponent<DatePickerProps> = ({
  value,
  onChange,
  placeholder,
  isClearable,
  onBlur,
  selectsStart,
  selectsEnd,
  startDate,
  endDate,
  minDate,
  selected,
  dateFormat,
}) => (
  <ReactDatePicker
    selected={value}
    startDate={startDate}
    endDate={endDate}
    minDate={minDate}
    onChange={onChange}
    selectsStart={selectsStart}
    selectsEnd={selectsEnd}
    className="form-control"
    placeholderText={placeholder}
    isClearable={isClearable}
    onBlur={onBlur}
    dateFormat={dateFormat}
  />
);
