import * as React from 'react';
import {
  Modal,
  Button,
  Badge,
  Card,
  ProgressBar,
  FormControl,
} from 'react-bootstrap';
import { CenteredBusySpinner, BusyButton } from '../components/Busy';
import { useAsyncCallback } from '../hooks/useAsyncCallback';
import { ModalProps } from './ModalProps';
import { Class, ClassCourse } from '../../model/entities/Class';
import { Dropzone } from '../components/Dropzone';
import { readFileAsString } from '../util/readFileAsString';
import { parseCsv } from '../util/parseCsv';
import { useApiClient } from '../hooks/useApiClient';
import { Select } from '../components/Select';
import * as _ from 'lodash';
import { useField } from '../hooks/useField';
import { FormGroup } from '../components/FormGroup';
import { validateAll } from '../util/validateAll';
import { required } from '../validators/required';

export interface ImportStudentsModalProps extends ModalProps {
  currentClass: Class;
}

enum ProcessStage {
  Initial,
  PasteData,
  HeadersOrData,
  InvalidData,
  ChooseColumn,
  Importing,
}

interface ProcessState {
  stage: ProcessStage;
  data?: any[];
  headers?: boolean;
}

export const ImportStudentsModal: React.FunctionComponent<
  ImportStudentsModalProps
> = ({ show, onHide, currentClass }) => {
  const client = useApiClient();
  const [pastedData, setPastedData] = React.useState('');

  const [state, setState] = React.useState<ProcessState>({
    stage: ProcessStage.Initial,
  });

  const [progress, setProgress] = React.useState<number>(0);

  const nameColumn = useField<number | null>(null, [required]);
  const emailColumn = useField<number | null>(null, []);
  const passwordColumn = useField<number | null>(null, []);
  const gradeLevelColumn = useField<number | null>(null, []);
  const genderColumn = useField<number | null>(null, []);
  const ageColumn = useField<number | null>(null, []);
  const coursesRequired = (value: ClassCourse[]) => !value.length ? 'Required' : '';
  const courses = useField<ClassCourse[]>(
    !currentClass.selfRegistration || !currentClass.selfRegistration.individualCourseEnrol ? currentClass.courses : [],
    [coursesRequired]
  );

  const process = useAsyncCallback(
    async (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();

      if (
        !validateAll([
          nameColumn,
          emailColumn,
          passwordColumn,
          gradeLevelColumn,
          genderColumn,
          ageColumn,
          courses,
        ])
      )
        return;

      setState({ ...state, stage: ProcessStage.Importing });

      const data = state.headers ? state.data!.slice(1) : state.data!;
      let count = 0;

      for (const row of data) {
        if (!row.length) continue;

        const student = {
          name: row[nameColumn.value!],
          email: row[emailColumn.value!],
          password: row[passwordColumn.value!],
          gradeLevel: row[gradeLevelColumn.value!],
          gender: row[genderColumn.value!],
          age: row[ageColumn.value!],
          courses: courses.value,
        };

        if (!student.name) continue;

        await client.post(
          `/classes/${currentClass.id}/students`,
          _.pickBy(student, _.identity),
        );

        count++;
        setProgress((count / data.length) * 100);
      }

      setProgress(0);
      setState({ stage: ProcessStage.Initial });
      setPastedData('');
      client.reload(`/classes/${currentClass.id}/students`);
      onHide();
    },
    [
      state,
      nameColumn,
      emailColumn,
      passwordColumn,
      gradeLevelColumn,
      genderColumn,
      ageColumn,
      courses,
    ],
  );

  const onDrop = useAsyncCallback(async (file: File) => {
    const str = await readFileAsString(file);
    await processData.call(str);
  }, []);

  const processData = useAsyncCallback(async (data: string) => {
    const csv = await parseCsv(data);

    if (csv.data.length) {
      setState({ stage: ProcessStage.HeadersOrData, data: csv.data });
    } else {
      setState({ stage: ProcessStage.InvalidData });
    }
  }, []);

  const columns = state.data
    ? [
        { value: null, label: '-- Not set --' },
        ...state.data![0].map((column: any, i: number) => ({
          value: i,
          label: column,
        })),
      ]
    : [];

  return (
    <Modal
      backdrop
      show={show}
      onHide={() => {
        setState({ stage: ProcessStage.Initial });
        onHide();
      }}
    >
      <Modal.Header closeButton>
        <Modal.Title>Import students from file</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        {onDrop.busy ? (
          <CenteredBusySpinner variant="primary" animation="border" />
        ) : state.stage === ProcessStage.Initial ? (
          <>
            <p>Drop a CSV that has student names as a column.</p>
            <Dropzone onDrop={onDrop.call} />

            <p className="mt-4">
              <button
                className="btn btn-link text-highlight-1"
                onClick={e => {
                  e.preventDefault();
                  setState({ stage: ProcessStage.PasteData });
                }}
              >
                Click here
              </button>{' '}
              to paste data instead.
            </p>
          </>
        ) : state.stage === ProcessStage.PasteData ? (
          <>
            <FormGroup>
              <FormControl
                as="textarea"
                placeholder="Copy and paste data from a spreadsheet or CSV file here"
                value={pastedData}
                onChange={(e: any) => {
                  setPastedData(e.target.value);
                }}
                rows={6}
                autoFocus
              />
            </FormGroup>

            <BusyButton
              variant="primary"
              onClick={() => {
                processData.call(pastedData);
              }}
              busy={processData.busy}
            >
              Next
            </BusyButton>
          </>
        ) : state.stage === ProcessStage.InvalidData ? (
          <p>
            The file you chose either has no data in it or it is not in a
            supported format.
          </p>
        ) : state.stage === ProcessStage.HeadersOrData ? (
          <>
            <p>Is this a student?</p>

            <Card>
              <Card.Body>
                {state.data![0].map((item: any, i: number) => (
                  <Badge variant="secondary" className="mr-2" key={i}>
                    {item}
                  </Badge>
                ))}
              </Card.Body>
            </Card>

            <div className="d-flex flex-column align-items-stretch mt-4">
              <Button
                size="sm"
                onClick={() =>
                  setState({
                    ...state,
                    headers: true,
                    stage: ProcessStage.ChooseColumn,
                  })
                }
              >
                No
              </Button>
              <Button
                size="sm"
                onClick={() =>
                  setState({
                    ...state,
                    headers: false,
                    stage: ProcessStage.ChooseColumn,
                  })
                }
                className="mt-2"
              >
                Yes
              </Button>
            </div>
          </>
        ) : state.stage === ProcessStage.ChooseColumn ? (
          <>
            {state.headers ? (
              <p>Choose the column that corresponds to each field.</p>
            ) : (
              <p>Choose the data that corresponds to each field.</p>
            )}

            <form onSubmit={process.call}>
              <FormGroup label="Name" error={nameColumn.error}>
                <Select
                  options={columns}
                  value={columns.find(x => x.value === nameColumn.value)}
                  onChange={(value: any) => nameColumn.setValue(value ? value.value : null)}
                  isInvalid={!!nameColumn.error}
                />
              </FormGroup>

              <FormGroup label="Email" error={emailColumn.error}>
                <Select
                  options={columns}
                  value={columns.find(x => x.value === emailColumn.value)}
                  onChange={(value: any) => emailColumn.setValue(value ? value.value : null)}
                />
              </FormGroup>

              <FormGroup label="Password" error={passwordColumn.error}>
                <Select
                  options={columns}
                  value={columns.find(x => x.value === passwordColumn.value)}
                  onChange={(value: any) => passwordColumn.setValue(value ? value.value : null)}
                />
              </FormGroup>

              <FormGroup label="Gender" error={genderColumn.error}>
                <Select
                  options={columns}
                  value={columns.find(x => x.value === genderColumn.value)}
                  onChange={(value: any) => genderColumn.setValue(value ? value.value : null)}
                />
              </FormGroup>

              <FormGroup label="Grade level" error={gradeLevelColumn.error}>
                <Select
                  options={columns}
                  value={columns.find(x => x.value === gradeLevelColumn.value)}
                  onChange={(value: any) => gradeLevelColumn.setValue(value ? value.value : null)}
                />
              </FormGroup>

              <FormGroup label="Age" error={ageColumn.error}>
                <Select
                  options={columns}
                  value={columns.find(x => x.value === ageColumn.value)}
                  onChange={(value: any) => ageColumn.setValue(value ? value.value : null)}
                />
              </FormGroup>

              <FormGroup label="Courses" error={courses.error}>
                <Select
                  isMulti
                  options={currentClass.courses}
                  getOptionValue={x => x.id.toString()}
                  getOptionLabel={x => x.name}
                  onChange={(value) => {console.log(value);courses.setValue(value as ClassCourse[])}}
                  placeholder="Courses"
                  value={courses.value}
                  isDisabled={!currentClass.selfRegistration || !currentClass.selfRegistration.individualCourseEnrol}
                  isInvalid={!!courses.error}
                />
              </FormGroup>

              <Button variant="primary" type="submit">
                Import
              </Button>
            </form>
          </>
        ) : state.stage === ProcessStage.Importing ? (
          <ProgressBar now={progress} />
        ) : null}
      </Modal.Body>
    </Modal>
  );
};
