import * as React from 'react';
import { Props } from '../util/Props';
import { Button } from 'react-bootstrap';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
// import styles from '../styles/Qbutton.module.scss';

export interface QButtonProps extends Props<Button> {
  icon?: IconProp;
}

export const QButton: React.FunctionComponent<QButtonProps> = ({
  icon,
  className,
  children,
  ...props
}) => (
  <Button {...props} className={className}>
    {children}
  </Button>
);
