import * as React from 'react';
import cs from 'classnames';
import { Form } from 'react-bootstrap';
import { errorContext } from './ErrorMessage';
import { BusySpinner } from './Busy';

export interface FormGroupProps {
  controlId?: string;
  as?: any;
  label?: React.ReactNode;
  help?: React.ReactNode;
  children?: React.ReactNode;
  error?: any;
  className?: string;
  busy?: boolean;
  optional?: boolean;
}

export const FormGroup: React.StatelessComponent<FormGroupProps> = ({
  controlId,
  as,
  label,
  help,
  children,
  error,
  className,
  busy,
  optional,
}: FormGroupProps) => {
  return (
    <Form.Group
      controlId={controlId}
      as={as}
      className={cs({ 'is-invalid': error }, className)}
    >
      {label && (
        <Form.Label>
          {label}
          {optional && (
            <span className="text-muted font-weight-normal"> (optional)</span>
          )}
        </Form.Label>
      )}

      {busy ? (
        <div>
          <BusySpinner size="sm" />
        </div>
      ) : (
        <errorContext.Provider value={error}>{children}</errorContext.Provider>
      )}

      {help && <Form.Text className="text-muted">{help}</Form.Text>}

      {typeof error === 'string' && <div className="field-error">{error}</div>}
    </Form.Group>
  );
};
