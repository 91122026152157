import * as React from 'react';
import * as _ from 'lodash';
import { Select } from './Select';

const countriesData = require('countries-list/dist/countries.min.json');

const countries = _.orderBy(
  _.map(countriesData, ({ name, native }, value) => ({
    value,
    label: `${name} (${native})`,
  })),
  (country) => country.label,
);

export interface CountryFieldProps {
  value: string;
  onChange: (value: string) => void;
  placeholder?: string;
  isInvalid?: boolean;
  className?: string;
}

export const CountryField: React.FunctionComponent<CountryFieldProps> = ({
  value,
  onChange,
  placeholder,
  isInvalid,
  className,
}) => {
  return (
    <Select
      options={countries}
      value={countries.find((x) => x.value === value)}
      onChange={(value: any) => onChange(value ? value.value : null)}
      placeholder={placeholder}
      isInvalid={isInvalid}
      className={className}
    />
  );
};
