import * as React from 'react';
import { Modal, Spinner, Col, Form, Button } from 'react-bootstrap';
import { auth } from 'firebase';
import { Formik, FormikConfig } from 'formik';
import * as yup from 'yup';
import {
  AuthResponseErrorCode,
  AuthResponse,
} from '../../model/api/AuthResponse';
import { useAsyncEffect } from '../hooks/useAsyncEffect';
import { useQueryString } from '../hooks/useQueryString';
import { Route } from '../Route';
import { useApiClient } from '../hooks/useApiClient';
import { Footer } from '../components/Footer';
import { useApiClientContext } from '../hooks/useApiClientContext';
import { BusyButton } from '../components/Busy';
import logo from '../images/gng-logo.png';
import { useCreateNotification } from '../hooks/useCreateNotification';

export const loginRoute = new Route('/login', () => {
  const client = useApiClient();
  const code = useQueryString('code');
  const { setAuth } = useApiClientContext();
  const createNotification = useCreateNotification();
  const [authError, setAuthError] =
    React.useState<AuthResponseErrorCode | null>(null);
  const [forgotPassword, setForgotPassword] = React.useState(false);

  const busy = useAsyncEffect(async () => {
    if (code) {
      const result = await client.post<AuthResponse>('/auth/tokens', {
        code: code!,
      });

      if (result.error) {
        setAuthError(result.error);
      } else {
        setAuthError(null);
        setAuth(result);
      }
    } else {
      return auth().onAuthStateChanged(async (user) => {
        if (!user) return;
        // "Returns the current token if it has not expired. Otherwise, this will refresh the token and return a new one.""
        const token = await user.getIdToken();
        const result = await client.post<AuthResponse>('/auth/firebase/token', {
          token,
        });

        if (result.error) {
          setAuthError(result.error);
          auth().signOut();
        } else {
          setAuthError(null);
          setAuth(result);
        }
      });
    }
  }, [code]);

  // const goToLoginUrl = useAsyncCallback(async () => {
  //   const { url } = await client.request('GET', '/auth', undefined, {
  //     Authorization: '',
  //   });
  //   window.location = url;
  // });

  const schemaLogin = yup.object().shape({
    email: yup.string().email().required(),
    password: yup.string().min(8).required(),
  });

  const schemaForgotPassword = yup.object().shape({
    email: yup.string().email().required(),
  });

  const onSubmitHandler: FormikConfig<any>['onSubmit'] = async (
    values,
    { setSubmitting },
  ) => {
    if (forgotPassword) {
      auth()
        .sendPasswordResetEmail(values.email)
        .then(function () {
          createNotification(
            'Password reset email',
            `An email has been sent to ${values.email}, please follow the instructions there to reset your password, and then come back to login.`,
            'info',
            { hideAfter: 8000 },
          );
          setForgotPassword(false);
        })
        .catch(function (error) {
          createNotification('Error', error.message, 'danger', {
            hideAfter: 8000,
          });
          console.log(error);
        });
    } else {
      auth()
        .signInWithEmailAndPassword(values.email, values.password)
        .catch((error) => {
          // Handle Errors here.
          var errorMessage = error.message;
          createNotification('Error', errorMessage, 'danger', {
            hideAfter: 8000,
          });
          console.log(error);
        });
    }
    setSubmitting(false);
  };

  const toggleForgotPassword = () => {
    setForgotPassword(!forgotPassword);
  };

  let urlParams = new URLSearchParams(window.location.search);
  let getUserToken = urlParams.get('token');

  if (getUserToken) {
    localStorage.setItem('tokens', getUserToken);
    window.location.href = window.location.origin;
  }

  return (
    <>
      <img
        alt="Logo"
        src={logo}
        width={100}
        height={100}
        className="my-4 mx-auto d-block"
      />
      <Modal.Dialog className="my-0" contentClassName="border-0">
        <Modal.Header className="border-0 text-center d-block">
          <Modal.Title className="w-100">
            {forgotPassword ? 'Forgot password' : 'Educator Login'}
          </Modal.Title>
          {forgotPassword ? (
            <p className="mt-4">
              Go back to{' '}
              <Button
                variant="link p-0 align-baseline"
                onClick={toggleForgotPassword}
              >
                login
              </Button>
            </p>
          ) : (
            <></>
          )}
        </Modal.Header>
        <Modal.Body>
          {busy ? (
            <div className="d-flex justify-content-center">
              <Spinner animation="grow" variant="primary" />
            </div>
          ) : !authError ? (
            <>
              <Formik
                validationSchema={
                  forgotPassword ? schemaForgotPassword : schemaLogin
                }
                onSubmit={onSubmitHandler}
                initialValues={{
                  email: '',
                  password: '',
                }}
              >
                {({
                  values,
                  handleSubmit,
                  handleChange,
                  touched,
                  errors,
                  isSubmitting,
                }) => (
                  <Form noValidate onSubmit={handleSubmit}>
                    <Form.Row>
                      <Form.Group as={Col} controlId="validationFormikEmail">
                        <Form.Label>Email</Form.Label>
                        <Form.Control
                          type="email"
                          aria-describedby="inputGroupPrepend"
                          name="email"
                          onChange={handleChange}
                          isInvalid={touched.email && !!errors.email}
                          value={values.email}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.email}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Form.Row>
                    {!forgotPassword && (
                      <Form.Row>
                        <Form.Group
                          as={Col}
                          controlId="validationFormikPassword"
                        >
                          <Form.Label>Password</Form.Label>
                          <Form.Control
                            type="password"
                            aria-describedby="inputGroupPrepend"
                            name="password"
                            onChange={handleChange}
                            isInvalid={touched.password && !!errors.password}
                            value={values.password}
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors.password}
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Form.Row>
                    )}
                    <div className="text-center">
                      <BusyButton type="submit" busy={isSubmitting}>
                        {forgotPassword ? 'Submit' : 'Login'}
                      </BusyButton>
                    </div>
                  </Form>
                )}
              </Formik>
              {!forgotPassword && (
                <>
                  <Button
                    variant="link p-0 align-baseline w-100 mt-4"
                    onClick={toggleForgotPassword}
                  >
                    Forgot password?
                  </Button>
                </>
              )}
            </>
          ) : authError === AuthResponseErrorCode.StudentRole ? (
            <div className="text-center">
              <h5>Not for student access</h5>

              <p>You don't have access to this page.</p>
            </div>
          ) : (
            <div className="text-center">
              <h5>Please request access</h5>
              <p>
                You don't have access to this page. If you're an educator,
                please get in touch with your course coordinator to request
                access.
              </p>
            </div>
          )}
        </Modal.Body>
      </Modal.Dialog>
      <Footer />
    </>
  );
});
