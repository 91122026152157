import * as React from 'react';
import * as _ from 'lodash';
import { KnownLanguage } from '../../model/entities/User';
import { useArray, idEqual } from '../hooks/useArray';
import { Row, Col } from 'react-bootstrap';
import { LanguageProficiencyField } from './LanguageProficiencyField';
import { IconButton } from './IconButton';
import { faTimes, faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import { LanguageField } from './LanguageField';
import { ErrorContext, ErrorMessage } from './ErrorMessage';

export interface KnownLanguagesFieldProps {
  value: KnownLanguage[];
  onChange: (value: KnownLanguage[]) => void;
}

export const KnownLanguagesField: React.FunctionComponent<
  KnownLanguagesFieldProps
> = ({ value, onChange }) => {
  const languages = useArray(value, onChange, idEqual);

  return (
    <>
      {languages.items.length === 0 && (
        <div className="text-muted font-size-sm mb-2">
          No other languages known.
        </div>
      )}
      <div>
        {languages.items.map((language, i) => {
          return (
            <Row key={i} className="align-items-center mb-2">
              <ErrorContext path={i.toString()}>
                <Col sm={6}>
                  <LanguageField
                    value={language.language as any}
                    onChange={(value) => {
                      languages.update({ ...language, language: value });
                    }}
                  />
                  <ErrorMessage path="language" className="my-0 ml-2" />
                </Col>
                <Col sm={5}>
                  <LanguageProficiencyField
                    value={language.proficiency}
                    onChange={(proficiency) => {
                      languages.update({ ...language, proficiency });
                    }}
                  />
                  <ErrorMessage path="proficiency" className="my-0 ml-2" />
                </Col>
                <Col sm={1}>
                  <IconButton
                    icon={faTimes}
                    onClick={() => {
                      languages.remove(language);
                    }}
                    variant="danger"
                  />
                </Col>
              </ErrorContext>
            </Row>
          );
        })}
      </div>

      <IconButton
        className="mt-3"
        icon={faPlusCircle}
        onClick={() => {
          languages.add({
            id: _.uniqueId('language'),
            language: '',
            proficiency: '' as any,
          } as any);
        }}
      >
        Add language
      </IconButton>
    </>
  );
};
