import { useEffect, DependencyList } from 'react';
import { useAsyncCallback } from './useAsyncCallback';

export type AsyncEffectCallback = () => Promise<void | (() => void)>;

export function useAsyncEffect(
  callback: AsyncEffectCallback,
  deps: DependencyList = [],
) {
  const { busy, call } = useAsyncCallback(callback, deps);

  useEffect(() => {
    const promise = call();

    return () => {
      promise.then(dispose => {
        if (typeof dispose === 'function') {
          dispose();
        }
      });
    };
    // TODO: fix
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [call, ...deps]);

  return busy;
}
