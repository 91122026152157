import * as React from 'react';
import { Route } from '../Route';
import { ClassContext } from '../routes/classDetailsRoute';
import { ClassEducatorsSection } from './ClassEducatorsSection';
import { AvailableCoursesSection } from './AvailableCoursesSection';
import { ClassStudentSection } from './ClassStudentSection';

export const classUsersTabRoute = new Route<{ id: string }>(
  '/classes/:id/users',
  () => {
    const currentClass = React.useContext(ClassContext);

    return currentClass ? (
      <div>
        <ClassEducatorsSection currentClass={currentClass} />

        <AvailableCoursesSection currentClass={currentClass} />

        <ClassStudentSection currentClass={currentClass} />
      </div>
    ) : null;
  },
  { title: 'Participants' },
);
