import * as React from 'react';
import { auth } from 'firebase';
import { Button, Modal } from 'react-bootstrap';
import { Route } from '../Route';
import { User } from '../../model/entities/User';
import { EducatorRegistrationForm } from '../components/EducatorRegistrationForm';
import logo from '../images/gng-logo.png';

import { useTrackingCode } from "react-hubspot-tracking-code-hook";

export const educatorRegistrationRoute = new Route(
  '/educators-registration',
  () => {
    const [processing, setProcessing] = React.useState(false);
    const [registered, setRegistered] = React.useState(false);

      const {setPathPageView, setTrackEvent} = useTrackingCode();
      React.useEffect(() => {
          document.title = 'Global Nomads Group - Educator registration';
          setPathPageView("/educators-registration");
      }, [setPathPageView]);

      const handleOnRegistration = async (user: User, password: string) => {
          setProcessing(true);
          await auth().signInWithEmailAndPassword(user.email, password);
          setTrackEvent({eventId: 'Registered', value: user.email})
          setRegistered(true);
          openInANewTab();
      };

    const openInANewTab = () => {
      window.open('/login', '_blank');
    };

    return (
      <>
        <img
          alt="Logo"
          src={logo}
          width={100}
          height={100}
          className="my-4 mx-auto d-block"
        />
        <Modal.Dialog className="my-0" contentClassName="border-0">
          {!registered && (
            <Modal.Header className="border-0 py-0 text-center d-block">
              <Modal.Title className="w-100">
                Create Your Educator Account
              </Modal.Title>
              <p className="mt-4">
                Already have a login? <a href="/login">Login here</a>
              </p>
            </Modal.Header>
          )}
          <Modal.Body>
            {registered ? (
              <div className="text-center">
                <h4>Your account has been created succesfully!</h4>
                <Button
                  variant="link p-0 align-baseline"
                  onClick={openInANewTab}
                >
                  Open in a new tab.
                </Button>
              </div>
            ) : (
              <EducatorRegistrationForm
                onRegistration={handleOnRegistration}
                processing={processing}
              />
            )}
          </Modal.Body>
        </Modal.Dialog>
      </>
    );
  },
);
