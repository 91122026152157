import * as React from 'react';
import { Column } from 'react-table';
import { Class } from '../../model/entities/Class';
import { RedstoneTable, Data } from '../components/Table';
// const styles = require('../styles/ClassDetailsRoute.module.scss');

export interface AvailableCoursesSectionProps {
  currentClass: Class;
}

export const AvailableCoursesSection: React.FunctionComponent<
  AvailableCoursesSectionProps
> = ({ currentClass }) => {
  const columns = React.useMemo<Column<Partial<Data>>[]>(
    () => [
      { Header: 'Course name', accessor: 'courseName' },
      { Header: 'Nbr of students', accessor: 'numberOfStudents' },
    ],
    [],
  );

  const data: any = currentClass.courses.map((course) => {
    return {
      id: course.id,
      courseName: course.name,
      numberOfStudents: currentClass.students?.length,
    };
  });

  return (
    <section>
      <div>
        <p>My Classes</p>
      </div>
      <div className={``}>
        <RedstoneTable
          columns={columns}
          data={data || []}
          onClick={(id: any) => {
            if (id) {
              window.open(
                process.env.REACT_APP_QUETZAL_ENDPOINT + `/courses/${id}`,
              );
            }
          }}
        />
      </div>
    </section>
  );
};
