import * as React from 'react';
import * as _ from 'lodash';
import { Select } from './Select';

const languagesData = require('countries-list/dist/languages.all.min.json');

const languages = _.orderBy(
  _.map(languagesData, ({ name, native }, value) => ({
    value,
    label: `${name} (${native})`,
  })),
  language => language.label,
);

export interface LanguageFieldProps {
  value: string;
  onChange: (value: string) => void;
}

export const LanguageField: React.FunctionComponent<LanguageFieldProps> = ({
  value,
  onChange,
}) => {
  return (
    <Select
      options={languages}
      value={languages.find(x => x.value === value)}
      onChange={(value: any) => onChange(value ? value.value : null)}
    />
  );
};
