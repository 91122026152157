import * as React from 'react';
import { Route } from '../Route';
import { PageContent } from '../components/PageContent';
import { useEditModalState } from '../hooks/useEditModalState';
import { List } from '../components/List';
import { ListItem } from '../components/ListItem';
import { User, UserRole } from '../../model/entities/User';
import { PageSection } from '../components/PageSection';
import { Button, Dropdown } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import { useAsyncCallback } from '../hooks/useAsyncCallback';
import { EditUserModal } from '../modals/EditUserModal';
import { ViewInCanvasLink } from '../components/ViewInCanvasLink';
import { useApiQuery } from '../hooks/useApiQuery';
import { useApiClient } from '../hooks/useApiClient';

export const userListRoute = new Route(
  '/users',
  () => {
    const modalState = useEditModalState<User>();
    const client = useApiClient();
    const users = useApiQuery<User[]>('/users?includeRequested=true', []);

    const adminUsers = users.data.filter(
      (x) => x.role === UserRole.Admin && !x.accessRequested,
    );

    const educators = users.data.filter(
      (x) => x.role === UserRole.Educator && !x.accessRequested,
    );

    const unknownUsers = users.data.filter((x) => x.accessRequested);

    const dismissUnknownUser = useAsyncCallback(async (id: string) => {
      await client.update('/users', id, { accessRequested: false });
    }, []);

    return (
      <PageContent title="Users" callback={() => {}}>
        <Button
          variant="outline-primary"
          onClick={() => modalState.show()}
          className="mb-4"
        >
          <FontAwesomeIcon icon={faPlusCircle} className="mr-2" />
          Add user
        </Button>

        {unknownUsers.length > 0 && (
          <PageSection title="Unknown users">
            <List
              items={unknownUsers}
              loading={users.loading}
              renderItem={(user) => (
                <ListItem
                  key={user.id}
                  title={user.name}
                  busy={dismissUnknownUser.busy}
                  onClick={() => modalState.show(user)}
                  actions={
                    <>
                      <Dropdown.Item
                        onClick={() => {
                          dismissUnknownUser.call(user.id!);
                        }}
                      >
                        Dismiss
                      </Dropdown.Item>
                      <ViewInCanvasLink url={`/accounts/1/users/${user.id}`} />
                    </>
                  }
                />
              )}
            />
          </PageSection>
        )}

        <PageSection title="Programme coordinators">
          <List
            items={adminUsers}
            loading={users.loading}
            renderItem={(user) => (
              <ListItem
                key={user.id}
                title={user.name}
                onClick={() => modalState.show(user)}
              />
            )}
          />
        </PageSection>

        <PageSection title="Educators">
          <List
            items={educators}
            loading={users.loading}
            renderItem={(user) => (
              <ListItem
                key={user.id}
                title={user.name}
                onClick={() => modalState.show(user)}
              />
            )}
          />
        </PageSection>

        {modalState.visible && (
          <EditUserModal
            show
            onHide={modalState.hide}
            user={modalState.entity}
          />
        )}
      </PageContent>
    );
  },
  { title: 'Users', adminOnly: true },
);
