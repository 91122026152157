import { ApiClient } from '../../util/ApiClient';
import * as _ from 'lodash';
import { AuthTokens, AuthResponse } from '../../model/api/AuthResponse';
import { CreateNotificationFunction } from '../components/NotificationProvider';

interface ResultObserver {
  url: string;
  observeUrl?: string;
  callback: (result: any) => void;
}

export interface ObserveResultsOptions {
  observeUrl?: string;
}

let retrying = false;

export class AuthenticatedApiClient extends ApiClient {
  private authClient = new ApiClient(process.env.API_URL || '/api');
  private observers: ResultObserver[] = [];

  constructor(
    private tokens: AuthTokens | null,
    createNotification: CreateNotificationFunction,
  ) {
    super('/api', [
      {
        afterRequest: async (response, retry) => {
          if ((response as any).status === 401 && this.tokens && !retrying) {
            const result = await this.refreshAuth(this.tokens.refreshToken);
            if (!result) {
              throw new Error('Logged out!');
            } else {
              result.tokens && this.setTokens(result.tokens);
            }
            retrying = true;
            return retry && retry();
          } else if (response instanceof Error) {
            createNotification(
              'Server error',
              response.message,
              'danger',
              {
                cause: _.get(response, 'meta.details.message'),
              },
            );
          }
          retrying = false;
          return;
        },
      },
    ]);

    if (tokens) {
      super.setBearerToken(tokens.accessToken);
    }
  }

  async refreshAuth(refreshToken: string) {
    try {
      const result = await this.authClient.post<AuthResponse>('/auth/refresh', {
        refreshToken,
      });

      return result;
    } catch (err: any) {
      if (err.status === 400) {
        return null;
      } else {
        throw err;
      }
    }
  }

  setTokens(tokens: AuthTokens | null) {
    if (tokens) {
      this.tokens = tokens;
      this.setBearerToken(tokens.accessToken);
      localStorage.setItem('tokens', JSON.stringify(tokens));
    } else {
      localStorage.removeItem('tokens');
    }
  }

  async observeResults(
    url: string,
    callback: (result: any) => void,
    options: ObserveResultsOptions = {},
  ) {
    const observer: ResultObserver = {
      url,
      callback,
      observeUrl: options.observeUrl,
    };

    this.observers.push(observer);

    if (this.bearerToken) {
      await this.runObserver(observer);
    }

    return () => _.pull(this.observers, observer);
  }

  async create(url: string, body: any) {
    const result = await this.post(url, body);
    await this.rerunObservers(url);
    return result;
  }

  async update(url: string, id: string, body: any) {
    const fullUrl = `${url}/${id}`;
    const result = await this.patch(fullUrl, body);

    await this.rerunObservers(url);
    await this.rerunObservers(fullUrl, result);
    return result;
  }

  async destroy(url: string, id: string) {
    const fullUrl = `${url}/${id}`;
    await this.delete(fullUrl);

    await this.rerunObservers(url);
  }

  private async runObserver(observer: ResultObserver) {
    const result = await this.get(observer.url);
    observer.callback(result);
  }

  private rerunObservers(path: string, result?: any) {
    const pathWithoutQuery = path.split('?')[0];

    return Promise.all(
      this.observers
        .filter(observer => {
          const observerUrlWithoutQuery = observer.url.split('?')[0];
          const observeUrl = observer.observeUrl || observerUrlWithoutQuery;

          return pathWithoutQuery === observeUrl;
        })
        .map(async observer => {
          if (result) {
            observer.callback(result);
          } else {
            await this.runObserver(observer);
          }
        }),
    );
  }

  async reload(url: string) {
    await this.rerunObservers(url);
  }
}
