import * as React from 'react';
import { useTable, Column } from 'react-table';

const styles = require('../styles/Table.module.scss');

export interface Data {
  id: number;
  numberOfParticipants: number;
  numberOfCourses?: number;
  numberOfModules?: number;
  numberOfStudents?: number;
  courseName?: JSX.Element;
  className?: string;
  studentName?: string;
  module1?: string;
  module2?: string;
  module3?: string;
  module4?: string;
  courseStatus?: JSX.Element;
  storyStatus?: string;
  story?: string;
  startedBy?: number;
  student?: string;
  email?: string;
  password?: string;
  actions: JSX.Element;
}

export interface ClassData {
  numberOfParticipants: number;
  numberOfCourses?: number;
  numberOfModules?: number;
  className?: string;
  actions: JSX.Element;
}

interface RedstoneTableProps {
  columns: Column<Partial<Data>>[];
  data: Partial<Data>[];
  onClick: any; //React.MouseEventHandler;
}

export const RedstoneTable: React.FC<RedstoneTableProps> = ({
  columns,
  data,
  onClick,
}) => {
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({ columns, data });
  return (
    <table {...getTableProps()} style={{ border: 'solid 1px #E2E8F0' }}>
      <thead>
        {headerGroups.map((headerGroup) => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column) => (
              <th
                {...column.getHeaderProps()}
                style={{
                  background: '#C0E3FB',
                  color: '#155E75',
                  fontWeight: 'bold',
                }}
                className={`${styles.tdHeader}`}
              >
                {column.render('Header')}
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {rows.map((row) => {
          prepareRow(row);
          return (
            <tr
              {...row.getRowProps()}
              className="hoverable-row"
              onClick={() => onClick(row.original.id)}
            >
              {row.cells.map((cell) => (
                <td
                  {...cell.getCellProps()}
                  style={{
                    padding: '10px',
                    background: 'white',
                  }}
                  className={`${styles.tdCell}`}
                >
                  {cell.render('Cell')}
                </td>
              ))}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};
